import React, { useEffect, useState, useMemo } from 'react'
import SearchableMultiSelectPopover from './searchable-multi-select-popover/SearchableMultiSelectPopover'
import styles from './audienceSelect.module.scss'
import FormFieldErrorText from '../../triggered-email-modal/form-field-error-text/FormFieldErrorText'

const AudienceSelect = ({ audienceList, selectedAudienceList, onCheckBoxChange, onSelectAll, onDeselectAll, hasError }) => {
    const [popoverIsOpen, setPopoverIsOpen] = useState(false)
    const [filteredAudienceList, setFilterAudienceList] = useState([])
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        const filteredList = audienceList.filter(audience => {
            return audience.name.toLowerCase().includes(searchValue.toLowerCase())
        })
        setFilterAudienceList(filteredList)
    }, [searchValue, audienceList])

    const selectAudienceListStr = useMemo(() => {
        return selectedAudienceList.map(audience => audience.name).join(', ')
    }, [selectedAudienceList])

    let preventKeyDown = (e) => {
        return e.key === 'Enter' && e.preventDefault()
    }

    let preventMouseDown = (e) => {
        e.preventDefault()
    }

    
    return (
        <div className={styles["container"]}>
            <div className={styles["select-container"]}>
                <select style={{ border: hasError ? '1px solid red' : '' }} onClick={() => setPopoverIsOpen(!popoverIsOpen)} className={styles["select"]} onKeyDown={(e) => preventKeyDown(e)} onMouseDown={(e) => preventMouseDown(e)}>
                    <option>{selectAudienceListStr? selectAudienceListStr : "Audience" }</option>
                </select>
                <FormFieldErrorText text='No audience selected' showText={hasError} />
            </div>
            {
                popoverIsOpen &&
                <SearchableMultiSelectPopover
                    selectedAudienceList={selectedAudienceList}
                    filteredAudienceList={filteredAudienceList}
                    searchValue={searchValue}
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    onCheckBoxChange={onCheckBoxChange}
                    onDeselectAll={onDeselectAll}
                    onSelectAll={onSelectAll}
                    close={() => setPopoverIsOpen(false)}
                /> 
            }
        </div>
    )
}

export default AudienceSelect