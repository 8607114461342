import React from "react";
import useAudienceSelections from "./useAudienceSelections";
import Button from "@material-ui/core/Button";
import CustomModal from "../../../common/components/custom-modal/CustomModal";
import DeleteSelection from "./delete-selection/DeleteSelection";
import styles from "./audienceSelections.module.scss";
import SelectionTable from './selection-table/SelectionTable';
import { useNavigate } from "react-router-dom";

const AudienceSelections = () => {
  const navigate = useNavigate();

  const {
    loading,
    selections,
    newColumns,
    handleMessageSelection,
    selectedRow,
    handleEditSelection,
    handleShowAudienceMembers,
    closeDeleteModal,
    deletionSelectionModal,
    handleDeleteSelection,
    refreshTable,
  } = useAudienceSelections();

  const navigateToAudienceBuilderPage = () => {
    navigate("/audience-builder");
  };

  return (
    <>
      <div className={styles.selections}>
        <div className={styles["create-new-container"]}>
          <Button
            variant="contained"
            className={styles["refresh-audiences-btn"]}
            onClick={() => refreshTable()}
            disabled={loading ? true : false}
          >
            {loading ? (
              <div>
                <i className="fa fa-spinner fa-pulse"></i> Loading Audiences...
              </div>
            ) : (
              "Refresh Audiences"
            )}
          </Button>
          <Button
            className={styles["create-audience-btn"]}
            variant="contained"
            onClick={() => navigateToAudienceBuilderPage()}
          >
            Create Audience
          </Button>
        </div>
        <p
          style={{
            display: loading ? "block" : "none",
            textAlign: "center",
            marginBottom: "1rem",
          }}
        >
          <i className="fa fa-spinner fa-pulse"></i> Loading Audiences...
        </p>
        <SelectionTable
          tableColumns={newColumns}
          tableRows={selections}
          hasRowClickAction
          rowClickAction={(row) => handleShowAudienceMembers(row.original.id)}
          rowActions={[
            { id: 1, text: "Edit", action: (row) => handleEditSelection(row) },
            {
              id: 2,
              text: "Delete",
              action: (row) => handleDeleteSelection(row),
            },
            {
              id: 3,
              text: "Send message",
              action: (row) => handleMessageSelection(row),
            },
          ]}
        />
      </div>

      <CustomModal
        isOpen={deletionSelectionModal}
        closeModal={closeDeleteModal}
      >
        <DeleteSelection
          refreshTable={refreshTable}
          selectedRow={selectedRow}
          closeModal={closeDeleteModal}
        />
      </CustomModal>
    </>
  );
};

export default AudienceSelections;
