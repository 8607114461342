import React from "react";
import { Button } from "@material-ui/core";
import useTriggeredSmsModal from "./useTriggeredSmsModal";
import FieldSelector from "./field-selector/FieldSelector";
import UrlFieldSelector from "./url-field-selector/UrlFieldSelector";
import SelectedFieldItem from "./selected-field-item/SelectedFieldItem";
import CustomRelatesToField from "./custom-relates-to-field/CustomRelatesToField";
import FallbackFormPopover from "./fallback-form-popover/FallbackFormPopover";
import AudienceSelect from "../common/audience-select/AudienceSelect";
import styles from "./triggeredSmsModal.module.scss";
import "./triggeredSmsModal.css";
import CustomInput from "../common/custom-input/CustomInput";
import FormFieldErrorText from "../triggered-email-modal/form-field-error-text/FormFieldErrorText";
import MessageFieldSelector from "./message-field-selector/MessageFieldSelector";

const TriggeredSmsModal = () => {
  const {
    appendUrl,
    loading,
    formIsValid,
    createBitlyLink,
    bitlyError,
    formattedUrl,
    handleformattedUrlChange,
    bitlyLoading,
    nextModal,
    prevModal,
    triggeredSms,
    messageUnits,
    isGSM,
    handleTriggeredSMSChange,
    handleTextareaChange,
    handleRelatesToChange,
    audienceList,
    selectedAudienceList,
    setSelectedAudienceList,
    onCheckBoxChange,
    fieldPickerList,
    productBrandList,
    productGroupList,
    campaignNameList,
    selectedField,
    selectedFieldList,
    removeSelectedField,
    showFallBackFormPopover,
    handleFallbackFormInputChange,
    setShowFallbackFormPopover,
    selectedProductBrand,
    selectedProductGroup,
    selectedCampaignName,
    setSelectedField,
    setSelectedProductBrand,
    setSelectedProductGroup,
    setSelectedCampaignName,
    appendSelectedField,
    appendSelectedProductBrand,
    appendSelectedProductGroup,
    appendSelectedCampaignName,
    customRelatesToValue,
    customRelatesToInputOnChange,
    appendCustomRelatesToField,
    sendTestSMSToOnChange,
    sendTestSMS,
    sendTestSMSBtnText,
    canSendTest,
    formValidator,
    fieldchange,
    appendtoURL,
    fieldchange_url,
    selectedUrlField,
    UTM_Campaign,
    UTM_Medium,
    UTM_Source,
    appendUTMs,
    setUTM_Source,
    setUTM_Medium,
    setUTM_Campaign,
    urlFields,
    removefromURL,
    removeUrl,
    pastedUrl,
    setpastedUrl,
  } = useTriggeredSmsModal();

  return (
    <div className={styles["smsModal-container"]}>
      <h1 className={styles.header}>Triggered SMS</h1>
      <p className={styles["sub-header"]}>
        Send a triggered SMS using your SMS Service Provider
      </p>
      <p style={{ display: loading ? "block" : "none", textAlign: "right" }}>
        <i className="fa fa-spinner fa-pulse"></i>
      </p>

      <div className={styles.main}>
        <div className={styles["audience-selection-container"]}>
          <AudienceSelect
            audienceList={audienceList}
            selectedAudienceList={selectedAudienceList}
            onCheckBoxChange={onCheckBoxChange}
            onSelectAll={(filteredAudienceList) =>
              setSelectedAudienceList([...filteredAudienceList])
            }
            onDeselectAll={() => setSelectedAudienceList([])}
            hasError={formValidator.selectedAudiences.hasError}
          />

          <CustomInput
            type="text"
            className={styles["message-name-input"]}
            value={triggeredSms.messageName}
            name="messageName"
            onChange={
              /*(event) => {*/
              handleTriggeredSMSChange /*(event); setUTM_Campaign({label: "utm_campaign", name: [event.target.value]});
            appendUTMs("utm_campaign", [event.target.value])}*/
            }
            placeholder="Message Name"
            hasError={formValidator.messageName.hasError}
            errorText="Enter message name"
          />
        </div>
        <div className={styles["textarea-n-select"]}>
          <div className={styles["textarea-container"]}>
            <textarea
              dir="auto"
              name="message"
              id="sms-messagebody"
              style={{
                border: formValidator.message.hasError ? "1px solid red" : "",
              }}
              value={triggeredSms.message}
              onChange={handleTextareaChange}
              placeholder="Message"
              className={styles["textarea"]}
            />
            <FormFieldErrorText
              text="Enter message"
              showText={formValidator.message.hasError}
            />
          </div>
          <div className={styles["select-container"]}>
            <MessageFieldSelector
              placeholder="Field Picker"
              onFieldChange={fieldchange}
              selectedField={selectedField.label}
              appendSelectedField={appendSelectedField}
              fieldList={fieldPickerList}
            />
            {!!selectedFieldList.length ? (
              <span className={styles["selected-fields-list-title"]}>
                Selected fields:
              </span>
            ) : (
              ""
            )}
            <div className={styles["selected-field-items-container"]}>
              {selectedFieldList &&
                selectedFieldList.map((fieldItem) => (
                  <SelectedFieldItem
                    key={fieldItem.id}
                    removeSelectedField={removeSelectedField}
                    fieldItem={fieldItem}
                  />
                ))}
            </div>
            <div className={styles["configure-fullback-btn-container"]}>
              <Button
                onClick={() =>
                  setShowFallbackFormPopover(!showFallBackFormPopover)
                }
                disabled={!selectedFieldList.length}
                className={styles["fall-back-btn"]}
                size="small"
              >
                Configure
              </Button>
              {showFallBackFormPopover && !!selectedFieldList.length && (
                <FallbackFormPopover
                  handleFallbackFormInputChange={handleFallbackFormInputChange}
                  selectedFieldList={selectedFieldList}
                />
              )}
            </div>
          </div>
        </div>
        <p
          className={styles["gsm-limit"]}
          // style={{
          //   color:
          //     triggeredSms.message.split("").length > messageLimit
          //       ? "#f14b5b"
          //       : "#232931",
          // }}
        >
          Characters: {triggeredSms.message.split("").length} (
          {isGSM ? "GSM" : "Unicode"}) / Units: {messageUnits}
        </p>
        <div className={styles["bitly-input-container"]}>
          <textarea
            className="formattedurl-textarea"
            dir="auto"
            name="onwardURL"
            value={formattedUrl}
            onChange={handleformattedUrlChange}
            disabled={true}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              data-testid="bitly-create-button"
              onClick={removeUrl}
              size="small"
              style={{ marginBottom: "0.2rem" }}
            >
              Remove&nbsp;
            </Button>
            <Button
              data-testid="bitly-create-button"
              onClick={appendUrl}
              className={styles.button}
              size="small"
            >
              Append&nbsp;
            </Button>
          </div>
        </div>
        {bitlyError ? (
          <p className={styles["bitly-error"]}>*Unable to create link</p>
        ) : (
          ""
        )}{" "}
        <br />
        <div className="urlAddons">
          <div className="utm-container">
            <p className="utm-item-header">URL:</p>
            <textarea
              className="long-textarea"
              dir="auto"
              name="pastedURL"
              placeholder="Paste URL here"
              value={pastedUrl}
              onChange={(event) => setpastedUrl(event.target.value)}
            />
          </div>
          <div className="utm-container">
            <p className="utm-item-header">Source:</p>
            <textarea
              name="utm_source"
              className="utm-item"
              onChange={(event) => {
                appendUTMs(
                  event.target.name,
                  event.target.value.replace(/\s+/g, "_"),
                );
                setUTM_Source({
                  name: event.target.name,
                  value: event.target.value.replace(/\s/g, "_"),
                });
              }}
              placeholder="Source"
              value={UTM_Source.value}
            ></textarea>
            <p className="utm-item-header">Medium:</p>
            <textarea
              name="utm_medium"
              className="utm-item"
              onChange={(event) => {
                appendUTMs(
                  event.target.name,
                  event.target.value.replace(/\s+/g, "_"),
                );
                setUTM_Medium({
                  name: event.target.name,
                  value: event.target.value.replace(/\s/g, "_"),
                });
              }}
              placeholder="Medium"
              value={UTM_Medium.value}
            ></textarea>
          </div>
          <div className="utm-container">
            <p className="utm-item-header">Campaign Name:</p>
            <textarea
              name="utm_campaign"
              className="long-textarea"
              onChange={(event) => {
                appendUTMs(
                  event.target.name,
                  event.target.value.replace(/\s+/g, "_"),
                );
                setUTM_Campaign({
                  name: event.target.name,
                  value: event.target.value.replace(/\s/g, "_"),
                });
              }}
              placeholder="Campaign Name"
              value={UTM_Campaign.value}
            ></textarea>
          </div>
        </div>
        <div className={styles["select-container"]}>
          <UrlFieldSelector
            placeholder="Other Fields"
            onFieldChange={(event) => fieldchange_url(event, urlFields)}
            selectedField={selectedUrlField.label}
            appendSelectedField={appendtoURL}
            fieldList={urlFields}
            remove={removefromURL}
          />
        </div>
        <div className={styles["textarea-n-select"]}>
          <div className={styles["textarea-container"]}>
            <textarea
              placeholder="Relates To"
              name="relatesTo"
              value={triggeredSms.relatesTo}
              onChange={handleRelatesToChange}
              className={styles["textarea"]}
            />
          </div>
          <div className={styles["select-container"]}>
            <FieldSelector
              placeholder="Campaign Names"
              onFieldChange={(event, values) => setSelectedCampaignName(values)}
              selectedField={selectedCampaignName}
              appendSelectedField={appendSelectedCampaignName}
              fieldList={campaignNameList}
            />
            <FieldSelector
              placeholder="Product Brands"
              onFieldChange={(event, values) => setSelectedProductBrand(values)}
              selectedField={selectedProductBrand}
              appendSelectedField={appendSelectedProductBrand}
              fieldList={productBrandList}
            />
            <FieldSelector
              placeholder="Product Groups"
              onFieldChange={(event, values) => setSelectedProductGroup(values)}
              selectedField={selectedProductGroup}
              appendSelectedField={appendSelectedProductGroup}
              fieldList={productGroupList}
            />
            <CustomRelatesToField
              inputValue={customRelatesToValue}
              onInputChange={customRelatesToInputOnChange}
              btnOnClick={appendCustomRelatesToField}
            ></CustomRelatesToField>
            {/* <FieldSelector
              placeholder="Product Names"
              onFieldChange={(event) =>
                setSelectedProductName(event.target.value)
              }
              selectedField={selectedProductName}
              appendSelectedField={appendSelectedProductName}
              fieldList={productNameList}
            /> */}
          </div>
        </div>
        <div className={styles["test-section"]}>
          <div className={styles.schedule}>
            <CustomInput
              min={new Date().toISOString().slice(0, -8)}
              name="schedule"
              value={triggeredSms.schedule}
              onChange={handleTriggeredSMSChange}
              type="datetime-local"
              placeholder="Optional Schedule"
              hasError={formValidator.schedule.hasError}
              errorText="No date selected"
            />
          </div>
          <div>
            <i>
              For international numbers, please include the area code for the
              number (e.g. 07123456789 would be +447123456789)
            </i>
          </div>
          <div className={styles["input-n-button"]}>
            <input
              placeholder="Sent Test To..."
              onChange={(event) => sendTestSMSToOnChange(event)}
            />
            <Button
              // disabled={!canSendTest()}
              size="small"
              onClick={() => sendTestSMS()}
            >
              {sendTestSMSBtnText}
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <Button onClick={prevModal} size="small" className={styles.cancel}>
          Back
        </Button>
        <Button
          onClick={nextModal}
          size="small"
          disabled={!formIsValid()}
          className={styles.next}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default TriggeredSmsModal;
