import React from "react";
import styles from './detailsPage.module.scss'

const DetailsPane = ({ detailData }) => {

  return (
    <div className={styles["container"]}>
      <div className={styles["item"]}>
        <div className={styles["name"]}>SKU:</div>
        <div className={styles["value"]}>{detailData["Product Key"]}</div>
      </div>
      <div className={styles["item"]}>
        <div className={styles["name"]}>Product:</div>
        <div className={styles["value"]}>{detailData["Product Description"]}</div>
      </div>
      <div className={styles["item"]}>
        <div className={styles["name"]}>Category:</div>
        <div className={styles["value"]}>{detailData["Category"]}</div>
      </div>
      <div className={styles["item"]}>
        <div className={styles["name"]}>Brand:</div>
        <div className={styles["value"]}>{detailData["Product Brand"]}</div>
      </div>
      <div className={styles["item"]}>
        <div className={styles["name"]}>Price:</div>
        <div className={styles["value"]}>{detailData["Total Amount"]}</div>
      </div>
      <div className={styles["item"]}>
        <div className={styles["name"]}>Quantity:</div>
        <div className={styles["value"]}>{detailData["Order Line Quantity"]}</div>
      </div>
      <div className={styles["item"]}>
        <div className={styles["name"]}>Colour:</div>
        <div className={styles["value"]}>{detailData["Product Colour"]}</div>
      </div>
    </div>
  );
};

export default DetailsPane;
