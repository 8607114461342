export const getIndividuals = async (obj, api) => {
  const response = api
    .post(`Search/Individual`, obj)
    .then((res) => res)
    .catch((err) => err);

  return response;
};

export const getControls = async (route, api) => {
  try {
    const response = await api.get(
      `SystemManagement/Controls/GetControls?route=${route}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getLatestIndividuals = async (api) => {
  try {
    const response = await api.get('/Search/GetLatestIndividuals')
    return response
  } catch(err){
    console.log(err)
  }
}

export const getControlData = async (query, body, api) => {
  return api.post(`PageControls/ControlData?queryname=${query}`, body);
};
