import React from "react";
import uuid from 'react-uuid'

const ProfilesAddressTab = ({ addressDetails }) => {
  return addressDetails
    ? addressDetails.map((addressDetail) => {
        return (
          <div key={uuid()}>
            <h5>Address Details</h5>
            <table>
              <tbody>
                {Object.keys(addressDetail).map((key) => {
                  return (
                    <tr key={uuid()}>
                      <td style={{ paddingRight: "1rem" }}>{key}:</td>
                      <td style={{ paddingRight: "1rem" }}>
                        {addressDetail[key]}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })
    : "Loading...";
};

export default ProfilesAddressTab;
