import React from "react";
import styles from "./autoRefreshToggle.module.scss";
import { ToggleButton } from "@mui/material";

const AutoRefreshToggle = ({ handleChange, isOn, isOff }) => {
  return (
    <div className={styles["container"]}>
      <p className={styles["auto-refresh-text"]}>Toggle Auto Refresh</p>
      <div className={styles["input-container"]}>
        <label className={styles["radio-btn-label"]} htmlFor="auto-refresh-on">
          On
        </label>
        <input
          className={styles["radio-btn"]}
          id="auto-refresh-on"
          name="table-refresh"
          value="On"
          onChange={handleChange}
          checked={isOn}
          type="radio"
        />
      </div>

      <div className={styles["input-container"]}>
        <label className={styles["radio-btn-label"]} htmlFor="auto-refresh-off">
          Off
        </label>
        <input
          className={styles["radio-btn"]}
          id="auto-refresh-off"
          name="table-refresh"
          value="Off"
          onChange={handleChange}
          checked={isOff}
          type="radio"
        />
      </div>
    </div>
  );
};

export default AutoRefreshToggle;
