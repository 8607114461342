import React, { useEffect, useState } from "react";
import { getProperties, getData } from "./masterDataManagement.service";
import MasterDataManagementTable from "./MasterDataManagementTable/MasterDataManagementTable";
import { useIsAuthenticated } from "@azure/msal-react";
import ApiNew from "../../common/api/apinew.service";

const MasterDataManagement = () => {
  const { api } = ApiNew();
  const [primaryField, setPrimaryField] = useState("");
  const [masterData, setMasterData] = useState("");
  const [excludeFields, setExcludeFields] = useState("");

  const isAuthenticated = useIsAuthenticated();

  const tableName = "Product";

  useEffect(() => {
    (async () => {
      if (isAuthenticated && api) {
        const properties = await getProperties(tableName, api);

        properties &&
          properties.forEach((property) => {
            if (property.name === "HO_MDM_PrimaryKey") {
              setPrimaryField(property.value);
            }

            if (property.name === "HO_MDM_ExcludeFields") {
              setExcludeFields(property.value);
            }
          });
      }
    })();
  }, [api, isAuthenticated]);

  useEffect(() => {
    (async () => {
      if (isAuthenticated && api) {
        const data = await getData(tableName, api);

        setMasterData(data);
      }
    })();
  }, [api, isAuthenticated]);

  return (
    <div>
      {masterData && primaryField ? (
        <MasterDataManagementTable
          primaryField={primaryField}
          masterData={masterData}
        />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default MasterDataManagement;
