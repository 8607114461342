import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    individualSearchObj : null,
    personID: null,
    orderId: null,
}

const individualPageSlice = createSlice({
    name: 'individualPage',
    initialState: INITIAL_STATE,
    reducers: {
        setPersonID: (state, action) => {
            state.personID = action.payload
        },
        setIndividualSearchObj: (state, action) => {
            state.individualSearchObj = action.payload
        }
    }
})


export const { setPersonID, setExpandTopNavSearchResults, setIndividualSearchObj } = individualPageSlice.actions
export default individualPageSlice.reducer