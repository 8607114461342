import React, { useState, useEffect } from 'react'
import DetailCardBtn from './detail-card-btn/DetailCardBtn';
import DetailsPane from './details-pane/DetailsPane';
import uuid from 'react-uuid'
import styles from './itemDetailsTab.module.scss'

const ItemDetailsTab = ({ detailsData }) => {
    const [selectedItem, setSelectedItem] = useState();
    const [detailPaneData, setDetailPaneData] = useState();

    useEffect(() => {
        const initialSelectedDetail = detailsData[0];

        setSelectedItem(initialSelectedDetail["Order Line No"]);
        setDetailPaneData(initialSelectedDetail);
    }, [detailsData]);

    const selectDetail = (detail) => {
        setSelectedItem(detail["Order Line No"]);
        setDetailPaneData(detail);
    };

    return (
        <div className={styles["container"]}>
            <div className={styles["leftSide"]}>
                {detailsData.map((detail) => {
                    return (
                    <DetailCardBtn
                        key={uuid()}
                        detail={detail}
                        selectDetail={selectDetail}
                        selectedItem={selectedItem}
                    />
                    );
                })}
            </div>
            <div className={styles["rightSide"]}>
                {detailPaneData ? <DetailsPane detailData={detailPaneData} /> : ""}
            </div>
      </div>
    )
}

export default ItemDetailsTab