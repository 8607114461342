import React from 'react'
import FormFieldErrorText from './../../triggered-email-modal/form-field-error-text/FormFieldErrorText';

const CustomInput = ({ errorText, hasError, ...otherProps }) => {
  return (
    <div style={{ width: '100%' }}>
      <input style={{ border: hasError ? '1px solid red' : ''}} dir='auto' {...otherProps}/>
      <FormFieldErrorText text={errorText} showText={hasError} />
    </div>
  )
}

export default CustomInput