import React, { useState } from 'react'
import CustomButton from '../../../../common/components/mui-custom-button/CustomButton';
import styles from './advanceSearch.module.scss';

const defaultValue = {
    xcmindid: "",
    personid: "",
    forenames: "",
    middlenames: "",
    surname: "",
    dob: "",
    addressline1: "",
    postcode: "",
    email: "",
    telephone: "",
    mobilenumber: "",
  };

const AdvanceSearch = ({ doSearch }) => {
    const [searchItems, setSearchItems] = useState(defaultValue);
    const [xcmindidError, setXcmindidError] = useState(false)

    //on key press will continue making requests if key is held down.
    //on key up does not!
    const advancedSearchBox_OnKeyUp = (event) => {
        if (event.key === "Enter") {
            advancedSearch_Click();
        }
    };

    const advancedSearch_Click = () => {
        let cleansedSearchItems = {};

        const keys = Object.keys(searchItems);

        keys.forEach((key) => {
        if (searchItems[key]) {
            cleansedSearchItems[key] = searchItems[key];
        }
        });

        doSearch(cleansedSearchItems);
    };

    let handleChange = (event) => {
        let { name, value } = event.target;

        if(name === "xcmindid"){
            if(value !== ''){
                setXcmindidError(Number(value) < 0)
            } else {
                setXcmindidError(false)
            }
        }

        setSearchItems({ ...searchItems, [name]: value });
    };
    

    const reset = () => {
        setSearchItems(defaultValue);
    };

    return (
        <div className={styles.container}>
            <div className={styles["form-container"]}>
                <div style={{ width: "50%" }}>
                    <div>
                        <h4>IDs</h4>
                        <div>
                            <input
                                min={0}
                                placeholder='XCM Ind ID'
                                type="number"
                                onChange={handleChange}
                                onKeyUp={advancedSearchBox_OnKeyUp}
                                name="xcmindid"
                                value={searchItems.xcmindid}
                            />
                            {xcmindidError ? <p className={styles["xcmid-error-text"]}>ID cannot be negative</p> : ''}
                        </div>
                        <div>
                            <input
                                placeholder='Person ID'
                                type="text"
                                onChange={handleChange}
                                onKeyUp={advancedSearchBox_OnKeyUp}
                                name="personid"
                                value={searchItems.personid}
                            />
                        </div>
                    </div>
                    <div style={{ paddingTop: "2rem" }}>
                        <h4>Personal Info</h4>
                        <div>
                            <input
                                placeholder='Forenames'
                                type="text"
                                onChange={handleChange}
                                onKeyUp={advancedSearchBox_OnKeyUp}
                                name="forenames"
                                value={searchItems.forenames}
                            />
                        </div>
                        <div>
                            <input
                                placeholder='Middle Names'
                                type="text"
                                onChange={handleChange}
                                onKeyUp={advancedSearchBox_OnKeyUp}
                                name="middlenames"
                                value={searchItems.middlenames}
                            />
                        </div>
                        <div>
                            <input
                                placeholder='Surname'
                                type="text"
                                onChange={handleChange}
                                onKeyUp={advancedSearchBox_OnKeyUp}
                                name="surname"
                                value={searchItems.surname}
                            />
                        </div>
                        <div>
                            <input
                                placeholder='DOB'
                                type="text"
                                onFocus={(e) => {e.target.type='date'}}
                                onBlur={(e) => {e.target.type='text'}}
                                onChange={handleChange}
                                onKeyUp={advancedSearchBox_OnKeyUp}
                                name="dob"
                                value={searchItems.dob}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ width: "50%" }}>
                    <div>
                        <h4>Address</h4>
                    </div>
                    <div>
                        <input
                        placeholder='Address Line 1'
                        type="text"
                        onChange={handleChange}
                        onKeyUp={advancedSearchBox_OnKeyUp}
                        name="addressline1"
                        value={searchItems.addressline1}
                        />
                    </div>
                    <div>
                        <input
                        placeholder='Postcode'
                        type="text"
                        onChange={handleChange}
                        onKeyUp={advancedSearchBox_OnKeyUp}
                        name="postcode"
                        value={searchItems.postcode}
                        />
                    </div>
                    <div>
                        <input
                        placeholder='Email'
                        type="text"
                        onChange={handleChange}
                        onKeyUp={advancedSearchBox_OnKeyUp}
                        name="email"
                        value={searchItems.email}
                        />
                    </div>
                    <div>
                        <input
                        placeholder='Telephone'
                        type="text"
                        onChange={handleChange}
                        onKeyUp={advancedSearchBox_OnKeyUp}
                        name="telephone"
                        value={searchItems.telephone}
                        />
                    </div>
                    <div>
                        <input
                        placeholder='Mobile'
                        type="text"
                        onChange={handleChange}
                        onKeyUp={advancedSearchBox_OnKeyUp}
                        name="mobilenumber"
                        value={searchItems.mobile}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.actions} style={{ textAlign: "right" }}>
                <CustomButton
                btnSize='small'
                bgColor='grey'
                textColor='#fff'
                onClick={() => reset()}
                >
                    Clear
                </CustomButton>
                <CustomButton
                disabled={xcmindidError}
                className={styles['search-btn']}
                useMuiStyle={true}
                textColor='#fff'
                btnSize='small'
                onClick={() => advancedSearch_Click()}
                >
                    Search
                </CustomButton>
            </div>
        </div>
    )
}

export default AdvanceSearch