import { useState, useEffect, useMemo, useRef } from "react";
import {
  getCountV2,
  getFieldsV2,
  updateSelectionV2,
  submitQueryV3,
  checkIfAudienceNameIsInUse,
} from "../audienceSelector.service.js";
import { v4 as generateID } from "uuid";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAudienceToView } from "../../../redux/audience-selector/audience-selector.reducer";
import ApiNew from "../../../common/api/apinew.service";
import { useIsAuthenticated } from "@azure/msal-react";

const useAudienceBuilder = (selectedRow) => {
  const { api } = ApiNew();
  let timer = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fieldGroups, setFieldGroups] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [loadingCount, setLoadingCount] = useState(false);
  const [audienceLimitError, setAudienceLimitError] = useState(false);
  const [checkingAudienceName, setCheckingAudienceName] = useState(false);
  const [audienceNameError, setAudienceNameError] = useState(false);
  const [audienceInfo, setAudienceInfo] = useState({
    audienceId: "",
    audienceName: "",
    audienceDescription: "",
    audienceLimit: "",
    count: "",
    refreshedAt: "",
  });
  const [defaultJson, setDefaultJson] = useState({
    id: generateID(),
    combinator: "and",
    not: false,
    rules: [],
  });
  const [json, setJson] = useState(defaultJson);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (api && isAuthenticated) {
      getQueryFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, isAuthenticated]);

  useEffect(() => {
    if (selectedRow) {
      setAudienceInfo({
        audienceId: selectedRow.id,
        audienceName: selectedRow.name,
        audienceDescription: selectedRow.description,
        audienceLimit: selectedRow.limit.toString(),
        count: selectedRow.count.toString(),
        refreshedAt: selectedRow.refreshedAt,
      });
      
      
      if (selectedRow.json) {
        const jsonObj = JSON.parse(JSON.stringify(selectedRow.json));
        setDefaultJson(jsonObj);
      }

      const dailyAutoRefresh =
        selectedRow.autoRefresh.toLowerCase() === "yes" ? true : false;
      setAutoRefresh(dailyAutoRefresh);
      setCount(selectedRow.count);
    }

    return () => {
      clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  let getQueryFields = async () => {
    const fieldData = await getFieldsV2(api);
    setFieldGroups(parseJSON(fieldData));
  };

  let handleAudienceLimitChange = (event) => {
    const { value } = event.target;

    if (value !== "") {
      setAudienceLimitError(Number(value) < 0);
    } else {
      setAudienceLimitError(false);
    }

    handleChange(event);
  };

  const audienceNameTimer = useRef();

  let checkAudienceName = (event) => {
    const { value } = event.target;

    clearTimeout(audienceNameTimer.current);

    if (!!value) {
      setAudienceNameError(false);
      setCheckingAudienceName(true);

      audienceNameTimer.current = setTimeout(async () => {
        let nameInUse;
        if (selectedRow) {
          nameInUse = await checkIfAudienceNameIsInUse(
            api,
            value,
            audienceInfo.audienceId
          ); //returns a boolean
        } else {
          nameInUse = await checkIfAudienceNameIsInUse(api, value); //returns a boolean
        }

        setAudienceNameError(nameInUse);
        setCheckingAudienceName(false);
      }, 2000);
    } else {
      setAudienceNameError(false);
      setCheckingAudienceName(false);
    }

    handleChange(event);
  };

  let handleChange = (event) => {
    let { name, value } = event.target;

    setAudienceInfo({
      ...audienceInfo,
      [name]: value.trimLeft(),
    });
  };

  let fetchCount = async (json, api) => {
    const count = await getCountV2(json, api);
    setCount(count);
    setLoadingCount(false);
  };

  let setJsonState = useMemo(() => {
    return (json) => {
      setJson(json);
      setLoadingCount(true);

      if (json.rules.length) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          if (api) {
            fetchCount(json, api);
          } else {
            setCount(0);
            setLoadingCount(false);
          }
        }, 3000);
      } else {
        setCount(0);
        setLoadingCount(false);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  let submit = async () => {
    clearTimeout(timer.current);
    setLoading(true);


    const body = {
      name: audienceInfo.audienceName,
      description: audienceInfo.audienceDescription,
      audienceLimit: audienceInfo.audienceLimit,
      count: audienceInfo.count,
      json: json,
      autoRefresh,
    };

    if (selectedRow) {
      updateSelectionV2(selectedRow.id, audienceInfo.audienceName, body, api);
    } else {
      submitQueryV3(audienceInfo.audienceName, body, api);
    }

    //let it do something before we send it off or else the audiences might be created.
    setTimeout(() => {
      setLoading(false);
      navigate("/connections");
    }, 2000);
  };

  let parseJSON = (fieldData) => {
    const parsedFields = fieldData.map((field) => {
      return {
        ...field,
        operators: JSON.parse(field.operators),
      };
    });
    return parsedFields;
  };

  let formIsValid = () => {
    return (
      audienceInfo.audienceName &&
      audienceInfo.audienceDescription &&
      !audienceLimitError &&
      !checkingAudienceName &&
      !audienceNameError &&
      audienceInfo.count >= 0
    );
  };

  let viewAudience = () => {
    dispatch(setAudienceToView(selectedRow.id));
    navigate("/audience-record");
  };

  let returnToAudiences = () => {
    navigate("/audience-record");
  };

  return {
    autoRefresh,
    setAutoRefresh,
    loadingCount,
    viewAudience,
    handleChange,
    checkAudienceName,
    audienceNameError,
    checkingAudienceName,
    handleAudienceLimitChange,
    audienceLimitError,
    fieldGroups,
    defaultJson,
    audienceInfo,
    count,
    setJsonState,
    loading,
    formIsValid,
    submit,
    returnToAudiences,
  };
};

export default useAudienceBuilder;
