import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIsAuthenticated } from "@azure/msal-react";
import {
  getBroadcasts,
  setApproveCampaign,
  setDeclineCampaign
} from "./adhocCommunication.service";
import { useNavigate } from "react-router-dom";
import {
  setBroadcastCampaignId,
  resetAdhocCommunicationState
} from "../../redux/adhoc-communication/adhoc-communication.reducer";
import { toast } from "react-toastify";
import ApiNew from "../../common/api/apinew.service";

export const useAdhocCommunication = () => {
  const { api } = ApiNew();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const communicationModalOpen = useSelector(
    ({ adhocCommunication: { communicationModalOpen } }) =>
      communicationModalOpen
  );
  const communicationModalId = useSelector(
    ({ adhocCommunication: { communicationModalId } }) => communicationModalId
  );

  const [newColumns, setNewColumns] = useState([]);
  const [broadcasts, setBroadcasts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableAutoRefresh, setTableRefresh] = useState("");

  const isAuthenticated = useIsAuthenticated();

  const interval = useRef();
  useEffect(() => {
    if (tableAutoRefresh === "On") {
      interval.current = setInterval(() => {
        refreshTable();
      }, 30000);
    } else {
      clearInterval(interval.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableAutoRefresh]);

  useEffect(() => {
    const tableAutoRefreshValue = localStorage.getItem(
      "communications-table-auto-refresh"
    );
    setTableRefresh(tableAutoRefreshValue ?? "Off");
  }, []);

  useEffect(() => {
    (async () => {
      fetchBroadcasts();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, isAuthenticated]);

  const friendlyColumnName = (key) => {
    return key
      .toString()
      .replace("_", " ") //remove _ with spaces
      .split(" ") //split the word so we can get sub-words
      .map((word) => word[0].toUpperCase() + word.substring(1, word.length)) //upper case first letter of each word.
      .join(" "); //rejoin words with spaces between
  };

  let fetchBroadcasts = async () => {
    setLoading(true);

    if (isAuthenticated && api) {
      const broadcastData = await getBroadcasts(api);

      if (broadcastData.length > 0) {
        setBroadcasts(convertScheduledForToReadableDate(broadcastData));

        const columnKeys = Object.keys(broadcastData[0]);

        setNewColumns(
          columnKeys.map((key) => ({
            Header: friendlyColumnName(key),
            accessor: key
          }))
        );
      } else {
        setBroadcasts([]);
      }
    }

    setLoading(false);
  };

  const convertScheduledForToReadableDate = (broadcastData) => {
    return broadcastData.map((broadcast) => {
      return {
        ...broadcast,
        // eslint-disable-next-line no-useless-computed-key
        ["Scheduled For"]: broadcast["Scheduled For"]
          ? new Date(broadcast["Scheduled For"])
              .toLocaleString()
              .replace(",", "")
          : null
      };
    });
  };

  const handleTableAutoRefreshValueChange = (event) => {
    const value = event.target.value;
    setTableRefresh(value);

    localStorage.setItem("communications-table-auto-refresh", value);
    toast("Campaigns auto refresh turned " + value.toLowerCase());
  };

  const viewBroadcast = (campaignId) => {
    dispatch(setBroadcastCampaignId(campaignId));
    navigate("/broadcast-details");
  };

  const approveCampaign = async (campaignId) => {
    await setApproveCampaign(campaignId, api);

    refreshTable();
  };

  const declineCampaign = async (campaignId) => {
    await setDeclineCampaign(campaignId, api);

    refreshTable();
  };

  let refreshTable = () => {
    fetchBroadcasts();
  };

  let closeCommunicationModal = () => {
    dispatch(resetAdhocCommunicationState());
  };

  return {
    newColumns,
    broadcasts,
    loading,
    communicationModalId,
    communicationModalOpen,
    closeCommunicationModal,
    viewBroadcast,
    approveCampaign,
    declineCampaign,
    refreshTable,
    tableAutoRefresh,
    handleTableAutoRefreshValueChange
  };
};
