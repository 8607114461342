import React from "react";
import Button from "@material-ui/core/Button";
import Switch from "@mui/material/Switch";
import useAudienceBuilder from "./useAudienceBuilder";
import XCMAudienceSelector from "./xcm_audienceselector/XCMAudienceSelector";
import styles from "./audienceBuilder.module.scss";
import CircularProgress from "@mui/material/CircularProgress";

const AudienceBuilder = ({ selectedRow }) => {
  const {
    autoRefresh,
    setAutoRefresh,
    loadingCount,
    viewAudience,
    handleChange,
    checkAudienceName,
    audienceNameError,
    checkingAudienceName,
    handleAudienceLimitChange,
    audienceLimitError,
    fieldGroups,
    defaultJson,
    audienceInfo,
    count,
    setJsonState,
    loading,
    formIsValid,
    submit,
    returnToAudiences,
  } = useAudienceBuilder(selectedRow);

  return (
    <div
      data-testid="audience-builder-modal-container"
      className={styles["audience-builder-container"]}
    >
      <h1 className={styles.header}>Audience Builder</h1>
      <p className={styles["sub-header"]}>
        Create and edit groupings of individuals
      </p>

      <div className={styles["audience-info-container"]}>
        <div className={styles["audience-info"]}>
          <div className={styles["audience-name-input-container"]}>
            <input
              dir="auto"
              maxLength={100}
              className={styles["input"]}
              value={audienceInfo.audienceName}
              onChange={checkAudienceName}
              placeholder="Audience Name"
              name="audienceName"
              type="text"
            />
            {audienceNameError ? (
              <p className={styles["audience-name-error-text"]}>
                There is already an audience with this name
              </p>
            ) : (
              ""
            )}
            {checkingAudienceName ? (
              <i
                style={{ marginLeft: "0.25rem" }}
                className="fa fa-spinner fa-pulse"
              ></i>
            ) : (
              ""
            )}
          </div>
          <input
            dir="auto"
            className={styles["input"]}
            maxLength={100}
            value={audienceInfo.audienceDescription}
            onChange={handleChange}
            placeholder="Audience Description"
            name="audienceDescription"
            type="text"
          />
        </div>

        <div className={styles["audience-contacts"]}>
          <div className={styles.wrapper}>
            <div className={styles.contacts}>
              Audience Contacts:{" "}
              <span style={{ fontWeight: loadingCount ? "normal" : "bold" }}>
                {loadingCount
                  ? "Processing Audience..."
                  : audienceInfo.audienceLimit > 0 && audienceInfo.audienceLimit <= count
                  ? count + " (Limited to " + audienceInfo.audienceLimit + ")"
                  : count}
              </span>
              <div>
                <span style={{ color: "red" }}>
                  {!loadingCount && audienceInfo.audienceLimit > count
                    ? "Note: Number of members is less than the limit."
                    : ""}
                </span>
              </div>
            </div>
            <div className={styles["view-n-limit"]}>
              <Button
                disabled={!selectedRow}
                onClick={viewAudience}
                size="small"
              >
                View
              </Button>
              <div className={styles["limit-input-container"]}>
                <input
                  name="audienceLimit"
                  type="number"
                  min={0}
                  onChange={handleAudienceLimitChange}
                  value={audienceInfo.audienceLimit}
                  placeholder="Audience limit"
                  className={styles["limit-input"]}
                />
                {audienceLimitError ? (
                  <p
                    data-testid="audience-limit-error-message"
                    className={styles["limit-error-message"]}
                  >
                    limit cannot be less than 0
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["refresh-container"]}>
        <Button disableRipple size="small" className={styles["refresh-btn"]}>
          Refresh&nbsp;<i className="fa-solid fa-arrows-spin fa-pulse"></i>
        </Button>
        {audienceInfo.refreshedAt ? (
          <p className={styles["last-refresh-text"]}>
            Last refresh:{" "}
            <em>{new Date(audienceInfo.refreshedAt).toString()}</em>
          </p>
        ) : (
          ""
        )}
      </div>

      <div className={styles["query-builder-container"]}>
        <XCMAudienceSelector
          fieldGroups={fieldGroups}
          defaultQuery={defaultJson}
          onQueryChange={(json) => setJsonState(json)}
        />
      </div>

      <div className={styles["auto-refresh-container"]}>
        <h5 className={styles["auto-refresh-header"]}>Auto Refresh Daily</h5>
        <Switch
          onChange={(e) => setAutoRefresh(e.target.checked)}
          checked={autoRefresh}
          sx={{
            ".MuiSwitch-switchBase.Mui-checked": {
              color: "#83C1B9",
            },
            ".MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
              backgroundColor: "#83C1B9",
            },
          }}
        />
      </div>

      <div className={styles.actions}>
        <Button
          onClick={() => returnToAudiences()}
          size="small"
          className={styles.close}
        >
          Return to Audiences
        </Button>
        <Button
          data-testid="save-button"
          size="small"
          onClick={submit}
          className={styles.save}
          disabled={!formIsValid() || loading}
        >
          Save
          <CircularProgress
            style={{
              color: "#ffffff",
              display: loading ? "block" : "none",
              marginLeft: "1rem",
            }}
            size="1rem"
          />
        </Button>
      </div>
    </div>
  );
};

export default AudienceBuilder;
