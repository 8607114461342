import React from "react";
import Button from "@material-ui/core/Button";
import Rule from "../rule/Rule";
import combinators from "../constants/combinators.json";
import styles from "./group.module.scss";

const Group = ({
  fieldGroups,
  groupData,
  onFieldGroupChange,
  onFieldChange,
  onHasRelativeDateChange,
  onSelectedFieldChange,
  onOperatorChange,
  onValueChange,
  setRuleDataValue,
  onBetweenValueChange,
  onSearchableMultiSelectValueChange,
  onCombinatorChange,
  addRuleClick,
  addGroupClick,
  deleteRuleClick,
  deleteGroupClick,
}) => {
  return (
    <div
      className={styles["group-container"]}
      key={groupData.id + "_parentdiv"}
    >
      <div className={styles["group-wrapper"]}>
        <div className={styles["action-wrapper"]}>
          <select
            className={styles.combinator}
            key={groupData.id + "_combinatorselect"}
            onChange={(e) => onCombinatorChange(groupData.id, e)}
            value={groupData.combinator ?? combinators[0]}
          >
            {combinators.map((combinator) => (
              <option
                key={groupData.id + "_combinatorselect_" + combinator}
                value={combinator}
              >
                {combinator}
              </option>
            ))}
          </select>
          <Button
            size="small"
            key={groupData.id + "_addrulebtn"}
            onClick={() => addRuleClick(groupData.id)}
            className={styles["rule-button"]}
          >
            Add Rule
          </Button>
          <Button
            size="small"
            key={groupData.id + "_addgroupbtn"}
            onClick={() => addGroupClick(groupData.id)}
            className={styles["group-button"]}
          >
            Add Group
          </Button>
        </div>

        {groupData.id !== 1 ? (
          <button
            key={groupData.id + "_deletegroupbtn"}
            className={styles["remove-group-button"]}
            size="small"
            onClick={(e) => deleteGroupClick(groupData.id, e)}
          >
            <span className="material-icons-outlined">close</span>
          </button>
        ) : (
          ""
        )}
      </div>

      {groupData.rules?.map((rule) => {
        return rule.rules ? (
          <Group
            key={rule.id + "_groupcomp"}
            fieldGroups={fieldGroups}
            setRuleDataValue={setRuleDataValue}
            groupData={rule}
            onFieldGroupChange={onFieldGroupChange}
            onFieldChange={onFieldChange}
            onHasRelativeDateChange={onHasRelativeDateChange}
            onSelectedFieldChange={onSelectedFieldChange}
            onOperatorChange={onOperatorChange}
            onValueChange={onValueChange}
            onBetweenValueChange={onBetweenValueChange}
            onSearchableMultiSelectValueChange={
              onSearchableMultiSelectValueChange
            }
            onCombinatorChange={onCombinatorChange}
            addRuleClick={addRuleClick}
            deleteRuleClick={deleteRuleClick}
            addGroupClick={addGroupClick}
            deleteGroupClick={deleteGroupClick}
          />
        ) : (
          <Rule
            key={rule.id + "_rulecomp"}
            fieldGroups={fieldGroups}
            ruleData={rule}
            onFieldGroupChange={onFieldGroupChange}
            onFieldChange={onFieldChange}
            onHasRelativeDateChange={onHasRelativeDateChange}
            onSelectedFieldChange={onSelectedFieldChange}
            onOperatorChange={onOperatorChange}
            onValueChange={onValueChange}
            setRuleDataValue={setRuleDataValue}
            onBetweenValueChange={onBetweenValueChange}
            onSearchableMultiSelectValueChange={
              onSearchableMultiSelectValueChange
            }
            deleteRuleClick={deleteRuleClick}
          ></Rule>
        );
      })}
    </div>
  );
};

export default Group;
