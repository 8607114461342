import React from "react";
import RowButton from "../RowButton/RowButton";

const EditableRow = ({
  row,
  primaryKey,
  editRecordData,
  handleSaveClick,
  handleCancelClick,
  handleEditRecordChange,
}) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        if (cell.column.id === primaryKey || cell.column.id === "edited") {
          return <td style={{textAlign: 'center'}} key={cell.column.id}>{editRecordData[cell.column.id]}</td>;
        } else {
          return (
            <td key={cell.column.id}>
              <input
                onChange={(e) => handleEditRecordChange(e, cell.column.id)}
                value={editRecordData[cell.column.id]}
              />
            </td>
          );
        }
      })}
      <td>
        <RowButton materialIcon="save" onClick={(e) => handleSaveClick(e)} />
        <RowButton
          materialIcon="cancel"
          onClick={() => handleCancelClick(row.original.id)}
        />
      </td>
    </tr>
  );
};

export default EditableRow;
