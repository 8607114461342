import React from "react";
import OrdersCard from "./orders-card/OrdersCard";
import uuid from 'react-uuid';

const OrdersCards = ({ orderHeaders, orderLines }) => {
  return orderHeaders.map((orderHeader) => {
    const orderLinesForHeader = orderLines?.filter(
      (line) => line["Order ID"] === orderHeader["Order ID"]
    );

    return (
      <OrdersCard key={uuid()} orderHeader={orderHeader} orderLines={orderLinesForHeader} />
    );
  });
};

export default OrdersCards;
