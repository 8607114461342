import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import styles from "./tableRow.module.scss";
import PopoverMenu from "../popover-menu/PopoverMenu";

const TableRow = ({
  row,
  rowActions,
  hasRowClickAction,
  rowClickAction,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <tr
      className={styles.tr}
      {...row.getRowProps()}
      data-testid={`audiences-table-row-${row.original.id}`}
    >
      {row.cells.map((cell) => (
        <td
          onClick={hasRowClickAction ? () => rowClickAction(row) : () => {}}
          className={styles.td}
          {...cell.getCellProps()}
        >
          {cell.render("Cell")}
        </td>
      ))}
      {!!rowActions && (
        <td className={styles.td}>
          <IconButton
            data-testid={`row-id-${row.original.id}-action-button`}
            onClick={() => setShowMenu(!showMenu)}
            size="small"
            aria-label="more-vert"
          >
            <span className="material-icons">more_vert</span>
          </IconButton>
          {showMenu ? (
            <PopoverMenu
              row={row}
              menuData={rowActions}
              showMenu={showMenu}
              close={() => setShowMenu(false)}
            />
          ) : (
            ""
          )}
        </td>
      )}
    </tr>
  );
};

export default TableRow;
