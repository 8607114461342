import { useState, useEffect } from "react";
import { getFields, getSelections } from "../../audience-selector/audienceSelector.service";
import { useDispatch, useSelector } from "react-redux";
import { setAdhocEmail, switchModal } from "../../../redux/adhoc-communication/adhoc-communication.reducer";
import { useIsAuthenticated } from "@azure/msal-react";
import ApiNew from "../../../common/api/apinew.service";

const useTriggerEmailModal = () => {
  const { api } = ApiNew();
  const dispatch = useDispatch();
  const reduxAdhocEmail = useSelector(
    ({ adhocCommunication: { adhocEmail } }) => adhocEmail
  );
  const reduxSelectedAudience = useSelector(
    ({ adhocCommunication: { selectedAudience } }) => selectedAudience
  );

  const [audienceList, setAudienceList] = useState([]);
  const [selectedAudienceList, setSelectedAudienceList] = useState([])

  const [messageTemplateList] = useState([
    { id: 1, template: "Service Update Feb 2022 Final" },
  ]);
  const [listItems, setListItems] = useState([]);
  const [ignoredListItems, setIgnoredListItems] = useState([]);
  const [selectedListItems, setSelectedListItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextButtonIsClicked, setNextButtonIsClicked] = useState(false)

  const isAuthenticated = useIsAuthenticated();

  const [formValidator, setFormValidator] = useState({
    selectedAudience: { hasError: false },
    messageName: { hasError: false },
    campaignId: { hasError: false, errorMessage: 'Enter campaign ID' },
    messageTemplate: { hasError: false },
    selectedFields: { hasError: false },
    schedule: { hasError: false },
})

  const [triggeredEmail, setTriggeredEmail] = useState({
    selectedAudiences: [],
    messageName: "",
    campaignId: "",
    messageTemplate: "",
    fields: {
      selectedFields: [],
      ignoredFields: [],
    },
    schedule: new Date().toISOString().slice(0, -8),
  });

  useEffect(() => {
    (async () => {
      if (isAuthenticated && api) {
        setLoading(true);

        const audiences = await getSelections(api);
        setAudienceList(audiences);

        const listItemsData = await getFields(api);

        const listWithCheckedProperty =
          addCheckedPropertyToListItem(listItemsData);
        setListItems(listWithCheckedProperty);
        setIgnoredListItems(listWithCheckedProperty);

        // Fill form fields and drop down with previously inserted values
        if (reduxAdhocEmail) {
          setTriggeredEmail(reduxAdhocEmail);
          setSelectedListItems(reduxAdhocEmail.fields.selectedFields);
          setIgnoredListItems(reduxAdhocEmail.fields.ignoredFields);
        }
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, api]);

  useEffect(() => {
    if (listItems.length) {
      setTriggeredEmail({
        ...triggeredEmail,
        fields: {
          selectedFields: selectedListItems,
          ignoredFields: ignoredListItems,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListItems, ignoredListItems]);

  useEffect(() => {
    if(reduxAdhocEmail && reduxAdhocEmail.selectedAudiences.length){
      setSelectedAudienceList([...reduxAdhocEmail.selectedAudiences])
    } else if(reduxSelectedAudience){
      setSelectedAudienceList([reduxSelectedAudience])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxSelectedAudience])

  useEffect(() => {
    setTriggeredEmail({
      ...triggeredEmail,
      selectedAudiences: selectedAudienceList
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAudienceList])

  useEffect(() => {
    if(nextButtonIsClicked){
      updateFormValidator()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggeredEmail])

  let handleCampaignIdChange = (event) => {
    const { value } = event.target;

    if(value < 0){
      const validator = Object.assign({}, formValidator)
      validator.campaignId.hasError = true
      validator.campaignId.errorMessage = 'campaign ID cannot be negative'
      setFormValidator(validator)
    } else {
      const validator = Object.assign({}, formValidator)
      validator.campaignId.hasError = false
      validator.campaignId.errorMessage = 'Enter campaign ID'
      setFormValidator(validator)
    }

    handleTriggeredEmailChange(event)
  }

  let handleTriggeredEmailChange = (event) => {
    const { name, value } = event.target;

    setTriggeredEmail({
      ...triggeredEmail,
      [name]: value,
    });
  };

  let addAllListItemsAsField = () => {
    setSelectedListItems([...listItems]);

    setIgnoredListItems([]);
  };

  let removeAllListItemsAsField = () => {
    setIgnoredListItems([...listItems]);

    setSelectedListItems([]);
  };

  let addCheckedListItemAsField = () => {
    const checkedItems = ignoredListItems.filter((item) => item.checked);

    setSelectedListItems([
      ...selectedListItems,
      ...checkedItems.map((item) => {
        return {
          ...item,
          checked: false,
        };
      }),
    ]);
    setIgnoredListItems(ignoredListItems.filter((item) => !item.checked));
  };

  let removeCheckedListItemAsField = () => {
    const checkedItems = selectedListItems.filter((item) => item.checked);

    setIgnoredListItems([
      ...ignoredListItems,
      ...checkedItems.map((item) => {
        return {
          ...item,
          checked: false,
        };
      }),
    ]);

    setSelectedListItems(selectedListItems.filter((item) => !item.checked));
  };

  let handleChecked = (id) => {
    setIgnoredListItems(
      ignoredListItems.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            checked: !item.checked,
          };
        }
        return item;
      })
    );

    setSelectedListItems(
      selectedListItems.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            checked: !item.checked,
          };
        }
        return item;
      })
    );
  };

  const onCheckBoxChange = (audienceId) => {
    if(selectedAudienceList.some(aud => aud.id === audienceId)){
      const filteredList = selectedAudienceList.filter(aud => aud.id !== audienceId)
      setSelectedAudienceList(filteredList)
    } else {
      const audience = audienceList.find(aud => aud.id ===  audienceId)
      setSelectedAudienceList(selectedAudienceList.concat(audience))
    }
  } 

  let prevModal = () => {
    dispatch(setAdhocEmail(triggeredEmail));
    dispatch(switchModal(0));
  };
  

  let nextModal = () => {
    setNextButtonIsClicked(true)
    const validator = updateFormValidator()

    const keys = Object.keys(validator)

    for(let i = 0; i<keys.length; i++){
      const key = keys[i]
      if(validator[key].hasError){
        //This would stop the function if any of the form fields is empty (doesn't have a value), so the user won't be taken to the next modal
        return;
      }
    }

    dispatch(setAdhocEmail(triggeredEmail));
    dispatch(switchModal(4));
  };

  const updateFormValidator = () => {
    setLoading(true)
    const validator = Object.assign({}, formValidator)
    
    validator.selectedAudience.hasError = triggeredEmail.selectedAudiences.length === 0 ? true : false
    validator.messageName.hasError = triggeredEmail.messageName === '' ? true : false
    validator.campaignId.hasError = triggeredEmail.campaignId === '' || Number(triggeredEmail.campaignId) < 0 ? true : false
    validator.messageTemplate.hasError = triggeredEmail.messageTemplate === '' ? true : false
    validator.selectedFields.hasError = triggeredEmail.fields.selectedFields.length === 0 ? true : false
    validator.schedule.hasError = triggeredEmail.schedule === '' ? true : false
    setFormValidator(validator)

    setLoading(false)
    return validator
  }

  let addCheckedPropertyToListItem = (listItemData) => {
    const lists = [];

    listItemData.forEach((item) => {
      let obj = {
        id: item.id,
        label: item.friendlyName,
        checked: false,
      };
      lists.push(obj);
    });

    return lists;
  };

  let formIsValid = () => {
    for(let prop in formValidator){
      if(formValidator[prop].hasError){
        return false;
      }
    }

    return true
  };

  return {
    loading,
    formIsValid,
    prevModal,
    nextModal,
    audienceList,
    selectedAudienceList,
    setSelectedAudienceList,
    onCheckBoxChange,
    ignoredListItems,
    selectedListItems,
    addAllListItemsAsField,
    removeAllListItemsAsField,
    addCheckedListItemAsField,
    removeCheckedListItemAsField,
    handleChecked,
    triggeredEmail,
    handleTriggeredEmailChange,
    handleCampaignIdChange,
    messageTemplateList,
    formValidator
  };
};

export default useTriggerEmailModal;
