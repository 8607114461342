import React, { useEffect, useState, useRef, useTransition } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { getAudienceMembersSendStatus, getBroadcastDetails } from "../adhocCommunication.service";
import { setBroadcastCampaignId } from "../../../redux/adhoc-communication/adhoc-communication.reducer";
import { useAdhocCommunication } from "../useAdhocCommunication";
import BroadcastMetrics from "./broadcast-metrics/BroadcastMetrics";
import SmoothCollapse from "react-smooth-collapse";
import Button from "@material-ui/core/Button";
import BroadcastDetailsTable from "./broadcast-details-table/BroadcastDetailsTable";
import AutoRefreshToggle from "../common/auto-refresh-toggle/AutoRefreshToggle";
import ApiNew from "../../../common/api/apinew.service";
import styles from "./broadcastDetails.module.scss";
import { toast } from "react-toastify";

const friendlyColumnName = (key) => {
  return key
    .toString()
    .replace("_", " ") //remove _ with spaces
    .split(" ") //split the word so we can get sub-words
    .map((word) => word[0].toUpperCase() + word.substring(1, word.length)) //upper case first letter of each word.
    .join(" "); //rejoin words with spaces between
};

const BroadcastDetails = () => {
  const broadcastCampaignId = useSelector(
    ({ adhocCommunication: { broadcastCompaignId } }) => broadcastCompaignId
  );
  const [isPending, startTransition] = useTransition()

  const canApproveCampaignSends = useSelector(
    ({ user: { canApproveCampaignSends } }) => canApproveCampaignSends
  );
  const { approveCampaign, declineCampaign } = useAdhocCommunication();

  const [searchParams] = useSearchParams();

  const { api } = ApiNew();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  const [bcDetails, setBcDetails] = useState(null);
  const [audienceMembers, setAudienceMembers] = useState([]);
  const [newColumns, setNewColumns] = useState([]);

  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [metricsAutoRefresh, setMetricsAutoRefresh] = useState('')

  const interval = useRef()
  useEffect(() => {
    if(metricsAutoRefresh === 'On'){
      interval.current = setInterval(() => {
        fetchBroadcastMetrics();
      }, 20000)
    } else {
      clearInterval(interval.current)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsAutoRefresh])

  useEffect(() => {
    const autoRefreshValue = localStorage.getItem('broadcast-metrics-auto-refresh')
    setMetricsAutoRefresh(autoRefreshValue ?? 'On')
  }, [])

  useEffect(() => {
    if (api && isAuthenticated) {
      if (broadcastCampaignId) {
        fetchBroadcastMetrics();
        //fetchBroadcastMembers();
      } else {
        const campaignId = searchParams.get("campaignid");

        if (!campaignId) {
          navigate("/communicate");
          return;
        } else {
          dispatch(setBroadcastCampaignId(campaignId));
        }
      }
    }

    return () => {
      setBcDetails(null);
      setAudienceMembers([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, isAuthenticated, broadcastCampaignId]);

  const fetchBroadcastMetrics = async () => {
    setBcDetails(null)
    setIsLoadingMetrics(true);

    if(broadcastCampaignId) {

      const bcDetailsResponse = await getBroadcastDetails(
        broadcastCampaignId,
        api
      );
        if(bcDetailsResponse) {
          setBcDetails(bcDetailsResponse[0]);
        }
    }

    setIsLoadingMetrics(false);
  };

  const fetchBroadcastMembers = async () => {
    setAudienceMembers([])
    setIsLoadingMembers(true);

    const AMSendStatus = await getAudienceMembersSendStatus(
      broadcastCampaignId,
      api
    );

    if (!!AMSendStatus.length) {
      startTransition(() => {
        setAudienceMembers(handleMessageParse(AMSendStatus));
      })

      const columnKeys = Object.keys(AMSendStatus[0]);
      setNewColumns(
        columnKeys.map((key) => ({
          Header: friendlyColumnName(key),
          accessor: key,
        }))
      );
    }

    setIsLoadingMembers(false);
  };

  const handleMessageParse = (AMSendStatus) => {
    let convertMessageToCSV = (obj) => {
      let arr = [];

      for (let prop in obj) {
        if (typeof obj[prop] === "object") {
          arr = convertMessageToCSV(obj[prop]);
        } else if (obj[prop] === "") {
          arr.push(`${prop}:" "`);
        } else {
          arr.push(`${prop}:"${obj[prop]}"`);
        }
      }

      return arr;
    };

    return AMSendStatus.map((AM) => {
      try {
        return {
          ...AM,
          Message: convertMessageToCSV(JSON.parse(AM.Message)).join(", "),
        };
      } catch (err) {
        return {
          ...AM,
          Message: null,
        };
      }
    });
  };

  const approveBtnClick = async () => {
    await approveCampaign(broadcastCampaignId);

    window.location.reload();
  };

  const declineBtnClick = async () => {
    await declineCampaign(broadcastCampaignId);

    window.location.reload();
  };

  const handleTableAutoRefreshValueChange = (event) => {
    const value = event.target.value

    setMetricsAutoRefresh(value)
    localStorage.setItem('broadcast-metrics-auto-refresh', value)

    toast("Broadcast metrics auto refresh turned "+value.toLowerCase())
  }

  return (
    <div className={styles["container"]}>
      <div className={styles["back-btn-container"]}>
        <Button
          onClick={() => navigate("/communicate")}
          className={styles["back-to-communication-btn"]}
          disableRipple
          size="small"
        >
          <span
            style={{ fontSize: "1.2rem" }}
            className="material-symbols-outlined"
          >
            arrow_back
          </span>
          Back to communications
        </Button>
      </div>
      <div className={styles["header-container"]}>
        <h1 className={styles["header"]}>{bcDetails?.CampaignName}</h1>
        <div className={styles["buttons-container"]}>

          <AutoRefreshToggle handleChange={handleTableAutoRefreshValueChange} isOn={metricsAutoRefresh === 'On'} isOff={metricsAutoRefresh === 'Off'} />
          
          {bcDetails?.Status?.toLowerCase().includes("pending") &&
          canApproveCampaignSends ? (
            <>
              <Button
                className={styles["approve-btn"]}
                onClick={() => approveBtnClick()}
                size="small"
              >
                Approve
              </Button>
              <Button
                className={styles["decline-btn"]}
                onClick={() => declineBtnClick()}
                size="small"
              >
                Decline
              </Button>
            </>
          ) : bcDetails?.Status?.toLowerCase().includes("pending") ? (
            <>
              <p>Status: </p>
              <p className={styles["pending-text"]}>Pending Approval</p>
            </>
          ) : bcDetails?.Status?.toLowerCase().includes("scheduled") ? (
            <>
              <p>Status: </p>
              <p className={styles["sending-scheduled-text"]}>Scheduled</p>
            </>
          ) : bcDetails?.Status?.toLowerCase().includes("sending") ? (
            <>
              <p>Status: </p>
              <p className={styles["sending-scheduled-text"]}>Sending</p>
            </>
          ) : bcDetails?.Status?.toLowerCase().includes("sent") ? (
            <>
              <p>Status: </p>
              <p className={styles["sent-text"]}>Sent</p>
            </>
          ) : bcDetails?.Status?.toLowerCase().includes("declined") ? (
            <>
              <p>Status: </p>
              <p className={styles["declined-text"]}>Declined</p>
            </>
          ) : (
            <>
              <p>Status: </p>
              <p className={styles["pending-text"]}>{bcDetails?.Status}</p>
            </>
          )}
        </div>
      </div>
      {isLoadingMetrics ? (
        <p className={styles["loading-spinner"]}>
          <i className="fa fa-spinner fa-pulse"></i> Loading metrics...
        </p>
      ) : (
        ""
      )}

      {bcDetails ? <BroadcastMetrics bcDetails={bcDetails} /> : ""}

      {/* {isLoadingMembers || isPending ? (
        <p className={styles["loading-spinner"]}>
          <i className="fa fa-spinner fa-pulse"></i> Loading members. Please
          wait...
        </p>
      ) : (
        ""
      )}

      {!!audienceMembers.length ? (
        <>
          <div className={styles["expand-btn-container"]}>
            <Button
              onClick={() => setShowTable(!showTable)}
              className={styles["expand-btn"]}
              size="small"
              variant="contained"
            >
              {showTable ? "hide " : "show "} audience members{" "}
              {showTable ? " -" : " +"}
            </Button>
          </div>
          <SmoothCollapse expanded={showTable} heightTransition="0.8s">
            <div className={styles["table-wrapper"]}>
              <h1 className={styles["members-header"]}>Audience Members</h1>
              <BroadcastDetailsTable
                tableRows={audienceMembers}
                tableColumns={newColumns}
              />
            </div>
          </SmoothCollapse>
        </>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default BroadcastDetails;
