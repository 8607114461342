import React from 'react'
import styles from './labelValuePair.module.scss'

const LabelValuePair = ({ label, value, hasVerticalLine }) => {

  return (
    <div className={styles["container"]}>
      <div className={styles["label-n-value-container"]}>
        <p className={styles["label"]}>{label}</p>
        <p className={styles["value"]}>{value}</p>
      </div>

      {hasVerticalLine ? <hr className={styles["v-line"]} /> : ''}
    </div>
  )
}

export default LabelValuePair