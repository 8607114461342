import React, { useState, useEffect } from "react";
import styles from './select.module.scss'

const Select = ({ ruleData, fields, onValueChange }) => {
  const [availableValues, setAvailableValues] = useState();
  const [field, setField] = useState();

  useEffect(() => {
    var field = fields.find((x) => x.name === ruleData.field);

    if (!field) {
      return;
    }

    setField(field);

    if (field?.availableValues?.length === 0) {
      return;
    }

    setAvailableValues(field.availableValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <select
      className={styles.select}
      onChange={(e) => onValueChange(ruleData.id, e)}
      value={ruleData.value}
    >
      <option value="" disabled selected hidden>
        Select {field?.friendlyName}...
      </option>
      {availableValues
        ? availableValues.map((value) => {
            return <option value={value}>{value}</option>;
          })
        : ""}
    </select>
  );
};

export default Select;
