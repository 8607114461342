import React from 'react'
import uuid from 'react-uuid';
import styles from './labelValuePair.module.scss'

const LabelValuePair = ({ controlData }) => {
    const controls = [];

    controlData.forEach((record) => {
      const keys = Object.keys(record);
  
      keys.forEach((key) => {
        controls.push(
          <div key={uuid()} className={styles.container}>
            <span className={styles.key}>
              {key}
            </span>
            <span className={styles["record-key"]}>
              {record[key]}
            </span>
          </div>
        );
      });
    });
  
    return controls;
}

export default LabelValuePair