import { configureStore } from '@reduxjs/toolkit'
import TopNavSearchReducer from './top-nav-search/top-nav-search.reducer'
import ErrorPageReducer from './error-page/error-page.reducer'
import AudienceSelectorReducer from './audience-selector/audience-selector.reducer'
import AdhocCommunicationReducer from './adhoc-communication/adhoc-communication.reducer'
import IndividualPageReducer from './individual-page/individual-page.reducer'
import userReducer from './user/user.reducer'
import logger from 'redux-logger'

const store = configureStore({
    reducer: {
        topNavSearch: TopNavSearchReducer,
        errorPage: ErrorPageReducer,
        audienceSelector: AudienceSelectorReducer,
        adhocCommunication: AdhocCommunicationReducer,
        individualPage: IndividualPageReducer,
        user: userReducer
    },
    middleware: process.env.NODE_ENV === 'development'? [logger] : []
})


export default store