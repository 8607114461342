import React from "react";
import uuid from "react-uuid";
import HTMLReactParser from "html-react-parser";

const TagListItems = ({ controlData }) => {
  let controls = [];

  let bgColor;
  let color;
  const bgColorTagOpen = "<BG-COLOR>";
  const bgColorTagClose = "</BG-COLOR>";
  const colorTagOpen = "<COLOR>";
  const colorTagClose = "</COLOR>";

  const removeAndSetBGColorFromValue = (value) => {
    if (!value.search(bgColorTagOpen)) {
      return value;
    }

    const start = value.indexOf(bgColorTagOpen);
    const tagLength = bgColorTagOpen.length;
    const end = value.indexOf(bgColorTagClose);
    const bgColorValue = value.substring(start + tagLength, end);

    bgColor = bgColorValue;

    return value.replace(bgColorTagOpen + bgColorValue + bgColorTagClose, "");
  };

  const removeAndSetColorFromValue = (value) => {
    if (!value.search(colorTagOpen)) {
      return value;
    }

    const start = value.indexOf(colorTagOpen);
    const tagLength = colorTagOpen.length;
    const end = value.indexOf(colorTagClose);
    const colorValue = value.substring(start + tagLength, end);

    color = colorValue;

    return value.replace(colorTagOpen + colorValue + colorTagClose, "");
  };

  controlData.forEach((record) => {
    let listItem = [];

    const keys = Object.keys(record);

    keys.forEach((key) => {
      var value = record[key];

      value = removeAndSetBGColorFromValue(value);
      value = removeAndSetColorFromValue(value);

      listItem.push(
        <span
          key={uuid()}
          style={{
            marginRight: "0.2rem",
            borderRadius: "0%",
            marginTop: "0.1rem",
            backgroundColor: bgColor,
            color: color,
          }}
        >
          {HTMLReactParser(value)}
        </span>
      );
    });

    controls.push(
      <ul key={uuid()}>
        <li style={{listStyleType: 'none'}}>{listItem}</li>
      </ul>
    );
  });

  return controls;
};

export default TagListItems;
