import React from 'react'
import Checkbox from '@mui/material/Checkbox';

const CustomCheckbox = ({ hasLabel, label, ...otherProps }) => {
  return (
    <div style={{whiteSpace: 'nowrap'}}>
      <Checkbox
        {...otherProps}
        sx={{
          color: 'grey',
          padding: 0,
          '&.Mui-checked': {
            color: '#83C1B9',
          }
        }}
      />
      {hasLabel && <label style={{fontSize: '0.7rem', whiteSpace: 'nowrap'}}>{label}</label>}
    </div>
  )
}

export default CustomCheckbox