import React from "react";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import Button from '@material-ui/core/Button'
import ParentMobileNavItem from './parent-mobile-nav-item/ParentMobileNavItem';
import MobileNavItem from "./mobile-nav-item/MobileNavItem";
import img from "../../assets/horizon-logos/horizon-online-mobile.png";
import styles from "./sideNavMobile.module.scss";

const SideNavMobile = ({ showFullSideNav, menuItems }) => {
    const navigate = useNavigate()
    const isAuthenticated = useIsAuthenticated();

    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect().catch((e) => {
            console.error(e);
        });
    };
    return (
        <div className={styles["side-nav-mobile"]}>
            <div className={styles["logo-container"]}>
                <img
                    src={img}
                    alt="logo"
                    className={styles["logo"]}
                    onClick={() => navigate("/dashboard")}
                />
            </div>
            <hr />
            <div className={styles["menu-items-div"]}>
                {
                    isAuthenticated ? (
                        <div className={styles["menu-items-container"]}>
                            <div className={styles["top-items"]}>
                                {!!menuItems.length && menuItems.map((navItem) => (
                                    <ParentMobileNavItem key={navItem.name} navItem={navItem} />
                                ))}
                            </div>
                            <div className={styles["logout-container"]}>
                                <hr />
                                <MobileNavItem item={{name: 'Logout', icon: 'logout'}} action={handleLogout} />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )
                }
                <div className={styles["action-container"]}>
                    <Button onClick={showFullSideNav} className={styles["expand-btn"]} size='small' variant='contained'>
                        <span className="material-symbols-outlined">
                            keyboard_double_arrow_right
                        </span>
                    </Button>
                </div>
            </div>
          </div>
    );
};

export default SideNavMobile;
