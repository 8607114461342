import React from 'react'
import styles from './buttonItem.module.scss'

const ButtonItem = ({ isDisabled, icon, headerText, text, action }) => {
  return (
    <button onClick={action} className={styles["item-container"]} disabled={isDisabled}>
        <div className={styles["icon-container"]}>
            <span className="material-icons">
                {icon}
            </span>
        </div>
        <div className={styles["text-container"]}>
            <p className={styles["text-header"]}>{headerText}</p>
            <p className={styles["text"]}>
                {text}
            </p>
        </div>
    </button>
  )
}

export default ButtonItem