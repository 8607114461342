import React from "react";
import { useMsal } from "@azure/msal-react";
import styles from "./signOutButton.module.scss";

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
const SignOutButton = () => {
  const { instance, accounts } = useMsal();

  const handleLogout = (instance) => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  return (
    <div className={styles.container} onClick={() => handleLogout(instance)}>
      <i className={`material-icons-outlined ${styles.icon}`}>logout</i>
      <p className={styles.text}>Logout</p>
    </div>
  );
};

export default SignOutButton;
