import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAudienceToEdit } from "../../../redux/audience-selector/audience-selector.reducer";
import AudienceBuilder from "../../../components/audience-selector/audience-builder/AudienceBuilder";
import styles from "./audienceBuilderPage.module.scss";

const AudienceBuilderPage = () => {
  const dispatch = useDispatch()
  const audienceToEdit = useSelector(( { audienceSelector : { audienceToEdit } }) => audienceToEdit)


  useEffect(() => {

    return () => {
      dispatch(setAudienceToEdit(null))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles["audience-builder"]}>
      <AudienceBuilder selectedRow={audienceToEdit} />
    </div>
  );
};

export default AudienceBuilderPage;
