import React from "react";
import styles from "./dashboard.module.scss";

import { useMsal } from "@azure/msal-react";

const DashBoard = () => {
  const { accounts } = useMsal();

  const name = accounts[0] && accounts[0].name;

  return (
    <div className={styles.dashboard}>
      <h1
        data-testid="dashboard-page-header"
        className={styles.header}
      >{`Welcome, ${name}!`}</h1>
      <p>
        The dashboard is currently under development. Please check back at a
        later date...
      </p>
    </div>
  );
};

export default DashBoard;
