import React from "react";
import Modal from "@material-ui/core/Modal";
import styles from "./customModal.module.scss";

const CustomModal = ({
  children,
  isOpen,
  closeModal,
  modalWidth,
  disableBackDropClick = false,
  disableScroll = false,
}) => {
  let handleClose = (e, reason) => {
    if (disableBackDropClick && reason === "backdropClick") {
      return;
    }

    closeModal();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        backdropFilter: "blur(3px)",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: modalWidth,
          overflowY: disableScroll ? "hidden" : "auto",
        }}
        className={styles.modal}
      >
        <div className={styles["close-button"]}>
          <button
            data-testid="custom-modal-times-button"
            onClick={() => closeModal()}
          >
            &times;
          </button>
        </div>
        {isOpen ? children : ""}
      </div>
    </Modal>
  );
};

export default CustomModal;
