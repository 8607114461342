import React from "react";
import CommunicationTableRowStatus from "./communication-table-row-status/CommunicationTableRowStatus";
import styles from "./communicationTableRow.module.scss";

const CommunicationTableRow = ({ row, rowClickAction, approveBtnClickAction, declineBtnClickAction }) => {
  return (
    <tr
      className={styles.tr}
      {...row.getRowProps()}
      data-testid={`audiences-table-row-${row.original.id}`}
    >
      {row.cells.map((cell) =>
        cell.column.Header.toString().toLowerCase() === "status" ? (
          <CommunicationTableRowStatus
            key={row.original.id + "_status"}
            row={row}
            rowClickAction={rowClickAction}
            approveBtnClickAction={approveBtnClickAction}
            declineBtnClickAction={declineBtnClickAction}
          ></CommunicationTableRowStatus>
        ) : (
          <td
            onClick={() => rowClickAction(row)}
            className={styles.td}
            {...cell.getCellProps()}
          >
            {cell.render("Cell")}
          </td>
        )
      )}
      {/* {isAdmin &&
      row.original.Status.toString().toLowerCase() === "Pending Approval" ? (
        <td className={styles.td}>
          <Button
            className={styles["approve-btn"]}
            onClick={() => console.log(row.original)}
            size="small"
          >
            Approve
          </Button>
        </td>
      ) : row.original.Status.toString().toLowerCase() ===
        "Pending Approval" ? (
        <td className={styles.td}>
          <p className={styles["pending-text"]}>Pending Approval</p>
        </td>
      ) : (
        <td className={styles.td}>
          <p className={styles["sent-text"]}>Sent</p>
        </td>
      )} */}
    </tr>
  );
};

export default CommunicationTableRow;
