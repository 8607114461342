import React from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
// import Select from '@material-ui/core/Select'
import styles from "./fieldSelector.module.scss";
import "./fieldselector.css";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const FieldSelector = ({
  placeholder,
  fieldList,
  selectedField,
  appendSelectedField,
  onFieldChange,
}) => {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  return (
    <div className={styles["select-item"]}>
      <Button
        title="append"
        id="add-button"
        onClick={appendSelectedField}
        disabled={!selectedField}
        size="small"
      >
        <span className="material-icons-outlined">arrow_back_ios</span>
      </Button>
      <Autocomplete
        disablePortal
        filterOptions={filterOptions}
        options={fieldList}
        sx={{ width: "100%", background: "white" }}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        onChange={onFieldChange}
      />
    </div>
  );
};

export default FieldSelector;
