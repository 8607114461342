import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./errorPage.module.scss";

const ErrorPage = () => {
  const navigate = useNavigate();
  const pageErrorOccured = useSelector(
    ({ errorPage: { pageErrorOccured } }) => pageErrorOccured
  );
  const errorCode = useSelector(({ errorPage: { errorCode } }) => errorCode);
  const errorMessage = useSelector(
    ({ errorPage: { errorMessage } }) => errorMessage
  );

  useEffect(() => {
    if (!pageErrorOccured) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getErrorMessage = () => {
    switch (errorCode) {
      case 500:
        return errorMessage?.length > 0
          ? errorMessage
          : "Internal Server Error";
      case 401:
      default:
        return errorMessage?.length > 0 ? errorMessage : "Unauthorized";
    }
  };

  return (
    <>
      {pageErrorOccured ? (
        <div className={styles["page-not-found-container"]}>
          <h1>{errorCode}</h1>
          <p>{getErrorMessage()}</p>
        </div>
      ) : (
        "An error has occured."
      )}
    </>
  );
};

export default ErrorPage;
