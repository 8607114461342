
export const getQueryRecords = async (audienceToViewId, skip, limit, searchValue, api) => {
  try {
    const response = await api.get(
      `AudienceSelector/GetQueryRecordsV2/${audienceToViewId}?skip=${skip}&limit=${limit}&searchValue=${searchValue}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getQueryRecordsCount = async (audienceToViewId, searchValue, api) => {
  try {
    const response = await api.get(
      `AudienceSelector/GetQueryRecordsCountV2/${audienceToViewId}?searchValue=${searchValue}`
    );

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
