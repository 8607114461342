import { toast } from "react-toastify";

export const getFields = async (api) => {
  try {
    const response = await api.get(`AudienceSelector/GetQuerySelectionFields`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getFieldsV2 = async (api) => {
  try {
    const response = await api.get(
      `AudienceSelector/GetQuerySelectionFieldsV3`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getCount = async (query, api) => {
  try {
    const response = await api.post(`AudienceSelector/GetCount`, query);

    return response.data ?? 0;
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const getCountV2 = async (json, api) => {
  try {
    const response = await api.post(`AudienceSelector/GetCountV3`, json);
    return response.data ?? 0;
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const submitQuery = async (body, api) => {
  try {
    const response = await api.post(`AudienceSelector/SubmitQuery`, body);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const submitQueryV2 = async (body, api) => {
  try {
    await api.post(`AudienceSelector/SubmitQuery_V2`, body);
  } catch (err) {
    console.log(err);
  }
};

export const submitQueryV3 = async (name, body, api) => {
  // try {
  //   const response = await api.post(`AudienceSelector/SubmitQuery_V3`, body);
  //   return response.data;
  // } catch (err) {
  //   console.log(err);
  // }

  let response = api
    .post(`AudienceSelector/SubmitQuery_V4`, body)
    .then((res) => res)
    .catch((err) => console.log(err));

  return toast.promise(response, {
    success: "Success",
    pending: `Creating audience '${name}' and generating members`,
    error: `Failed to create auduience '${name}'`,
  });
};

export const updateSelectionV2 = async (id, name, body, api) => {
  // try {
  //   await api.put(`AudienceSelector/UpdateSelection_V2/${id}`, body);
  // } catch (err) {
  //   console.log(err);
  // }

  let response = api
    .put(`AudienceSelector/UpdateSelection_V2/${id}`, body)
    .then((res) => res)
    .catch((err) => console.log(err));

  return toast.promise(response, {
    success: "Success",
    pending: `Updating audience '${name}' and regenerating members`,
    error: `Failed to update auduience '${name}'`,
  });
};

export const updateSelection = async (id, body, api) => {
  try {
    const response = await api.put(
      `AudienceSelector/UpdateSelection/${id}`,
      body
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getSelections = async (api) => {
  try {
    const response = await api.get(`AudienceSelector/GetSelections`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getSelectionById = async (id, api) => {
  try {
    const response = await api.get(`AudienceSelector/GetSelection/${id}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteSelection = async (name, id, api) => {
  // try {
  //   await api.delete(`AudienceSelector/DeleteSelection/${id}`);
  // } catch (err) {
  //   console.log(err);
  // }

  let response = api
    .delete(`AudienceSelector/DeleteSelection/${id}`)
    .then((res) => res)
    .catch((err) => console.log(err));

  return toast.promise(response, {
    success: "Success",
    pending: `Deleting audience '${name}'`,
    error: `Failed to delete auduience '${name}'`,
  });
};

export const getXCMCalender = async (api) => {
  try {
    const response = await api.get("/AudienceSelector/GetXCMCalender");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getDistinctFieldValue = async (fieldObj, api) => {
  try {
    const response = await api.post(
      "/AudienceSelector/GetDistinctFieldValues",
      fieldObj
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getDistinctFieldValueV2 = async (fieldObj, api) => {
  try{
    const response = await api.post('/AudienceSelector/GetDistinctFieldValues_v2', fieldObj)
    return response.data
  } catch (error) {
    console.log(error)
    return []
  }
}

export const checkIfAudienceNameIsInUse = async (api, audienceName, audienceId = '') => {
  try {
    const response = await api.get(`/AudienceSelector/CheckIfAudienceNameIsInUse/${audienceName}?audienceID=${audienceId}`)
    return response.data
  } catch (err) {
    console.log(err)
  }
}
