


export const operatorsData2 = {
  dateOperators: [
    "on",
    "on or before",
    "on or after",
    "between",
    "is in",
    "is not in",
    "is not empty",
    "is empty"
  ],
  numberOperators: [
    "equal to",
    "not equal to",
    "greater than",
    "greater than or equal to",
    "less than",
    "less than or equal to",
    "contains",
    "does not contain",
    "ends with",
    "between",
    "is not empty",
    "is empty"
  ],
  textOperators: [
    "equal to",
    "not equal to",
    "greater than",
    "greater than or equal to",
    "less than",
    "less than or equal to",
    "contains",
    "does not contain",
    "ends with",
    "between",
    "is in",
    "is not in",
    "is not empty",
    "is empty"
  ]
}