import React, { useState } from "react";
import ValueSearchModal from "./value-search-modal/ValueSearchModal";
import styles from "./valueSearchContainer.module.scss";

const ValueSearchContainer = ({
  ruleData,
  fieldGroup,
  onValueChange,
  setRuleDataValue,
}) => {
  const [valueSearchModalIsOpen, setValueSearchModalIsOpen] = useState(false);

  const closeValueSearchModal = () => {
    setValueSearchModalIsOpen(false);
  };

  return (
    <>
      <input
        className={styles["input"]}
        value={ruleData?.value ?? ""}
        onChange={(event) => onValueChange(ruleData.id, event)}
      ></input>
      <span
        className={`${styles["field-search-btn"]} ${"material-icons-outlined"}`}
        onClick={() => setValueSearchModalIsOpen(true)}
      >
        search
      </span>
      <ValueSearchModal
        isOpen={valueSearchModalIsOpen}
        closeModal={closeValueSearchModal}
        ruleID={ruleData.id}
        fieldGroup={fieldGroup}
        ruleData={ruleData}
        setRuleDataValue={setRuleDataValue}
      ></ValueSearchModal>
    </>
  );
};

export default ValueSearchContainer;
