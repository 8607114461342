import React from "react";
import styles from "./rowButton.module.scss";

const RowButton = ({ materialIcon, ...otherProps }) => {
  return (
    <button {...otherProps} className={styles.rowButton}>
      <span className="material-icons-outlined">{materialIcon}</span>
    </button>
  );
};

export default RowButton;
