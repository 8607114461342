import React from "react";
import Individual from "../../../components/individual/Individual";
import styles from './customers.module.scss';

const Customers = () => {
  return (
    <div className={styles["customers-page"]}>
      <Individual />
    </div>
  );
};

export default Customers;
