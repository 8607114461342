import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Controls from '../Controls/Controls';
import styles from './individualDetailsTabs.module.scss'

const IndividualDetailsTabs = ({ selectedIndividual, pageControls }) => {
  return (
        <Tabs className={styles["tabs-container"]}>
            <TabList className={styles["tab-list"]}>
                <Tab selectedClassName={styles["selectedTab"]} className={styles["tab"]}>
                    <i className='fa fa-user'></i>
                    &nbsp;Individual
                </Tab>
                <Tab selectedClassName={styles["selectedTab"]} className={styles["tab"]}>
                    <i className='fa fa-bag-shopping'></i>
                    &nbsp;Orders
                </Tab>
                <Tab selectedClassName={styles["selectedTab"]} className={styles["tab"]}>
                    <i className='fa fa-dna'></i>
                    &nbsp;Profiles
                </Tab>
            </TabList>
            <TabPanel>
                <div className={`${styles["tab-panel-container"]} ${styles["individal-tap-panel"]}`}>
                    <div>
                        <Controls
                        controlID={"div_Individual_Column1"}
                        pageControls={pageControls}
                        individual={selectedIndividual}
                        />
                    </div>
                    <div>
                        <Controls
                        controlID={"div_Individual_Column2"}
                        pageControls={pageControls}
                        individual={selectedIndividual}
                        />
                    </div>
                    <div>
                        <Controls
                        controlID={"div_Individual_Column3"}
                        pageControls={pageControls}
                        individual={selectedIndividual}
                        />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <div className={styles["tab-panel-container"]}>
                    <div>
                        <Controls
                        controlID={"div_Orders_Column4"}
                        pageControls={pageControls}
                        individual={selectedIndividual}
                        />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <div className={styles["tab-panel-container"]}>
                    <div>
                        <div>
                            <Controls
                                controlID={"div_Profiles_Profiles"}
                                pageControls={pageControls}
                                individual={selectedIndividual}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Controls
                        controlID={"div_Profiles_Column1"}
                        pageControls={pageControls}
                        individual={selectedIndividual}
                        />
                    </div>
                    <div>
                        <Controls
                        controlID={"div_Profiles_Column2"}
                        pageControls={pageControls}
                        individual={selectedIndividual}
                        />
                    </div>
                </div>
            </TabPanel>
        </Tabs>
  )
}

export default IndividualDetailsTabs