import React from 'react'
import AdhocCommunication from '../../../components/adhoc-communication/AdhocCommunication'
import styles from './communicate.module.scss'

const Communicate = () => {
  return (
    <div className={styles['communicate-container']}>
        <h1 data-testid='communicate-page-header' className={styles.header}>Adhoc Communication</h1>

        <div>
          <AdhocCommunication />
        </div>
    </div>
  )
}

export default Communicate