import React from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import Button from "@material-ui/core/Button";
import SideNavListItem from "./sidenav-list-item/SideNavListItem";
import img from "../../assets/horizon-logos/horizon-online-250.png";
import SignOutButton from "./sign-out-button/SignOutButton";
import styles from "./sideNav.module.scss";

const SideNav = ({ setShowMobileSideNav, menuItems }) => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className={styles["side-nav"]}>
      <div className={styles["side-nav-container"]}>
        <div className={styles["logo-container"]}>
          <img
            src={img}
            alt="logo"
            className={styles["logo"]}
            onClick={() => navigate("/dashboard")}
          />
          {/* <hr /> */}
        </div>
        <div className={styles["menu-items-container"]}>
          {isAuthenticated ? (
            <div>
              {!!menuItems.length &&
                menuItems.map((nav) => (
                  <SideNavListItem
                    key={nav.name}
                    text={nav.name}
                    icon={nav.icon}
                    childrenNav={nav.children}
                  />
                ))}
              {/* <SideNavListItem
                key="analytics"
                text="Analytics"
                icon="analytics"
                childrenNav={[
                  {
                    key: "superset",
                    name: "Superset",
                    path: "/superset",
                    icon: "all_inclusive",
                  },
                ]}
              /> */}
            </div>
          ) : (
            ""
          )}
          <div className={styles["action-container"]}>
            <Button
              onClick={setShowMobileSideNav}
              className={styles["collapse-btn"]}
              size="small"
              variant="contained"
            >
              <span className="material-symbols-outlined">
                keyboard_double_arrow_left
              </span>
            </Button>
          </div>
          {isAuthenticated ? (
            <div className={styles["logout-container"]}>
              {/* <hr /> */}
              <SignOutButton />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default SideNav;
