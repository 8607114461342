import React, { useState, useEffect } from "react";
import { getXCMCalender } from "../../../audienceSelector.service";
import { setFriendlyName } from "../helper/setFriendlyName";
import operatorsData from "../constants/operators2.json";
import { operatorsData2 } from "../constants/operators";
import SearchableMultiSelect from "../search-multi-select/SearchableMultiSelect";
import Select from "../select/Select";
import CustomCheckbox from "../../../../../common/components/custom-checkbox/CustomCheckbox";
import ApiNew from "../../../../../common/api/apinew.service";
import CalenderSelect from "../calender-select/CalenderSelect";
import FieldSearchModal from "../field-search-modal/FieldSearchModal";
import ValueSearchContainer from "../value-search-container/ValueSearchContainer";
import styles from "./rule.module.scss";

const Rule = ({
  fieldGroups,
  ruleData,
  onFieldGroupChange,
  onFieldChange,
  onHasRelativeDateChange,
  onSelectedFieldChange,
  onOperatorChange,
  onValueChange,
  setRuleDataValue,
  onBetweenValueChange,
  onSearchableMultiSelectValueChange,
  deleteRuleClick,
}) => {
  const { api } = ApiNew();
  const [leftBetweenValue, setLeftBetweenValue] = useState("");
  const [rightBetweenValue, setRightBetweenValue] = useState("");
  const [relativeDateList, setRelativeDateList] = useState([]);

  const [fieldRecord, setFieldRecord] = useState();
  const [fieldGroup, setFieldGroup] = useState();
  const [operators, setOperators] = useState(operatorsData);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (api) {
        const dateList = await getXCMCalender(api);
        setRelativeDateList(setFriendlyName(dateList));
      }
    })();
    setLoading(false);
  }, [api]);

  useEffect(() => {
    setLoading(true);

    let fieldName = ruleData.field;
    let fieldGroupSelected = ruleData.fieldGroup;

    let fieldsByGroupName = fieldGroups.find(
      (fieldGroup) => fieldGroup.name === fieldGroupSelected
    );

    let field = fieldGroups.find((x) => x.name === fieldName);
    
    let operators = []
    if(!!field?.operators?.length){
      operators = field.operators
    } else if (field.inputType === 'date' || field.inputType === 'datetime-local') {
      operators = operatorsData2.dateOperators
    } else if (field.inputType === 'number') {
      operators = operatorsData2.numberOperators
    } else {
      operators = operatorsData2.textOperators
    }

    setFieldRecord(field);
    setFieldGroup(field);
    setOperators([...operators]);

    if (ruleData.operator === "between" && !!ruleData.value) {
      const values = ruleData.value.split(",");
      setLeftBetweenValue(values[0]);
      setRightBetweenValue(values[1]);
    }

    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFieldChange]);

  const leftBetweenOnChange = (id, e) => {
    const rightValue = rightBetweenValue;
    let leftValue = leftBetweenValue;

    leftValue = e.target.value;

    setLeftBetweenValue(leftValue);

    const betweenValues = `${leftValue},${rightValue}`;

    onBetweenValueChange(id, betweenValues);
  };

  const rightBetweenOnChange = (id, e) => {
    const leftValue = leftBetweenValue;
    let rightValue = rightBetweenValue;

    rightValue = e.target.value;

    setRightBetweenValue(rightValue);

    const betweenValues = `${leftValue},${rightValue}`;

    onBetweenValueChange(id, betweenValues);
  };

  const showRelativeDateControls = () => {
    return (
      (fieldRecord?.inputType === "date" ||
        fieldRecord?.inputType === "datetime-local") &&
      (ruleData.operator.toLowerCase() === "is in" ||
        ruleData.operator.toLowerCase() === "is not in")
    );
  };

  const isTextInput = () => {
    return fieldRecord?.inputType === "text" || !fieldRecord?.inputType;
  };

  const [fieldSeachModalIsOpen, setFieldSearchModalIsOpen] = useState(false);

  const closeFieldSearchModal = () => {
    setFieldSearchModalIsOpen(false);
  };

  return (
    <div className={styles["rule-container"]}>
      <div className={styles["rule-wrapper"]}>
        {loading ? (
          "Loading rule data..."
        ) : (
          <>
            <div className={styles["field-group-text"]}>
              <p>
                Field group: <em>{fieldRecord?.fieldGroup}</em>
              </p>
            </div>
            <input
              className={styles["input"]}
              value={fieldRecord?.label ?? ""}
              readOnly
            ></input>
            <span
              className={`${
                styles["field-search-btn"]
              } ${"material-icons-outlined"}`}
              onClick={() => setFieldSearchModalIsOpen(true)}
            >
              search
            </span>
            <FieldSearchModal
              isOpen={fieldSeachModalIsOpen}
              closeModal={closeFieldSearchModal}
              ruleID={ruleData.id}
              fieldGroups={fieldGroups}
              selectedItem={fieldGroup}
              setFieldGroup={setFieldGroup}
              onSelectedFieldChange={onSelectedFieldChange}
            ></FieldSearchModal>
            <select
              className={styles["operator-select"]}
              onChange={(e) => onOperatorChange(ruleData.id, e)}
              value={
                operators.includes(ruleData.operator)
                  ? ruleData.operator
                  : operators[0]
              }
            >
              {operators.map((operator) => {
                return (
                  <option key={operator} value={operator}>
                    {operator}
                  </option>
                );
              })}
            </select>
            {fieldRecord?.controlType?.toLowerCase() ===
            "searchablemultiselect" ? (
              <>
                <div>
                  <SearchableMultiSelect
                    field={fieldRecord}
                    id={ruleData.id}
                    selectedValues={ruleData.value}
                    onSearchableMultiSelectValueChange={
                      onSearchableMultiSelectValueChange
                    }
                  />
                </div>
                <div className={styles["rule-value-container"]}>
                  {ruleData?.value?.length ? (
                    <p>
                      Selected: <em>{ruleData?.value}</em>
                    </p>
                  ) : (
                    <p>No {fieldGroup?.label}(s) selected.</p>
                  )}
                </div>
              </>
            ) : fieldRecord?.controlType?.toLowerCase() === "select" &&
              ruleData.operator === "between" ? (
              <div>
                <Select
                  ruleData={ruleData}
                  fieldGroups={fieldGroups}
                  onValueChange={onValueChange}
                />
                <Select
                  ruleData={ruleData}
                  fieldGroups={fieldGroups}
                  onValueChange={onValueChange}
                />
              </div>
            ) : fieldRecord?.controlType?.toLowerCase() === "select" ? (
              <div>
                <Select
                  ruleData={ruleData}
                  fieldGroups={fieldGroups}
                  onValueChange={onValueChange}
                />
              </div>
            ) : ruleData.operator === "between" ? (
              <>
                <input
                  dir="auto"
                  className={styles["input"]}
                  type={fieldRecord?.inputType}
                  onChange={(e) => leftBetweenOnChange(ruleData.id, e)}
                  value={leftBetweenValue ?? ""}
                />
                <input
                  dir="auto"
                  className={styles["input"]}
                  type={fieldRecord?.inputType}
                  onChange={(e) => rightBetweenOnChange(ruleData.id, e)}
                  value={rightBetweenValue ?? ""}
                />
              </>
            ) : (
              <>
                {showRelativeDateControls() && ruleData.hasRelativeDate ? (
                  <CalenderSelect
                    onValueChange={onValueChange}
                    ruleData={ruleData}
                    relativeDateList={relativeDateList}
                  />
                ) : (
                  <>
                    {isTextInput() ? (
                      <>
                        <ValueSearchContainer
                          ruleData={ruleData}
                          fieldGroup={fieldGroup}
                          setRuleDataValue={setRuleDataValue}
                          onValueChange={onValueChange}
                        ></ValueSearchContainer>
                      </>
                    ) : (
                      <input
                        dir="auto"
                        className={styles["input"]}
                        type={fieldRecord?.inputType ?? "date"}
                        onChange={(e) => onValueChange(ruleData.id, e)}
                        value={ruleData.value ?? ""}
                      />
                    )}
                  </>
                )}
                {showRelativeDateControls() ? (
                  <CustomCheckbox
                    hasLabel
                    label="Use relative date"
                    checked={ruleData.hasRelativeDate}
                    onChange={(e) => onHasRelativeDateChange(ruleData.id, e)}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </div>
      <button
        className={styles["remove-rule-button"]}
        onClick={() => deleteRuleClick(ruleData.id)}
      >
        <span className="material-icons-outlined">close</span>
      </button>
    </div>
  );
};

export default Rule;
