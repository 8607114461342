import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Master from "./pages/master/Master";
import ErrorPage from "./pages/master/error-page/ErrorPage";
import useAuth from "./pages/master/route-guard/useAuth";
import DashBoard from "./pages/master/dashboard/DashBoard";
import BroadcastDetailsPage from "./pages/master/broadcast-details/BroadcastDetailsPage";
import Superset from "./pages/master/superset/Superset";
import AudienceBuilder from "./pages/master/audience-builder-page/AudienceBuilderPage";
import Communicate from "./pages/master/communicate/Communicate";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  const { displayContent, routes } = useAuth();

  return (
    <>
      <div className="App">
        {displayContent && (
          <Routes>
            <Route path="/" element={<Master />}>
              <Route path="/dashboard" element={<DashBoard />} />
              <Route
                path="/broadcast-details"
                element={<BroadcastDetailsPage />}
              />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="" element={<Navigate to="/dashboard" />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
              {!!routes.length &&
                routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                ))}
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </div>
      <ToastContainer position="bottom-right" hideProgressBar={true} />
    </>
  );
}

export default App;
