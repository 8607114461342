import React, { useRef } from "react";
import styles from "./fallbackFormPopover.module.scss";

const FallbackFormPopover = ({
  selectedFieldList,
  handleFallbackFormInputChange,
}) => {
  const ref = useRef();

  return (
    <div ref={ref} className={styles["popover-menu"]}>
      <div className={styles["arrow"]}></div>
      <div className={styles["popover-body"]}>
        <div className={styles["header-container"]}>
          <h1 className={styles["header"]}>Configure fall back value</h1>
        </div>
        <div className={styles["form-container"]}>
          {selectedFieldList.map((field) => (
            <div key={field.id} className={styles["form-control"]}>
              <label htmlFor={field.label}>{field.label}</label>
              <input
                id={field.label}
                value={field.fallBackValue}
                onChange={(e) => handleFallbackFormInputChange(e, field)}
                type="text"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FallbackFormPopover;
