import React from 'react'
import FormFieldErrorText from '../form-field-error-text/FormFieldErrorText'
import FieldsListItem from './fields-list-item/FieldsListItem'
import styles from './listItemContainer.module.scss'

const ListItemContainer = ({ listItems, handleChecked, hasError, errorText }) => {
  return (
    <div className={styles['container']}>
      <div style={{ border: hasError ? '1px solid red' : '' }} className={styles["list-item-container"]}>
        {!!listItems.length && [...listItems].sort((a,b) => a.id - b.id).map(item => <FieldsListItem handleChecked={(id) => handleChecked(id)} key={item.id} item={item} />)}
      </div>
      <FormFieldErrorText text={errorText} showText={hasError} />
    </div>
  )
}

export default ListItemContainer