import React, { useState, useEffect } from "react";
import { getControlData } from "./../../../../individual.service";
import ProfilesDetailsTab from "./ProfileDetailsTab/ProfilesDetailsTab";
import ProfilesAddressTab from "./ProfilesAddressTab/ProfilesAddressTab";
import uuid from "react-uuid";
import styles from "./profilesCard.module.scss";
import ApiNew from "../../../../../../common/api/apinew.service";

const PERSONAL_DETAILS = "HorizonOnline.GetProfiles_PersonalDetails_v2";
const ADDRESS_DETAILS = "HorizonOnline.GetProfiles_AddressDetails_v2";

const ProfileCard = ({ profile }) => {
  const { api } = ApiNew();
  const [isOpen, setIsOpen] = useState(false);
  const [personalDetails, setPersonalDetails] = useState();
  const [addressDetails, setAddressDetails] = useState();

  const personID = profile["Person ID"];

  useEffect(() => {
    (async () => {
      if(api){
        let person = {
          person_id: personID,
        };
  
        const personalDetailsRes = await getControlData(
          PERSONAL_DETAILS,
          person,
          api
        );
  
        setPersonalDetails(personalDetailsRes.data);
  
        const addressDetailsRes = await getControlData(
          ADDRESS_DETAILS,
          person,
          api
        );
  
        setAddressDetails(addressDetailsRes.data);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personID, api]);

  return (
    <div className={styles.container}>
      {isOpen ? (
        <>
          <div
            className={`${styles["expand-less-icon"]} material-icons-outlined`}
            onClick={() => setIsOpen(!isOpen)}
          >
            expand_less
          </div>
          <div className={styles["tab-container"]}>
            <div className={styles["tab"]}>
              <ProfilesDetailsTab personalDetails={personalDetails} />
            </div>
            <div className={styles["tab"]}>
              <ProfilesAddressTab addressDetails={addressDetails} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`${styles["expand-more-icon"]} material-icons-outlined`}
            onClick={() => setIsOpen(!isOpen)}
          >
            expand_more
          </div>
          <div className={styles["overview-container"]}>
            {Object.keys(profile).map((key) => {
              return (
                <div key={uuid()} className={styles.overview}>
                  {key}: {profile[key]}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileCard;
