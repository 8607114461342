import React from "react";
import styles from "./selectedFieldItem.module.scss";

const SelectedFieldItem = ({ fieldItem, removeSelectedField }) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["item"]}>
        {fieldItem.label}
        <span onClick={() => removeSelectedField(fieldItem)}>&times;</span>
      </div>
    </div>
  );
};

export default SelectedFieldItem;
