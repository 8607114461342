import React from "react";
import AudienceSelections from "../../../components/audience-selector/audience-selections/AudienceSelections";
import styles from "./connections.module.scss";

const Connections = () => {
  return (
    <div className={styles["connections-container"]}>
      <div className={styles["header-container"]}>
        <div data-testid='connections-page-header' className={styles["header-text"]}>Audiences</div>
        <div className={`${styles["header-image"]} material-icons-outlined`}>
          groups
        </div>
      </div>
      <div>
        <AudienceSelections />
      </div>
    </div>
  );
};

export default Connections;
