import React from "react";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import { switchModal } from "../../../redux/adhoc-communication/adhoc-communication.reducer";
import { broadcastEmail } from "../adhocCommunication.service";
import styles from "./emailConfirmationModal.module.scss";

const EmailConfirmationModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const selectedAudience = useSelector(
    ({ adhocCommunication: { selectedAudience } }) => selectedAudience
  );
  const adhocEmail = useSelector(
    ({ adhocCommunication: { adhocEmail } }) => adhocEmail
  );

  let send = async () => {
    let obj = {
      audiences: adhocEmail.selectedAudiences,
      volume: selectedAudience.count,
      messageName: adhocEmail.messageName,
      campaignId: adhocEmail.campaignId,
      messageTemplate: adhocEmail.messageTemplate,
      fields: adhocEmail.fields.selectedFields
        .map((item) => item.label)
        .join(" / "),
      schedule: new Date(adhocEmail.schedule),
    };

    await broadcastEmail(obj);

    closeModal();
  };

  return (
    <div className={styles["modal-container"]}>
      <h1 className={styles.header}>Triggered Email Confirmation</h1>
      <div className={styles.main}>
        <dl className={styles.dl}>
          <div className={styles.wrapper}>
            <dt className={styles.dt}>Audience</dt>
            <dd className={styles.dd}>
              {adhocEmail.selectedAudiences.map((aud) => aud.name).join(", ")}
            </dd>
          </div>

          <div className={styles.wrapper}>
            <dt className={styles.dt}>Volume</dt>
            <dd className={styles.dd}>
              {selectedAudience?.count} unique email addresses
            </dd>
          </div>

          <div className={styles.wrapper}>
            <dt className={styles.dt}>Message Name</dt>
            <dd className={styles.dd}>{adhocEmail?.messageName}</dd>
          </div>

          <div className={styles.wrapper}>
            <dt className={styles.dt}>Campaign ID</dt>
            <dd className={styles.dd}>{adhocEmail?.campaignId}</dd>
          </div>

          <div className={styles.wrapper}>
            <dt className={styles.dt}>Message Template</dt>
            <dd className={styles.dd}>{adhocEmail?.messageTemplate}</dd>
          </div>

          <div className={styles.wrapper}>
            <dt className={styles.dt}>Fields To Include</dt>
            <dd className={styles.dd}>
              {adhocEmail?.fields.selectedFields
                .map((item) => item.label)
                .join(" / ")}
            </dd>
          </div>

          <div className={styles.wrapper}>
            <dt className={styles.dt}>Schedule</dt>
            <dd className={styles.dd}>
              {new Date(adhocEmail?.schedule).toLocaleString()}
            </dd>
          </div>
        </dl>
      </div>

      <div className={styles.actions}>
        <Button
          onClick={() => dispatch(switchModal(3))}
          size="small"
          className={styles.cancel}
        >
          Back
        </Button>
        <Button size="small" onClick={send} className={styles.next}>
          Send
        </Button>
      </div>
    </div>
  );
};

export default EmailConfirmationModal;
