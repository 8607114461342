import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import styles from "./deleteSelection.module.scss";
import ApiNew from "../../../../common/api/apinew.service";
import CircularProgress from "@mui/material/CircularProgress";
import { deleteSelection } from "../../audienceSelector.service";

const DeleteSelection = ({ selectedRow, closeModal, refreshTable }) => {
  const { api } = ApiNew();
  const [loading, setLoading] = useState(false);

  let deleteRow = async () => {
    setLoading(true);

    deleteSelection(selectedRow.name, selectedRow.id, api);

    setTimeout(() => {
      setLoading(false);
      refreshTable("Delete");
    }, 1000);
  };

  return (
    <div
      data-testid="delete-audience-modal-container"
      className={styles["delete-container"]}
    >
      <div className={styles["header-container"]}>
        <h1 className={styles.header}>Delete Audience</h1>
      </div>
      <div className={styles.text}>
        <p data-testid="delete-message">
          Are you sure you want to delete this audience:{" "}
          <strong>{selectedRow.name}</strong>
        </p>
      </div>
      <div className={styles.actions}>
        <Button onClick={closeModal} size="small" className={styles.no}>
          No
        </Button>
        <Button
          size="small"
          onClick={deleteRow}
          className={styles.confirm}
          disabled={loading}
        >
          Yes&nbsp;
          {loading ? (
            <CircularProgress style={{ color: "#ffffff" }} size="1rem" />
          ) : (
            <span className={`material-icons ${styles["delete-icon"]}`}>
              delete
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default DeleteSelection;
