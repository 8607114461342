import React, { useEffect } from "react";
import TableRow from "../../../../common/components/custom-table/table-row/TableRow";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { useSelector, useDispatch } from "react-redux";
import { setSelectionTablePageToRestore } from "../../../../redux/audience-selector/audience-selector.reducer";
import styles from "./selectionTable.module.scss";

const SelectionTable = ({
  tableColumns,
  tableRows,
  rowActions,
  hasRowClickAction,
  rowClickAction,
}) => {
  const dispatch = useDispatch();
  const topNavSearchValue = useSelector(
    ({ topNavSearch: { topNavSearchValue } }) => topNavSearchValue
  );

  const pageToRestore = useSelector(
    ({ audienceSelector: { selectionTablePageToRestore } }) =>
      selectionTablePageToRestore
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns: tableColumns,
      data: tableRows,
      initialState: {
        pageSize: 20,
        pageIndex: pageToRestore ?? 0,
        hiddenColumns: ["id"],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    setGlobalFilter(topNavSearchValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topNavSearchValue, tableRows, tableColumns]);

  let handlePageChange = (pageIndex) => {
    gotoPage(pageIndex);
    dispatch(setSelectionTablePageToRestore(pageIndex));
  };

  return (
    <div className={styles["table-n-pagination"]}>
      <div className={styles["table-container"]}>
        <div className={styles["selection-table-container"]}>
          <table className={styles.table} {...getTableProps()}>
            <thead className={styles.thead}>
              {headerGroups.map((headerGroup) => (
                <tr
                  className={styles.tr}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      className={styles.th}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className="material-icons-outlined">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "south"
                            : "north"
                          : ""}
                      </span>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className={styles.tbody} {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <TableRow
                    hasRowClickAction={hasRowClickAction}
                    rowClickAction={rowClickAction}
                    key={row.id}
                    rowActions={rowActions}
                    row={row}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {tableRows.length ? (
        <div className={styles["pagination-container"]}>
          <span className={styles["select-container"]}>
            Rows per page:
            <select
              className={styles.select}
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 20, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </span>
          <span>
            <span>
              {pageIndex + 1} of {pageOptions.length}
            </span>
            &nbsp;
          </span>
          <span className={styles["button-container"]}>
            <button
              onClick={() => handlePageChange(0)}
              disabled={pageIndex === 0}
            >
              <span className="material-icons-outlined">first_page</span>
            </button>
            <button
              onClick={() => handlePageChange(pageIndex - 1)}
              disabled={!canPreviousPage}
            >
              <span className="material-icons-outlined">chevron_left</span>
            </button>
            <button
              onClick={() => handlePageChange(pageIndex + 1)}
              disabled={!canNextPage}
            >
              <span className="material-icons-outlined">chevron_right</span>
            </button>
            <button
              onClick={() => handlePageChange(pageCount - 1)}
              disabled={pageIndex + 1 === pageCount}
            >
              <span className="material-icons-outlined">last_page</span>
            </button>
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SelectionTable;
