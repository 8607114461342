import React, { useState } from "react";
import CustomButton from "../../../common/components/custom-button/CustomButton";
import NavItemWithIcon from "../nav-item-with-icon/NavItemWithIcon";
import SmoothCollapse from "react-smooth-collapse";
import { useNavigate } from "react-router-dom";
import styles from "./sideNavListItem.module.scss";

const SideNavListItem = ({ icon, text, childrenNav }) => {
  const navigate = useNavigate();
  const [showChildren, setShowChildren] = useState(false);

  let toggleChildren = () => {
    setShowChildren(!showChildren);
  };

  let chevronStr = showChildren ? "less" : "more";
  return (
    <div className={styles.wrapper}>
      <div onClick={toggleChildren} className={styles["parent-container"]}>
        <i className={`material-icons-outlined ${styles.icon}`}>{icon}</i>
        <p className={styles.text}>{text}</p>
        <CustomButton>
          <span className={`material-symbols-outlined ${styles["btn-icon"]}`}>
            expand_{chevronStr}
          </span>
        </CustomButton>
      </div>
      <SmoothCollapse expanded={showChildren} heightTransition="0.5s ease">
        {childrenNav.map((item) => (
          <NavItemWithIcon
            key={item.name}
            action={() => navigate(item.path)}
            text={item.name}
            icon={item.icon}
          />
        ))}
      </SmoothCollapse>
      {/* <hr /> */}
    </div>
  );
};

export default SideNavListItem;
