import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  resetErrorPageState,
  setErrorCode,
  setErrorMessage,
  setPageErrorOccured,
} from "../../../redux/error-page/error-page.reducer";
import { getRoutes } from "./../../routes.service";
import { routeElements } from "../routes";
import { useIsAuthenticated } from "@azure/msal-react";
import { setCanApproveCampaignSends } from "../../../redux/user/user.reducer";
import ApiNew from "../../../common/api/apinew.service";
import { getUserDetails } from "./user.service";

const useAuth = () => {
  const { api } = ApiNew();

  const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [displayContent, setDisplayContent] = useState(false);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    if(!isAuthenticated){
      storeRouteInLocalStorage()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  useEffect(() => {
    if (api) {
      checkSiteAccessAndGetRoutes();
    } else if(!isAuthenticated) {
      setDisplayContent(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, isAuthenticated]);

  useEffect(() => {
    if(!!routes.length){
      const preLoadUrl = localStorage.getItem("preLoadUrl")

      if(preLoadUrl){
        navigate(preLoadUrl)
        
        localStorage.removeItem("preLoadUrl")
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes])

  let checkSiteAccessAndGetRoutes = async () => {
    if (!isAuthenticated) {
      navigateToErrorPage(401);
      return;
    }

    const routesResponse = await getRoutes(api);
    const userResponse = await getUserDetails(api);

    if (userResponse.status !== 200) {
      navigateToErrorPage(401);
      return;
    }

    //if(routesResponse.data.some(route => route.routePath === '/admin-control')){
    if (userResponse.data.IsCampaignSendApprover) {
      dispatch(setCanApproveCampaignSends(true))
    }

    if (routesResponse.status === 500) {
      navigateToErrorPage(500);
      return;
    }

    if (routesResponse.status !== 200 || !routesResponse.data) {
      navigateToErrorPage(401);
      return;
    }

    const routesComponents = routesResponse.data.map((route) =>
      routeElements.find(
        (routeElement) => route.routePath === routeElement.path
      )
    )
    
    setRoutes(routesComponents.filter((route) => !!route));

    setDisplayContent(true);
    dispatch(resetErrorPageState());
  };

  let navigateToErrorPage = (errorCode, errorMessage) => {
    setDisplayContent(true);
    dispatch(setPageErrorOccured(true));
    dispatch(setErrorCode(errorCode));
    dispatch(setErrorMessage(errorMessage));
    navigate("/error");
  };


  let storeRouteInLocalStorage = () => {
    const pathName = window.location.pathname
    const searchQuery = window.location.search

    localStorage.setItem("preLoadUrl", `${pathName}${searchQuery}`)
  }

  return { displayContent, routes };
};

export default useAuth;
