import React, { useEffect, useState, useCallback } from "react";
import CustomModal from "../../../../../common/components/custom-modal/CustomModal";
import styles from "./fieldSearchModal.module.scss";
import Button from "../../../../../common/components/custom-button/CustomButton";

const FieldSearchModal = ({
  isOpen,
  closeModal,
  ruleID,
  onSelectedFieldChange,
  fieldGroups,
  selectedItem,
}) => {
  const [searchedFieldGroups, setSearchedFieldGroups] = useState([]); //use this for the search results from the search input
  const [searchText, setSearchText] = useState(""); //search text from searchInput

  const [allFields, setAllFields] = useState([]); //all fields regardless of field group selected - use this when switching between group
  const [allGroups, setAllGroups] = useState([]); //all groups for the select options.
  const [selectedGroup, setSelectedGroup] = useState({});
  const [allFieldsForSelectedGroup, setAllFieldsForSelectedGroup] = useState(
    []
  ); //fields from the selected group in the dropdown.

  useEffect(() => {
    if (!fieldGroups) return;
    if (!selectedItem) return;

    const groups = fieldGroups.filter((x) => x.isHeader);
    const fields = fieldGroups.filter((x) => !x.isHeader);

    const fieldsByGroup = fields.filter(
      (field) => field.fieldGroup === selectedItem.fieldGroup
    );

    setAllFields([...fields]);
    setAllGroups([...groups]);
    setAllFieldsForSelectedGroup([...fieldsByGroup]);
    setSearchedFieldGroups([...fieldsByGroup]);

    setSearchText(selectedItem.label);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldGroups, selectedItem]);

  const fieldGroupOnChange = (event) => {
    const { value } = event.target;

    console.log(allFields.filter(field => field.fieldGroup === 'audience'))
    const fieldsByGroup = allFields.filter((x) => x.fieldGroup === value);
    const selectedGroupObj = allGroups.find((x) => x.fieldGroup === value);

    setSearchText("");
    setSelectedGroup(selectedGroupObj.name);
    setAllFieldsForSelectedGroup(fieldsByGroup);
    setSearchedFieldGroups(fieldsByGroup);
  };

  const fieldSearchOnChange = (event) => {
    const { value } = event.target;

    const searchedFields = allFieldsForSelectedGroup.filter((x) =>
      x.label.toLowerCase().includes(value.toLowerCase())
    );

    setSearchText(value);
    setSearchedFieldGroups(searchedFields);
  };

  const fieldOnClick = (item) => {
    setSearchText(item.label);
    setSelectedGroup(item.fieldGroup);

    onSelectedFieldChange(ruleID, item);

    closeModal(true);
  };

  const clearSearchValue = () => {
    setSearchText("");
    setSearchedFieldGroups(allFieldsForSelectedGroup);
  };

  const convertToUpperCase = useCallback((string) => {
    if(!string){
      return string
    }

    return string.trimStart().split(' ').map((word) => {
      switch(word){
        case 'dob':
          return word.toUpperCase()
        case 'id':
          return word.toUpperCase()
        default:
          return word[0].toUpperCase() + word.slice(1)
      }
    }).join(' ')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedFieldGroups])

  return (
    <CustomModal
      isOpen={isOpen}
      closeModal={closeModal}
      disableBackDropClick={true}
      disableScroll={true}
    >
      <div className={styles["container"]}>
        <div className={styles["field-group-select-container"]}>
          <select
            className={styles["field-group-select"]}
            onChange={(event) => fieldGroupOnChange(event)}
            value={selectedGroup}
          >
            {!!allGroups.length &&
              allGroups.map((fg) => {
                return (
                  <option
                    key={"fsm_fieldgroupoption_" + fg.name}
                    value={fg.fieldGroup}
                  >
                    {fg.label}
                  </option>
                );
              })}
          </select>
        </div>
        <div className={styles["search-input-container"]}>
          <input
            //value={searchValue}
            placeholder="Search..."
            className={styles["search-input"]}
            onChange={(event) => fieldSearchOnChange(event)}
            value={searchText}
          ></input>
          <Button type="button">
            <span
              className="material-icons-outlined"
              onClick={() => clearSearchValue()}
            >
              backspace
            </span>
          </Button>
        </div>
        <div className={styles["search-items-container"]}>
          {!!searchedFieldGroups.length &&
            searchedFieldGroups.map((item) => {
              return (
                <div
                  key={"fsm_searchitem_div_" + item.name}
                  className={
                    item === selectedItem
                      ? styles["search-item-selected"]
                      : styles["search-item"]
                  }
                >
                  <p
                    key={"fsm_searchitem_p_" + item.name}
                    className={styles["search-item-text"]}
                    onClick={() => fieldOnClick(item)}
                  >
                    {convertToUpperCase(item.label)}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
    </CustomModal>
  );
};

export default FieldSearchModal;
