import React from "react";
import Button from "@material-ui/core/Button";
import styles from "./customRelatesToField.module.scss";

const CustomRelatesToField = ({ inputValue, onInputChange, btnOnClick }) => {
  return (
    <div className={styles["input-btn-group"]}>
      <Button
        title="append"
        size="small"
        disabled={!inputValue.length}
        onClick={() => btnOnClick()}
      >
        <span className="material-icons-outlined">arrow_back_ios</span>
      </Button>
      <input
        className={styles.input}
        id="custom-relates-to-field"
        placeholder="Add custom 'Relates to'"
        value={inputValue}
        onChange={onInputChange}
      />
    </div>
  );
};

export default CustomRelatesToField;
