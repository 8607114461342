import React from "react";
import RowButton from "../RowButton/RowButton";

const ReadonlyRow = ({
  row,
  primaryKey,
  handleEditClick,
  handleDeleteClick,
  handleUndoDeleteClick,
  isRowDeleted,
  isRowEdited,
  handleUndoEditClick,
}) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        if (cell.column.id === "edited") {
          return <td style={{textAlign: 'center'}} key={cell.column.id} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
        }

        return (
          <td
            key={cell.column.id}
            style={{ textDecoration: isRowDeleted(row.original[primaryKey])? "line-through" : 'none', textAlign: 'center'}}
            {...cell.getCellProps()}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
      <td style={{ whiteSpace: 'nowrap'}}>
        {isRowDeleted(row.original[primaryKey]) ? (
          <RowButton
            materialIcon="undo"
            onClick={() => handleUndoDeleteClick(row.original[primaryKey])}
          />
        ) : isRowEdited(row.original[primaryKey]) ? (
          <>
            <RowButton
              materialIcon="undo"
              onClick={() => handleUndoEditClick(row.original[primaryKey])}
            />
            <RowButton
              materialIcon="edit"
              onClick={(event) => handleEditClick(event, row.original)}
            />
            <RowButton
              materialIcon="delete_forever"
              onClick={() => handleDeleteClick(row.original[primaryKey])}
            />
          </>
        ) : (
          <>
            <RowButton
              materialIcon="edit"
              onClick={(event) => handleEditClick(event, row.original)}
            />
            <RowButton
              materialIcon="delete_forever"
              onClick={() => handleDeleteClick(row.original[primaryKey])}
            />
          </>
        )}
      </td>
    </tr>
  );
};

export default ReadonlyRow;
