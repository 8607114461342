import React, { useEffect, useRef } from 'react';
import styles from './popoverMenu.module.scss'

const PopoverMenu = ({ row, close, menuData }) => {
    const ref = useRef()
    
    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                close()
            }
        }
        
        setTimeout(() => {
            document.addEventListener("click", checkIfClickedOutside)
        }, 0)

        return () => {
            document.removeEventListener("click", checkIfClickedOutside)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <div ref={ref} className={styles['popover-menu']}>
            {/* <div className={styles.arrow}></div> */}
            <div className={styles['menu-body']}>
                <div className={styles['menu-items']}>
                    {menuData.map(menuItem => 
                        <button data-testid='pop-over-button' onClick={() => menuItem.action(row)} key={menuItem.id} className={styles['menu-item']}>
                            {menuItem.text}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
};

export default PopoverMenu;
