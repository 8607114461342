import React from 'react'
import Controls from './Controls/Controls'
import IndividualDetailsTabs from './individual-details-tab/IndividualDetailsTabs';
import styles from './individualDetails.module.scss'

const IndividualDetails = ({ selectedIndividual, pageControls }) => {

    return (
        <div className={styles["details-container"]}>
            <div className={styles["details-wrapper"]}>
                <div className={styles["header"]}>
                    <Controls
                        controlID={"div_Individual_PersonName"}
                        pageControls={pageControls}
                        individual={selectedIndividual}
                    />
                </div>
                <div className={styles["individual-info-section"]}>
                    <div className={styles["section1"]}>
                        <Controls
                            controlID={"div_Individual_ListGroupItems"}
                            pageControls={pageControls}
                            individual={selectedIndividual}
                        />
                    </div>
                    <div className={styles["section2"]}>
                        <div className={styles["metrics-container"]}>
                            <div>
                                <Controls
                                    controlID={"div_Individual_MetricTypes"}
                                    pageControls={pageControls}
                                    individual={selectedIndividual}
                                />
                            </div>
                        </div>
                        <div className={styles["cards-container"]}>
                            <Controls
                            controlID={"div_Individual_Cards"}
                            pageControls={pageControls}
                            individual={selectedIndividual}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <IndividualDetailsTabs pageControls={pageControls} selectedIndividual={selectedIndividual} />
                </div>
            </div>
        </div>
    )
}

export default IndividualDetails