import React from "react";
import uuid from "react-uuid";
import HTMLReactParser from "html-react-parser";
import styles from './listGroupControl.module.scss'

const ListGroupControl = ({ controlData }) => {
  let controls = [];

  let bgColor;
  let color;
  let className;
  const bgColorTagOpen = "<BG-COLOR>";
  const bgColorTagClose = "</BG-COLOR>";
  const colorTagOpen = "<COLOR>";
  const colorTagClose = "</COLOR>";
  const faImageTagOpen = "<FA-IMAGE>";
  const faImageTagClose = "</FA-IMAGE>";
  const classTagOpen = "<CLASS>";
  const classTagClose = "</CLASS>";

  const removeAndSetBGColorFromValue = (value) => {
    if (!value.search(bgColorTagOpen)) {
      return value;
    }

    const start = value.toLowerCase().indexOf(bgColorTagOpen.toLowerCase());
    const tagLength = bgColorTagOpen.length;
    const end = value.indexOf(bgColorTagClose);
    const bgColorValue = value.substring(start + tagLength, end);

    bgColor = bgColorValue;

    return value.replace(bgColorTagOpen + bgColorValue + bgColorTagClose, "");
  };

  const removeAndSetColorFromValue = (value) => {
    if (!value.search(colorTagOpen)) {
      return value;
    }

    const start = value.indexOf(colorTagOpen);
    const tagLength = colorTagOpen.length;
    const end = value.indexOf(colorTagClose);
    const colorValue = value.substring(start + tagLength, end);

    color = colorValue;

    return value.replace(colorTagOpen + colorValue + colorTagClose, "");
  };

  const removeAndSetIconFromValue = (value) => {
    if (!value.search(faImageTagClose)) {
      return value;
    }

    const start = value.indexOf(faImageTagOpen);
    const tagLength = faImageTagOpen.length;
    const end = value.indexOf(faImageTagClose);
    const faImage = value.substring(start + tagLength, end);

    let value2 = value.replace(
      faImageTagOpen + faImage + faImageTagClose,
      '<i className="' + faImage + '"></i>'
    );

    return value2;
  };

  const removeAndSetClassFromValue = (value) => {
    if (!value.search(classTagClose)) {
      return value;
    }

    const start = value.indexOf(classTagOpen);
    const tagLength = classTagOpen.length;
    const end = value.indexOf(classTagClose);

    className = value.substring(start + tagLength, end);

    let value2 = value.replace(classTagOpen + className + classTagClose, "");

    return value2;
  };

  controlData.forEach((record) => {
    let unorderedList = [];

    const keys = Object.keys(record);

    keys.forEach((key) => {
      let value = record[key];

      value = removeAndSetBGColorFromValue(value);
      value = removeAndSetColorFromValue(value);
      value = removeAndSetIconFromValue(value);
      value = removeAndSetClassFromValue(value);

      let spanClass = className;

      unorderedList.push(
        <li key={uuid()} style={{ position: "relative", display: "block", padding: 0 }}>
          <span
            className={spanClass}
            style={{
              backgroundColor: bgColor,
              color: color,
            }}
          >
            {HTMLReactParser(value)}
          </span>
        </li>
      );
    });

    controls.push(
      <ul
        className={styles.ul}
        key={uuid()}
        style={{
          
        }}
      >
        {unorderedList}
      </ul>
    );
  });

  return controls;
};

export default ListGroupControl;
