import React, { useState, useEffect } from 'react'
import QuickSearchData from './QuickSearchOptions.json'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import styles from './individualQuickSearch.module.scss'

const IndividualQuickSearch = ({ searchCriteriaSaveOnClick, selectedSearchCriteria }) => {
  const [searchCriteriaSelected, setSearchCriteriaSelected] = useState('')
  
  useEffect(() => {
    if(selectedSearchCriteria){
      setSearchCriteriaSelected(selectedSearchCriteria)
    } else {
      setSearchCriteriaSelected(QuickSearchData[0].name)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let handleSave = () => {
    searchCriteriaSaveOnClick(searchCriteriaSelected)
  }
  
  return (
    <div className={styles["select-modal-container"]}>
      <div className={styles["select-container"]}>
        <p className={styles["label"]}>Select Search criteria:</p>
        <Select
          id='search-criteria'
          className={styles.select}
          value={searchCriteriaSelected}
          onChange={(e) => setSearchCriteriaSelected(e.target.value)}
        >
          {QuickSearchData.map(
              (item) => <MenuItem key={item.name} value={item.name}>{item.label}</MenuItem>
            )}
        </Select>
      </div>
      <div className={styles.actions}>
        <Button className={styles.save} onClick={handleSave} variant="contained">Save</Button>
      </div>
    </div>
  )
}

export default IndividualQuickSearch