import React, { useState, useEffect } from 'react'
import SmoothCollapse from 'react-smooth-collapse'
import CustomTable from '../../../common/components/custom-table/CustomTable';
import styles from './individualSearchResults.module.scss'

const IndividualSearchResults = ({ expand, searchResults, loading, hasError, errorMessage, handleRowSelect }) => {
    const [newColumns, setNewColumns] = useState([])
    
    useEffect(() => {
        if(!!searchResults.length){
            const columnKeys = Object.keys(searchResults[0]);

            setNewColumns(columnKeys.map((key) => ({
                Header: createFriendlyName(key),
                accessor: key,
            })));
        } else{
            setNewColumns([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResults])

    const createFriendlyName = function (key) {
        return key
            .toString()
            .replace("_", " ") //remove _ with spaces
            .split(" ") //split the word so we can get sub-words
            .map((word) => word[0].toUpperCase() + word.substring(1, word.length)) //upper case first letter of each word.
            .join(" "); //rejoin words with spaces between
    };

    return (
        <SmoothCollapse expanded={expand} heightTransition='0.8s'>
            <div className={styles.container}>
                {loading? <><i className='fa fa-spinner fa-pulse'></i> loading individuals...</> : ''}
                {hasError? 
                    <span className={styles.error}>{errorMessage}</span>
                        : 
                    <CustomTable 
                    tableColumns={newColumns} 
                    tableRows={searchResults}
                    hasRowClickAction
                    rowClickAction={handleRowSelect}
                    />
                }
            </div>
        </SmoothCollapse>
    )
}

export default IndividualSearchResults