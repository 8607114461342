import React from "react";
import uuid from 'react-uuid'

const ProfilesDetailsTab = ({ personalDetails }) => {
  return personalDetails
    ? personalDetails.map((profileDetail) => {
        return (
          <div key={uuid()}>
            <h5>Personal Details</h5>
            <table>
              <tbody>
                {Object.keys(profileDetail).map((key) => {
                  return (
                    <tr key={uuid()}>
                      <td>{key}:</td>
                      <td>{profileDetail[key]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })
    : "Loading...";
};

export default ProfilesDetailsTab;
