import React from 'react'
import AudienceSelectionRecord from '../../../components/audience-selector/audience-record/AudienceSelectionRecord';
import styles from './audienceRecord.module.scss'

const AudienceRecord = () => {

  return (
    <div className={styles.audience}>
      <div>
        <AudienceSelectionRecord />
      </div>
    </div>
  )
}

export default AudienceRecord