import React from 'react'
import styles from './mobileNavItem.module.scss'

const MobileNavItem = ({ item, action }) => {
    return (
        <div onClick={action} title={item.name} className={styles["menu-item"]}>
            <span className={`material-icons-outlined ${styles.icon}`}>{item.icon}</span>
        </div>
    )
}

export default MobileNavItem