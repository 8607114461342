import React from "react";
import uuid from "react-uuid";

const Cards = ({ controlData }) => {
  let controls = [];
  let bgColor = "blue";
  const bgColorTagOpen = "<BG-COLOR>";
  const bgColorTagClose = "</BG-COLOR>";

  const removeAndSetBGColorFromKey = (key) => {
    if (!key.search(bgColorTagOpen)) {
      return key;
    }

    const start = key.indexOf(bgColorTagOpen);
    const tagLength = bgColorTagOpen.length;
    const end = key.indexOf(bgColorTagClose);
    const color = key.substring(start + tagLength, end);

    bgColor = color;

    return key.replace(bgColorTagOpen + color + bgColorTagClose, "");
  };

  const cardShadowStyle = (bgColor) => {
    return {
      position: "relative",
      minHeight: "5rem",
      display: "flex",
      flexDirection: "column",
      minWidth: "7rem",
      wordWrap: "break-word",
      backgroundColor: "#fff",
      border: "1px solid rgba(0,0,0,0.25)",
      borderRadius: "0.25rem",
      boxShadow: "0 0.5rem 1rem rgba(0,0,0,0.15)",
      borderLeft: ".25rem solid",
      width: "7rem",
      padding: "0.5rem, 0",
      margin: "0.5rem",
      borderLeftColor: bgColor,
    };
  };

  const styles = {
    cardBody: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
      padding: "1rem 0rem 1rem 0rem",
    },
    cardRow: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    cardCol: {
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: "0%",
    },
    headerText: {
      marginLeft: -1,
      marginRight: -1,
      textAlign: "center",
      textTransform: "uppercase",
      color: "rgb(33, 37, 41)",
      marginBottom: "0.25rem",
    },
    valueText: {
      color: "rgb(33, 37, 41)",
      textAlign: "center",
      marginTop: "0.3rem",
      marginBottom: 0,
      fontWeight: 500,
      lineHeight: 1.2,
      fontSize: "0.7rem",
    },
  };

  controlData.forEach((record) => {
    const keys = Object.keys(record);

    keys.forEach((key) => {
      const title = removeAndSetBGColorFromKey(key);
      const value = !record[key] || record[key].length === 0 ? " " : record[key];

      controls.push(
        <div key={uuid()} style={cardShadowStyle(bgColor)}>
          <div style={styles.cardBody}>
            <div style={styles.cardRow}>
              <div style={styles.cardCol}>
                <div style={styles.headerText}>
                  <span>{title}</span>
                </div>
                <div style={styles.valueText}>
                  <span>{value}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  });

  return controls;
};

export default Cards;
