import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button'
import SmoothCollapse from 'react-smooth-collapse';
import MobileNavItem from './../mobile-nav-item/MobileNavItem';
import styles from './parentMobileNavItem.module.scss'

const ParentMobileNavItem = ({ navItem }) => {
    const navigate = useNavigate()

    const [ showChildren, setShowChildren ] = useState(false)

    return (
        <>
            <div onClick={() => setShowChildren(!showChildren)} title={navItem.name} className={styles["menu-item"]}>
                <span className={`material-icons-outlined ${styles.icon}`}>{navItem.icon}</span>
                <div className={styles["toggle-icon-container"]}>
                    <Button size='small' className={styles["toggle-btn"]}>
                        <span className='material-icons-outlined'>expand_{showChildren? 'less' : 'more'}</span>
                    </Button>
                </div>
            </div>
            <div className={styles["children-container"]}>
                <SmoothCollapse expanded={showChildren} heightTransition='0.5s'>
                    {!!navItem.children && navItem.children.map((childNav) => (
                        <MobileNavItem key={childNav.name} item={childNav} action={() => navigate(childNav.path)} />
                    ))}
                </SmoothCollapse>
            </div>
            <hr />
        </>
    )
}

export default ParentMobileNavItem