import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../../common/auth/auth-config/authConfig";
import styles from "./signInButton.module.scss";

const SignInButton = () => {
  const { instance } = useMsal();

  let handleLogin = () => {
    instance
      .loginRedirect(loginRequest)
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };

  return (
    <div className={styles["container"]}>
      <div
        data-testid="sign-in-button"
        className={styles["image-text-container"]}
        onClick={() => handleLogin()}
      >
        <i className={`material-icons-outlined ${styles["icon"]}`}>login</i>
        <p className={styles["text"]}>Sign In</p>
      </div>
    </div>
  );
};

export default SignInButton;
