import React from 'react'
import ButtonItem from './ButtonItem/ButtonItem'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { resetAdhocCommunicationState, switchModal } from './../../../redux/adhoc-communication/adhoc-communication.reducer';
import styles from './newAdhocCommuicatonModal.module.scss'

const NewAdhocCommuicatonModal = () => {
  const dispatch = useDispatch()
  return (
    <div className={styles["modal-container"]}>
        <h1 className={styles.header}>New Adhoc Communication</h1>
        <p className={styles["sub-header"]}>Select a type of communication to configure</p>

        <div className={styles.main}>
            <ButtonItem
             headerText="Triggered Email"
             icon="alternate_email"
             text="Send a triggered email to the audience using you Email Service Provider (ESP).
                The contact does not need to exist within the ESP database, and does not need to be opted in"
              action={() => dispatch(switchModal(3))}
            />
            <ButtonItem 
             headerText="Triggered SMS"
             icon='sms'
             action={() => dispatch(switchModal(1))}
             text="Send a triggered SMS message to the audience using your SMS messaging provider." 
            />
        </div>

        <div className={styles.actions}>
          <Button onClick={() => dispatch(resetAdhocCommunicationState())} size='small' className={styles.cancel}>Cancel</Button>
      </div>
    </div>
  )
}

export default NewAdhocCommuicatonModal