

export const setFriendlyName = (dateList) => {
    let calenderList = [...dateList.map(dateObj => {
        return {
            name: dateObj.name,
            friendlyName: getFriendlyName(dateObj.name)
        }
    })]


   return calenderList
}

const getFriendlyName = (calenderName) => {
    switch (calenderName) {
        case 'current_season_td':
            return 'current season today'

        case 'last_season_td':
            return 'last season today'

        case 'ly_current_month':
            return 'last year current month'

        case 'ly_current_quarter':
            return 'last year current quarter'

        case 'ly_current_season':
            return 'last year current season'

        case 'ly_current_season_td':
            return 'last year current season today'

        case 'ly_current_week':
            return 'last year current week'

        case 'ly_last_month':
            return 'last year last month'

        case 'ly_last_quarter':
            return 'last year last quarter'

        case 'ly_last_season':
            return 'last year last season'

        case 'ly_last_week':
            return 'last year last week'

        case 'ly_mtd':
            return 'last year month to date'

        case 'ly_penul_month':
            return 'last year penultimate month'

        case 'ly_penul_week':
            return 'last year penultimate week'

        case 'ly_same_day':
            return 'last year same day';

        case 'ly_yesterday':
            return 'last year yesterday'

        case 'ly_ytd':
            return 'last year year to date'

        case 'penul_month':
            return 'penultimate month'

        case 'penul_quarter':
            return 'penultimate quarter'

        case 'penul_week':
            return 'penultimate week'

        case 'penultimate_ytd':
            return 'penultimate year to date'

        case 'yesterday-1':
            return 'day before yesterday'

        case 'ytd':
            return 'year to date'
            
        default:
            return removeUnderScore(calenderName)
    }
}

const removeUnderScore = (str) => {
    const newString = str.replaceAll('_', ' ')
    return newString
}