import AudienceBuilderPage from "./audience-builder-page/AudienceBuilderPage";
import AudienceRecord from "./audience-record/AudienceRecord";
import Communicate from "./communicate/Communicate";
import Connections from "./connections/Connections";
import Customers from "./customers/Customers";
import MasterData from "./master-data/MasterData";

export const routeElements = [
  { path: "/customers", element: <Customers /> },
  { path: "/connections", element: <Connections /> },
  { path: "/communicate", element: <Communicate /> },
  { path: "/masterdata", element: <MasterData /> },
  { path: "/audience-record", element: <AudienceRecord /> },
  { path: "/audience-builder", element: <AudienceBuilderPage />}
  // { path: "/superset", element: <Superset /> },
];
