import React, { useState, useEffect } from "react";
import { getControlData } from "../../individual.service";
import uuid from "react-uuid";
import RawHtml from "./raw-HTML/RawHtml";
import LabelValuePair from "./label-value-pair/LabelValuePair";
import TagListItems from "./tag-list-items/TagListItems";
import ListGroupControl from "./list-group-control/ListGroupControl";
import ProfilesCards from "./profiles-cards/ProfilesCards";
import OrdersCards from "./orders-cards/OrdersCards";
import Cards from "./cards/Cards";
import CustomTable from "./../../../../common/components/custom-table/CustomTable";
import styles from "./controls.module.scss";
import ApiNew from "../../../../common/api/apinew.service";
import IndividualPreferences from "./individual-preferences/IndividualPreferences";

const createFriendlyName = function (key) {
  return key
    .toString()
    .replace("_", " ") //remove _ with spaces
    .split(" ") //split the word so we can get sub-words
    .map((word) => word[0].toUpperCase() + word.substring(1, word.length)) //upper case first letter of each word.
    .join(" "); //rejoin words with spaces between
};

const Controls = ({ controlID, pageControls, individual }) => {
  const { api } = ApiNew();
  const [controlsToRender, setControlsToRender] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (api) {
        setIsLoaded(false);
        let specificData = await getSpecificData();

        setControlsToRender(specificData);

        setIsLoaded(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageControls, api]);

  const getSpecificData = () => {
    if (pageControls) {
      const specificControls = pageControls.filter(
        (x) => x.ParentControlID === controlID
      );

      return Promise.all(
        specificControls.map((control) => {
          let controls = fetchControlData(control);

          return controls;
        })
      );
    }
  };

  let orderHeaderData = null;
  let orderLineData = null;

  const fetchControlData = async (control) => {
    if (control !== undefined) {
      if (control.ControlType.toLowerCase() === "separator") {
        return <hr key={uuid()} />;
      }

      const query = control.Query;

      if (!query) return null;

      const parameter = control.Parameter;

      let body = {};

      if (parameter === "xcm_ind_id") {
        body = {
          xcm_ind_id: individual["XCM IND ID"],
        };
      } else if (parameter === "person_id") {
        body = {
          person_id: individual["Person ID"],
        };
      }

      let controlData = [];

      await getControlData(query, body, api)
        .then((res) => {
          controlData = res.data;
        })
        .catch((reason) => {
          console.log("Reason: ", reason.request.response);
        });

      if (!controlData || controlData?.length === 0) {
        return (
          <div key={uuid()} style={{ fontSize: "0.8rem" }}>
            No data to show
          </div>
        );
      }

      switch (control.ControlType) {
        case "RawHTML":
          if (query.includes("OrderLine")) {
            return null;
          }

          return <RawHtml key={uuid()} controlData={controlData} />;

        case "LabelValuePair":
          if (query.includes("Profiles")) {
            return "";
          }
          return <LabelValuePair key={uuid()} controlData={controlData} />;

        case "Cards":
          return <Cards key={uuid()} controlData={controlData} />;

        case "TagListItems":
          return <TagListItems key={uuid()} controlData={controlData} />;

        case "ListGroupItems":
          return <ListGroupControl key={uuid()} controlData={controlData} />;

        case "TransposedMatrix2":
          return <IndividualPreferences key={uuid()} controlData={controlData} />;

        case "AdvancedTable":
        case "TransposedMatrix":
        case "TransposedActionMatrix":
        case "GridViewSinglePage":
        case "AdvancedCardTable":
          if (query.toLowerCase().includes("profiles")) {
            return <ProfilesCards key={uuid()} profiles={controlData} />;
          }

          if (query.toLowerCase().includes("orderheader")) {
            orderHeaderData = controlData;
          }

          if (query.toLowerCase().includes("orderline")) {
            orderLineData = controlData;
          }

          if (!orderLineData || !orderHeaderData) {
            return;
          }

          if (orderLineData && orderHeaderData) {
            return (
              <OrdersCards
                key={uuid()}
                orderHeaders={orderHeaderData}
                orderLines={orderLineData}
              />
            );
          }

          if (query.toLowerCase().includes("orderline")) {
            return "";
          }

          const columnKeys = Object.keys(controlData[0]);

          const newColumns = columnKeys.map((key) => ({
            Header: createFriendlyName(key),
            accessor: key,
          }));

          return (
            <CustomTable
              key={uuid()}
              tableRows={controlData}
              tableColumns={newColumns}
            />
          );

        case "Separator":
          return (
            <div key={uuid()}>
              <hr />
            </div>
          );

        default:
          break;
      }
    }
  };

  return (
    <>{isLoaded ? controlsToRender : <div className={styles.loader}></div>}</>
  );
};

export default Controls;
