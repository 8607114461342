import React, { useEffect, useState, useRef } from "react";
import ApiNew from "../../../../../../common/api/apinew.service";
import CustomModal from "../../../../../../common/components/custom-modal/CustomModal";
import styles from "./valueSearchModal.module.scss";
import { getDistinctFieldValueV2 } from "../../../../audienceSelector.service";
import Button from "../../../../../../common/components/custom-button/CustomButton";
const ValueSearchModal = ({
  isOpen,
  closeModal,
  ruleID,
  fieldGroup,
  ruleData,
  setRuleDataValue,
}) => {
  let timer = useRef();
  const { api } = ApiNew();
  const [allValues, setAllValues] = useState([]); //all fields regardless of field group selected - use this when switching between group
  const [searchedValues, setSearchedValues] = useState([]); //use this for the search results from the search input
  const [searchText, setSearchText] = useState(""); //search text from searchInput
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (!fieldGroup) return;
      if (!api) return;

      const fieldItem = {
        fieldName: fieldGroup.name,
        fieldValue: ruleData.value,
        fieldGroup: fieldGroup.fieldGroup,
      };

      let list = await getDistinctFieldValueV2(fieldItem, api);

      setLoading(false);

      if (ruleData?.value?.length && list?.length) {
        setSearchText(ruleData.value);

        list = list.filter((listItem) =>
          listItem.toLowerCase().includes(ruleData.value.toLowerCase())
        );
      }

      setAllValues([...list]);
      setSearchedValues([...list]);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldGroup, ruleData, api]);

  const fieldSearchOnChange = (event) => {
    setLoading(true);
    clearTimeout(timer.current);

    const { value } = event.target;

    const fieldItem = {
      fieldName: fieldGroup.name,
      fieldValue: value,
      fieldGroup: fieldGroup.fieldGroup,
    };

    setSearchText(value);

    //timeout before making API call.
    timer.current = setTimeout(async () => {
      console.log("api", api);
      console.log("fieldItem", fieldItem);
      const fields = await getDistinctFieldValueV2(fieldItem, api);
      setSearchedValues(fields);
    }, 500);

    setLoading(false);
  };

  const fieldOnClick = (item) => {
    setRuleDataValue(ruleData.id, item);

    setSearchText(item);

    closeModal(true);
  };

  const clearSearchValue = () => {
    setSearchText("");

    setSearchedValues(allValues);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      closeModal={closeModal}
      disableBackDropClick={true}
      disableScroll={true}
    >
      <div className={styles["container"]}>
        <div style={{ marginTop: "2rem", fontStyle: "italic" }}>
          Showing values the first 1000 records for '<b>{fieldGroup?.label}</b>
          '. Please use the search field to extend search...
        </div>
        <div style={{ marginTop: "0rem", fontStyle: "italic" }}>
          <b>NOTE:</b> this field might not have any values to show.
        </div>
        <div className={styles["search-input-container"]}>
          <input
            //value={searchValue}
            placeholder="Search..."
            className={styles["search-input"]}
            onChange={(event) => fieldSearchOnChange(event)}
            value={searchText}
          ></input>
          <Button type="button">
            <span
              className="material-icons-outlined"
              onClick={() => clearSearchValue()}
            >
              backspace
            </span>
          </Button>
        </div>
        <div className={styles["search-items-container"]}>
          {loading ? (
            <div>Loading....</div>
          ) : !!searchedValues.length ? (
            searchedValues.map((item) => {
              return (
                <div
                  key={"fsm_searchitem_div_" + item}
                  className={styles["search-item"]}
                >
                  <p
                    key={"fsm_searchitem_p_" + item}
                    className={styles["search-item-text"]}
                    onClick={() => fieldOnClick(item)}
                  >
                    {item}
                  </p>
                </div>
              );
            })
          ) : (
            <div>No results found.</div>
          )}
          {/* {searchedValues?.length &&
            searchedValues?.map((item) => {
              return (
                <div className={styles["search-item"]}>
                  <p
                    className={styles["search-item-text"]}
                    onClick={() => fieldOnClick(item.fieldValue)}
                  >
                    {item.fieldValue}
                  </p>
                </div>
              );
            })} */}
        </div>
      </div>
    </CustomModal>
  );
};

export default ValueSearchModal;
