import React from "react";
import Button from "@material-ui/core/Button";

const CustomButton = ({
  children,
  textColor,
  bgColor,
  btnSize,
  useMuiStyle,
  ...otherProps
}) => {
  return (
    <Button
      size={btnSize}
      variant='contained'
      {...otherProps}
      style={{
        backgroundColor: useMuiStyle ? "#83C1B9" : bgColor,
        color: textColor?? '#ffffff',
        margin: "0 0.1rem",
      }}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
