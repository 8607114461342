import React from 'react'
import styles from './navItemWithIcon.module.scss'

const NavItemWithIcon = ({ icon, text, action }) => {
  
  return (
    <div onClick={action} className={styles.container}>
        <i className={`material-icons-outlined ${styles.icon}`}>{icon}</i>
        <p className={styles.text}>{text}</p>
    </div>
  );
};

export default NavItemWithIcon;
