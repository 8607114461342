import React from "react";
import Button from "@material-ui/core/Button";
import CommunicationTable from "./communication-table/CommunicationTable";
import CustomModal from "../../common/components/custom-modal/CustomModal";
import TriggeredSmsModal from "./triggered-sms-modal/TriggeredSmsModal";
import SmsConfirmationModal from "./sms-confirmation-modal/SmsConfirmationModal";
import NewAdhocCommuicatonModal from "./new-adhoc-communication-modal/NewAdhocCommuicatonModal";
import TriggeredEmailModal from "./triggered-email-modal/TriggeredEmailModal";
import EmailConfirmationModal from "./email-confirmation-modal/EmailConfirmationModal";
import AutoRefreshToggle from "./common/auto-refresh-toggle/AutoRefreshToggle";
import { useDispatch } from "react-redux";
import { setCommunicationModal } from "../../redux/adhoc-communication/adhoc-communication.reducer";
import { useAdhocCommunication } from "./useAdhocCommunication";
import styles from "./adhocCommunication.module.scss";
const AdhocCommunication = () => {
  const dispatch = useDispatch();

  const {
    newColumns,
    broadcasts,
    loading,
    communicationModalId,
    communicationModalOpen,
    closeCommunicationModal,
    viewBroadcast,
    approveCampaign,
    declineCampaign,
    refreshTable,
    tableAutoRefresh,
    handleTableAutoRefreshValueChange,
  } = useAdhocCommunication();

  let changeModal = (communicationModalId) => {
    switch (communicationModalId) {
      case 0:
        return (
          <NewAdhocCommuicatonModal closeModal={closeCommunicationModal} />
        );
      case 1:
        return <TriggeredSmsModal />;
      case 2:
        return <SmsConfirmationModal closeModal={closeCommunicationModal} />;
      case 3:
        return <TriggeredEmailModal />;
      case 4:
        return <EmailConfirmationModal closeModal={closeCommunicationModal} />;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   if (api && isAuthenticated) {
  //     const locationQueryString = queryString.parse(window.location.search);
  //     const campaignID = locationQueryString.campaignid;

  //     if (campaignID) {
  //       viewBroadcast(campaignID);
  //     }
  //   }
  // });

  return (
    <>
      <div className={styles.communications}>
        <div className={styles["create-new-container"]}>
          <AutoRefreshToggle handleChange={handleTableAutoRefreshValueChange} isOn={tableAutoRefresh === 'On'} isOff={tableAutoRefresh === 'Off'} />
          <Button
            variant="contained"
            className={styles["refresh-comms-btn"]}
            onClick={() => refreshTable()}
            disabled={loading ? true : false}
          >
            {loading ? (
              <div>
                <i className="fa fa-spinner fa-pulse"></i> Loading Campaigns...
              </div>
            ) : (
              "Refresh Campaigns"
            )}
          </Button>
          <Button
            className={styles["create-comms-btn"]}
            variant="contained"
            onClick={() => dispatch(setCommunicationModal(true))}
          >
            Create
          </Button>
        </div>
        {/* <p
          style={{
            display: loading ? "block" : "none",
            textAlign: "center",
            marginBottom: "1rem",
          }}
        >
          <i className="fa fa-spinner fa-pulse"></i> Loading Communications...
        </p> */}
        {broadcasts?.length > 0 ? (
          <div className={styles["comms-table-container"]}>
            <CommunicationTable
              tableColumns={newColumns}
              tableRows={broadcasts}
              rowClickAction={(row) => viewBroadcast(row.original.Campaign)}
              approveBtnClickAction={(row) =>
                approveCampaign(row.original.Campaign)
              }
              declineBtnClickAction={(row) =>
                declineCampaign(row.original.Campaign)
              }
            />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <i>Click CREATE to create your first campaign.</i>
          </div>
        )}
      </div>

      <CustomModal
        disableBackDropClick={true}
        isOpen={communicationModalOpen}
        closeModal={closeCommunicationModal}
      >
        {changeModal(communicationModalId)}
      </CustomModal>
    </>
  );
};

export default AdhocCommunication;
