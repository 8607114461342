import React, { useEffect, useRef } from "react";
import CustomCheckbox from "../../../../../../common/components/custom-checkbox/CustomCheckbox";
import styles from "./searchMultiSelectPopover.module.scss";

const SearchMultiSelectPopover = ({
  close,
  onSearchChange,
  searchValue,
  filteredSelectableItems,
  selectedItems,
  checkBoxOnChange,
}) => {
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        close();
      }
    };

    setTimeout(() => {
      document.addEventListener("click", checkIfClickedOutside);
    }, 0);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={ref} className={styles["popover-container"]}>
      <div className={styles["menu-body"]}>
        <input
          className={styles["search-input"]}
          value={searchValue}
          placeholder="Search..."
          onChange={onSearchChange}
        ></input>
        {!!selectedItems.length ? (
          <p className={styles["selected-items"]}>
            Selected items: ({selectedItems.join(", ")})
          </p>
        ) : (
          ""
        )}
        {!!filteredSelectableItems.length ? (
          <ul className={styles.ul}>
            {filteredSelectableItems.map((item) => {
              return (
                <li key={item.id} className={styles.li}>
                  <CustomCheckbox
                    id={item.name}
                    checked={selectedItems.includes(item.name)}
                    onChange={(e) => checkBoxOnChange(e, item.name)}
                  />
                  <label htmlFor={item.name}>{item.name}</label>
                </li>
              );
            })}
          </ul>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SearchMultiSelectPopover;
