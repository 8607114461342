import React, { useEffect, useState, useTransition, useRef } from "react";
import { useSelector } from "react-redux";
import { getQueryRecords, getQueryRecordsCount } from "./audienceRecord.service";
import { getSelectionById } from "../audienceSelector.service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useIsAuthenticated } from "@azure/msal-react";
import { setAudienceToEdit } from "../../../redux/audience-selector/audience-selector.reducer";
import Button from "@material-ui/core/Button";
import AudienceRecordTable from './audience-record-table/AudienceRecordTable';
import ApiNew from "../../../common/api/apinew.service";
import styles from "./audienceSelectionRecord.module.scss";

const AudienceSelectionRecord = () => {
  const { api } = ApiNew();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();
  const [loading, setLoading] = useState(false);
  const [queryRecords, setQueryRecords] = useState([]);
  const [newColumns, setNewColumns] = useState([]);
  const [recordOffset, setRecordOffset] = useState(0)
  const [recordLimit, setRecordLimit] = useState(20)
  const [totalAudiences, setTotalAudiences] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  
  const audienceToViewId = useSelector(
    ({ audienceSelector: { audienceToViewId } }) => audienceToViewId
  );

  const topNavSearchValue = useSelector(
    ({ topNavSearch: { topNavSearchValue } }) => topNavSearchValue
  );

  const [audienceToView, setAudienceToView] = useState();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (api && isAuthenticated) {
      if (!audienceToViewId) {
        navigate("/connections");
      } else {
        fetchRecords();
      }
    }

    return () => {
      setQueryRecords([]);
      clearTimeout(timer.current)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, isAuthenticated, recordLimit, recordOffset]);

  let timer = useRef();

  useEffect(() => {
    clearTimeout(timer.current)

    timer.current = setTimeout(() => {
      if(currentPage > 1){
        //navigates to first page. The useEffect above then calls fetchRecords
        setCurrentPage(1)
        setRecordOffset(0)
      } else {
        fetchRecords()
      }
    }, 2000)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topNavSearchValue])
  
  //something here?
  const fetchRecords = async () => {
    setLoading(true);
    setQueryRecords([])

    const audience = await getSelectionById(audienceToViewId, api)
    const parsedAudience = parseJson(audience);
    setAudienceToView(parsedAudience);
    const count = await getQueryRecordsCount(audienceToViewId, topNavSearchValue, api)
    setTotalAudiences(count)

    if(count > 0){
      const records = await getQueryRecords(audienceToViewId, recordOffset, recordLimit, topNavSearchValue, api);

      startTransition(() => {
        setQueryRecords(convertDateRegisteredToReadableDate(records));
      });
    }

    setLoading(false);
  };


  const friendlyColumnName = (key) => {
    return key
      .toString()
      .replace("_", " ") //remove _ with spaces
      .split(" ") //split the word so we can get sub-words
      .map((word) => word[0].toUpperCase() + word.substring(1, word.length)) //upper case first letter of each word.
      .join(" "); //rejoin words with spaces between
  };

  useEffect(() => {
    if (queryRecords.length) {
      const columnKeys = Object.keys(queryRecords[0]);

      setNewColumns(
        columnKeys.map((key) => ({
          Header: friendlyColumnName(key),
          accessor: key,
        }))
      );
    }
  }, [queryRecords]);

  let convertDateRegisteredToReadableDate = (records) => {
    return [...records].map((record) => ({
      ...record,
    }));
  };

  let parseJson = (audience) => {
    const obj = {
      id: audience.id,
      name: audience.name,
      description: audience.description,
      count: audience.individuals,
      limit: audience.limit,
      autoRefresh: audience.auto_refresh,
      refreshedAt: audience.refreshed_at,
      json: JSON.parse(audience.json),
    };

    return obj;
  };

  const editAudience = () => {
    dispatch(setAudienceToEdit({...audienceToView}))
    navigate('/audience-builder')
  }

  return (
    <>
      {!!audienceToView && (
        <h1 className={styles.header}>{audienceToView.name}</h1>
      )}

      <div className={styles["audience-records"]}>
        <div className={styles["edit-button-container"]}>
          <Button
            onClick={editAudience}
            variant="contained"
          >
            Edit
          </Button>
        </div>
        <AudienceRecordTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          recordLimit={recordLimit}
          setRecordLimit={setRecordLimit}
          recordOffset={recordOffset}
          setRecordOffset={setRecordOffset}
          count={totalAudiences}
          tableColumns={newColumns} 
          tableRows={queryRecords} 
        />
        <p
          style={{
            display: loading || isPending ? "block" : "none",
            textAlign: "center",
          }}
        >
          <i className="fa fa-spinner fa-pulse"></i> Processing Audience...
        </p>
      </div>
    </>
  );
};

export default AudienceSelectionRecord;
