import React from 'react'
import styles from './customButton.module.scss'

const CustomButton = ({ children }) => {
  return (
    <button className={styles.button}>
        {children}
    </button>
  )
}

export default CustomButton