import React, { useState, useEffect, useTransition } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getControls, getIndividuals, getLatestIndividuals } from "./individual.service";
import Button from "@material-ui/core/Button";
import IndividualSearchResults from "./Individual-search-results/IndividualSearchResults";
import IndividualDetails from "./Individual-details/IndividualDetails";
import { setIndividualSearchObj } from "../../redux/individual-page/individual-page.reducer";
import styles from "./individual.module.scss";
import ApiNew from "../../common/api/apinew.service";

const friendlyColumnName = (key) => {
  return key
    .toString()
    .replaceAll("_", " ") //remove _ with spaces
    .split(" ") //split the word so we can get sub-words
    .join(" "); //rejoin words with spaces between
};

const Individual = () => {
  const dispatch = useDispatch();
  const { api } = ApiNew();

  const [isPending, startTransition] = useTransition();
  const individualSearchObj = useSelector(
    ({ individualPage: { individualSearchObj } }) => individualSearchObj
  );
  
  const [expandResults, setExpandResults] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedIndividual, setSelectedIndividual] = useState();
  const [pageControls, setPageControls] = useState();


  useEffect(() => {
    (async () => {
      if(api && !individualSearchObj){
        await fetchIndividuals(() => getLatestIndividuals(api))
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api])


  useEffect(() => {
    return () => {
      dispatch(setIndividualSearchObj(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!individualSearchObj) {
      const key = Object.keys(individualSearchObj)[0]
      const searchValue = individualSearchObj[key]

      if(searchValue.trim() === ''){
        fetchIndividuals(() => getLatestIndividuals(api))
      } else {
        fetchIndividuals(() => getIndividuals(individualSearchObj, api))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualSearchObj]);
  
  const fetchIndividuals = async (serviceFunction) => {
    setSearchResults([])
    setExpandResults(true);
    setLoading(true);
    setHasError(false);

    const individualsResponse = await serviceFunction()

    if (individualsResponse.status === 200) {
      if(!!individualsResponse.data.length){
        startTransition(() => {
          setSearchResults(individualsResponse.data);
        });
      } else {
        const key = Object.keys(individualSearchObj)[0]
        const searchValue = individualSearchObj[key]

        setHasError(true)
        setErrorMessage(`No individual with ${friendlyColumnName(key)}: ${searchValue}`)
      }
    } else {
      setHasError(true);
      setErrorMessage('Error whilst searching. Please contact your system administrator')
    }

    setLoading(false);
  }

  const toggleResults = () => {

    setExpandResults(!expandResults);
  };

  let handleRowSelect = async (row) => {
    setSelectedIndividual(row.original);

    const pageControlsData = await getControls("Customers", api);

    setPageControls(pageControlsData);
    setExpandResults(false);
  };

  return (
    <div className={styles["individual-container"]}>
      <div className={styles["expand-btn-container"]}>
        <Button
          onClick={toggleResults}
          className={styles["expand-btn"]}
          size="small"
          variant="contained"
        >
          <span className="material-symbols-outlined">
            expand_{expandResults ? "less" : "more"}
          </span>
        </Button>
      </div>
      <div>
        <IndividualSearchResults
          expand={expandResults}
          loading={loading || isPending}
          searchResults={searchResults}
          hasError={hasError}
          errorMessage={errorMessage}
          handleRowSelect={handleRowSelect}
        />
      </div>

      {selectedIndividual ? (
        <IndividualDetails
          selectedIndividual={selectedIndividual}
          pageControls={pageControls}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Individual;
