import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Group from "./group/Group";
import { defaultGroup } from "./group/defaultGroup";
import operators2 from "./constants/operators2.json";
import styles from "./xcmAudienceSelector.module.scss";

const XCMAudienceSelector = ({ fieldGroups, defaultQuery, onQueryChange }) => {
  const [componentData, setComponentData] = useState();

  useEffect(() => {
    setComponentData({ ...defaultQuery });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultQuery]);

  useEffect(() => {
    if (componentData) {
      onQueryChange(componentData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentData]);

  const setNewRule = (obj, id) => {
    const fieldGroupsItems = fieldGroups?.filter((x) => x.isHeader);
    const fieldsItems = fieldGroups?.filter((x) => !x.isHeader);

    const firstFieldGroup = fieldGroupsItems ? fieldGroupsItems[0] : "";
    const firstField = fieldsItems ? fieldsItems[0] : "";

    let defaultOperator = [...operators2][0];

    if (firstField === undefined) {
      return;
    }

    if (firstField.operators?.length) {
      defaultOperator = firstField?.operators[0];
    }

    let defaultRule = () => ({
      id: uuidv4(),
      fieldGroup: firstFieldGroup.fieldGroup,
      field: firstField.name, //this could be a mess at some point!!!
      value: firstField.defaultValue,
      operator: defaultOperator,
      hasRelativeDate: firstField.hasRelativeDate ? true : false,
    });

    let objects = [];

    if (obj.id === id) {
      obj.rules.push(defaultRule());
    } else {
      for (let i in obj.rules) {
        let rule = obj.rules[i];

        if (rule.id === id) {
          rule.rules.push(defaultRule());
        } else if (rule.rules?.length > 0) {
          objects = objects.concat(setNewRule(rule, id));
        }
      }
    }

    return obj;
  };

  const setDeleteRule = (obj, id) => {
    let objects = [];

    for (let i in obj.rules) {
      let rule = obj.rules[i];

      if (rule.id === id) {
        delete obj.rules[i];
      } else if (rule.rules?.length > 0) {
        objects = objects.concat(setDeleteRule(rule, id));
      }
    }

    return obj;
  };

  const setNewGroup = (obj, id) => {
    let objects = [];

    if (obj.id === id) {
      obj.rules.push(defaultGroup());
    } else {
      for (let i in obj.rules) {
        let rule = obj.rules[i];

        if (rule.id === id) {
          rule.rules.push(defaultGroup());
        } else if (rule.rules?.length > 0) {
          objects = objects.concat(setNewGroup(rule, id));
        }
      }
    }

    return obj;
  };

  const setDeleteGroup = (obj, id) => {
    let objects = [];

    for (let i in obj.rules) {
      let rule = obj.rules[i];

      if (rule.id === id) {
        delete obj.rules[i];
      } else if (rule.rules?.length > 0) {
        objects = objects.concat(setDeleteGroup(rule, id));
      }
    }

    return obj;
  };

  const setCombinator = (obj, id, newCombinatorValue) => {
    let objects = [];

    if (obj.id === id) {
      obj.combinator = newCombinatorValue;
    } else {
      for (let i in obj.rules) {
        let rule = obj.rules[i];

        if (rule.id === id) {
          rule.combinator = newCombinatorValue;
        } else if (rule.rules) {
          objects = objects.concat(setCombinator(rule, id, newCombinatorValue));
        }
      }
    }

    return obj;
  };

  const setFieldGroup = (obj, id, newVal) => {
    let newValue = newVal;
    let objects = [];

    for (let i in obj) {
      if (obj[i].id === id) {
        obj[i].fieldGroup = newValue;
      } else if (obj[i].rules?.length > 0) {
        objects = objects.concat(setFieldGroup(obj[i].rules, id, newValue));
      }
    }

    return obj;
  };

  const setField = (obj, id, newVal) => {
    let newValue = newVal;
    let objects = [];

    for (let i in obj) {
      if (obj[i].id === id) {
        obj[i].field = newValue;
      } else if (obj[i].rules?.length > 0) {
        objects = objects.concat(setField(obj[i].rules, id, newValue));
      }
    }

    return obj;
  };

  const setHasRelativeDate = (obj, id, newVal) => {
    let newValue = newVal;
    let objects = [];

    for (let i in obj) {
      if (obj[i].id === id) {
        obj[i].hasRelativeDate = newValue;
      } else if (obj[i].rules?.length > 0) {
        objects = objects.concat(
          setHasRelativeDate(obj[i].rules, id, newValue)
        );
      }
    }

    return obj;
  };

  const setOperator = (obj, id, newVal) => {
    let newValue = newVal;
    let objects = [];

    for (let i in obj) {
      if (obj[i].id === id) {
        obj[i].operator = newValue;
      } else if (obj[i].rules?.length > 0) {
        objects = objects.concat(setOperator(obj[i].rules, id, newValue));
      }
    }

    return obj;
  };

  const setValue = (obj, id, newVal) => {
    let newValue = newVal;
    let objects = [];

    for (let i in obj) {
      if (obj[i].id === id) {
        obj[i].value = newValue;
      } else if (obj[i].rules?.length > 0) {
        objects = objects.concat(setValue(obj[i].rules, id, newValue));
      }
    }

    return obj;
  };

  const addRuleClick = (id) => {
    let componentDataItems = { ...componentData };
    let objectReturned = setNewRule(componentDataItems, id);

    componentDataItems = objectReturned;

    setComponentData(componentDataItems);
  };

  const addGroupClick = (id) => {
    let componentDataItems = { ...componentData };
    let objectReturned = setNewGroup(componentDataItems, id);

    componentDataItems = objectReturned;

    setComponentData(componentDataItems);
  };

  const onFieldChange = (id, e) => {
    let componentDataItems = { ...componentData };

    let objectReturned = setField(componentDataItems.rules, id, e.target.value);

    componentDataItems.rules = objectReturned;

    setComponentData(componentDataItems);

    let field = fieldGroups.find((x) => x.name === e.target.value);

    if (field === null) {
      return;
    }

    if (field?.operators?.length) {
      changeOperator(id, field.operators[0]);
    }

    setValue(componentDataItems.rules, id, field.defaultValue ?? "");
  };

  const onHasRelativeDateChange = (id, e) => {
    let componentDataItems = { ...componentData };

    let objectReturned = setHasRelativeDate(
      componentDataItems.rules,
      id,
      e.target.checked
    );

    componentDataItems.rules = objectReturned;
    setComponentData(componentDataItems);
  };

  const onFieldGroupChange = async (id, e) => {
    let componentDataItems = { ...componentData };

    let objectReturned = setFieldGroup(
      componentDataItems.rules,
      id,
      e.target.value
    );

    const newFieldValue = fieldGroups.find(
      (fieldGroup) => fieldGroup.name === e.target.value
    );

    objectReturned = setField(
      componentDataItems.rules,
      id,
      newFieldValue.fields[0].name
    );

    componentDataItems.rules = objectReturned;

    setComponentData(componentDataItems);
  };

  const changeOperator = (id, value) => {
    let componentDataItems = { ...componentData };

    let objectReturned = setOperator(componentDataItems.rules, id, value);

    componentDataItems.rules = objectReturned;

    setComponentData(componentDataItems);
  };

  const onOperatorChange = (id, e) => {
    const newValue = e.target.value;

    changeOperator(id, newValue);
  };

  const onValueChange = (id, e) => {
    e.preventDefault();

    let newValue = e.target.value;

    let componentDataItems = { ...componentData };

    let objectReturned = setValue(componentDataItems.rules, id, newValue);

    componentDataItems.rules = objectReturned;

    setComponentData(componentDataItems);
  };

  const setRuleDataValue = (id, newValue) => {
    let componentDataItems = { ...componentData };

    let objectReturned = setValue(componentDataItems.rules, id, newValue);

    componentDataItems.rules = objectReturned;

    setComponentData(componentDataItems);
  };

  const onBetweenValueChange = (id, newValue) => {
    let componentDataItems = { ...componentData };

    let objectReturned = setValue(componentDataItems.rules, id, newValue);

    componentDataItems.rules = objectReturned;

    setComponentData(componentDataItems);
  };

  const onSearchableMultiSelectValueChange = (id, newValue) => {
    let componentDataItems = { ...componentData };

    let objectReturned = setValue(componentDataItems.rules, id, newValue);

    componentDataItems.rules = objectReturned;

    setComponentData(componentDataItems);
  };

  const onCombinatorChange = (id, e) => {
    let componentDataItems = { ...componentData };

    let objectReturned = setCombinator(componentDataItems, id, e.target.value);

    componentDataItems = objectReturned;

    setComponentData(componentDataItems);
  };

  const deleteRuleClick = (id) => {
    let componentDataItems = { ...componentData };

    let objectReturned = setDeleteRule(componentDataItems, id);

    componentDataItems = objectReturned;
    componentDataItems = removeNulls();

    setComponentData(componentDataItems);
  };

  const deleteGroupClick = (id) => {
    let componentDataItems = { ...componentData };

    let objectReturned = setDeleteGroup(componentDataItems, id);

    componentDataItems = objectReturned;
    componentDataItems = removeNulls();

    setComponentData(componentDataItems);
  };

  const setRemoveNulls = (obj) => {
    let rulesNoNulls = obj.rules.filter((x) => x !== null);

    for (let i in rulesNoNulls) {
      if (rulesNoNulls[i].rules) {
        setRemoveNulls(rulesNoNulls[i]);
      }
    }

    obj.rules = rulesNoNulls;

    return obj;
  };

  //bug fix.
  const removeNulls = () => {
    let componentDataItems = { ...componentData };

    const removedNulls = setRemoveNulls(componentDataItems);

    return removedNulls;
  };

  const submitSelection = () => {
    const componentDataItems = [...componentData];

    let queryString = "";

    if (componentDataItems.rules?.length === 0) {
      return;
    }

    const combinator = componentDataItems.rules.join(
      ` ${componentDataItems.combinator} `
    );

    queryString += combinator;

    setComponentData(queryString);
  };

  const onSelectedFieldChange = (id, value) => {
    const name = value.name;
    const fieldGroup = value.fieldGroup;
    let operator = operators2[0];

    let componentDataItems = { ...componentData };

    let objectReturned = setField(componentDataItems.rules, id, name);
    objectReturned = setFieldGroup(componentDataItems.rules, id, fieldGroup);

    let field = fieldGroups.find((x) => x.name === name);

    if (field === null) {
      return;
    }

    if (field.operators) {
      operator = field.operators[0];
    }

    objectReturned = setOperator(componentDataItems.rules, id, operator);
    objectReturned = setValue(componentDataItems.rules, id, field.defaultValue ?? "");

    componentDataItems.rules = objectReturned;
    setComponentData(componentDataItems);
  };

  return (
    <>
      {componentData && !!fieldGroups.length ? (
        <div className={styles["xcm-query-builder-container"]}>
          <Group
            fieldGroups={fieldGroups}
            groupData={componentData}
            onFieldGroupChange={onFieldGroupChange}
            onFieldChange={onFieldChange}
            onHasRelativeDateChange={onHasRelativeDateChange}
            onSelectedFieldChange={onSelectedFieldChange}
            onOperatorChange={onOperatorChange}
            onValueChange={onValueChange}
            setRuleDataValue={setRuleDataValue}
            onBetweenValueChange={onBetweenValueChange}
            onSearchableMultiSelectValueChange={
              onSearchableMultiSelectValueChange
            }
            onCombinatorChange={onCombinatorChange}
            addRuleClick={addRuleClick}
            deleteRuleClick={deleteRuleClick}
            addGroupClick={addGroupClick}
            deleteGroupClick={deleteGroupClick}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default XCMAudienceSelector;
