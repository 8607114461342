import React from 'react'
import CustomCheckbox from '../../../../../common/components/custom-checkbox/CustomCheckbox'
import styles from './fieldsListItem.module.scss'

const FieldsListItem = ({ item, handleChecked }) => {
  return (
    <div className={styles["list-item-container"]}>
        <CustomCheckbox onChange={() => handleChecked(item.id)} checked={item.checked} id={`item${item.id}`} />
        <label htmlFor={`item${item.id}`}>{item.label}</label>
    </div>
  )
}

export default FieldsListItem