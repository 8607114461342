import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    audienceToViewId: null,
    selectionTablePageToRestore: 0,
    audienceToEdit: null
}

const audienceSelector = createSlice({
    name: 'audienceSelector',
    initialState: INITIAL_STATE,
    reducers: {
        setAudienceToView: (state, action) => {
            state.audienceToViewId = action.payload
        },
        setSelectionTablePageToRestore: (state, action) => {
            state.selectionTablePageToRestore = action.payload
        },
        setAudienceToEdit: (state, action) => {
            state.audienceToEdit = action.payload
        }
    }
})

export const { setAudienceToView, setSelectionTablePageToRestore, setAudienceToEdit } = audienceSelector.actions
export default audienceSelector.reducer