import React from "react";
import styles from './detailCardBtn.module.scss'

const DetailCardBtn = ({ detail, selectDetail, selectedItem }) => {
  const isSelected = detail["Order Line No"] === selectedItem;

  return (
    <div
    className={styles["container"]}
    onClick={() => selectDetail(detail)}
    style={{
      backgroundColor: isSelected ? "#eee" : "transparent",
    }}
    >
        <div>SKU: {detail["Product Key"]}</div>
        <div>{detail["Product Description"]}</div>
    </div>
  );
};

export default DetailCardBtn;
