import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import SearchMultiSelectPopover from "./search-multi-select-popover/SearchMultiSelectPopover";
import uuid from "react-uuid";
import styles from "./searchableMultiSelect.module.scss";

const SearchableMultiSelect = ({
  id,
  selectedValues,
  field,
  onSearchableMultiSelectValueChange,
}) => {
  const [search, setSearch] = useState("");
  const [selectableItems, setSelectableItems] = useState([]);
  const [filteredSelectableItems, setFilteredSelectableItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showSelections, setShowSelections] = useState(false);

  useEffect(() => {
    if (field === null || field?.availableValues?.length === 0) {
      setSelectableItems([]);
    }

    if (!!selectedValues.length) {
      setSelectedItems(selectedValues.split(", "));
    }

    const availableValues = JSON.parse(field.availableValues);
    const items = availableValues.map((item) => {
      return {
        id: uuid(),
        name: item.audience_name
      }
    })
    setSelectableItems([...items]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  useEffect(() => {
    setFilteredSelectableItems(
      selectableItems.filter((item) =>
        item?.name?.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, selectableItems]);

  const checkBoxOnChange = (e, item) => {
    let selectedFilterItems = [...selectedItems];

    if (!selectedFilterItems.includes(item)) {
      selectedFilterItems.push(item);
    } else {
      selectedFilterItems = selectedFilterItems.filter((x) => x !== item);
    }

    setSelectedItems(selectedFilterItems);

    let selectedFilterItemsValue = selectedFilterItems.join(", ");

    onSearchableMultiSelectValueChange(id, selectedFilterItemsValue);
  };

  return (
    <>
      <div className={styles["multi-select-container"]}>
        <Button
          className={styles.button}
          onClick={() => setShowSelections(!showSelections)}
        >
          Show Selections...
        </Button>
      </div>
      {
        showSelections ? (
          <SearchMultiSelectPopover
            searchValue={search}
            close={() => setShowSelections(false)}
            onSearchChange={(e) => setSearch(e.target.value)}
            filteredSelectableItems={filteredSelectableItems}
            selectedItems={selectedItems}
            checkBoxOnChange={(e, item) => checkBoxOnChange(e, item)}
          />
        ) : (
          <></>
        )
      }
    </>
  );
};

export default SearchableMultiSelect;
