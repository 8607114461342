import React from "react";
import uuid from 'react-uuid'
import ProfilesCard from "./profiles-card/ProfilesCard";

const ProfilesCards = ({ profiles }) => {
  return profiles.map((profile) => {
    return <ProfilesCard key={uuid()} profile={profile} />;
  });
};

export default ProfilesCards;