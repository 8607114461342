import React, { useMemo } from 'react'
import styles from './broadcastMetrics.module.scss'
import LabelValuePair from './label-value-pair/LabelValuePair'

const BroadcastMetrics = ({ bcDetails }) => {
  
  const metaDataString = useMemo(() => {
    try{
      const metaData = JSON.parse(bcDetails["MetaData"])
      if(!!metaData.length){
        return metaData.join(', ')
      } else {
        return 'none'
      }
    } catch (err) {
      return 'none'
    }
  }, [bcDetails])

  const getSendDuration = useMemo(() => {
    //duration is in seconds
    const duration = Number(bcDetails["SendDuration"])
    
    const hoursDecimal = duration / 60 / 60
    const hours = Math.floor(hoursDecimal)

    const minutesDecimal = hoursDecimal - hours
    const minutes = Math.floor(minutesDecimal * 60)

    const secondsDecimal = (duration / 60) - Math.floor(duration / 60)
    const seconds = Math.round(secondsDecimal * 60)
    
    const checkNumber = (number) => number === 1 ? '' : 's'
    
    return `${hours} hr${checkNumber(hours)} ${minutes} min${checkNumber(minutes)} ${seconds} sec${checkNumber(seconds)}`
  }, [bcDetails])
  
  const AddAudienceIds = () => {
    // we are converting string into array without using JSON.parse()
    let LocalArray = bcDetails["Audience_IDs"].split(', ');
    LocalArray.forEach(element => {
      element = parseInt(element)
    });
    return LocalArray
  }

  return (
    <div className={styles["details-container"]}>
      <hr className={styles['line']} />

      <div className={styles['metrics-container']}>
        <div className={styles['row']}>
          <LabelValuePair label="Broadcast campaign ID" value={bcDetails["BroadcastCampaignID"]} hasVerticalLine/>
          <LabelValuePair label="Channel" value={bcDetails["Channel"]} hasVerticalLine/>
          <LabelValuePair label="Message" value={bcDetails["Message"]}/>
        </div>

        <hr className={styles['line']} />

        <div className={styles['row']}>
          <LabelValuePair label="Audience IDs" value={AddAudienceIds()} hasVerticalLine/>
          <LabelValuePair label="MetaData" value={metaDataString} hasVerticalLine/>
          <LabelValuePair label="Schedule Date" value={new Date(bcDetails["ScheduleDate"]).toLocaleString().replace(",", "")}/>
        </div>

        <hr className={styles['line']} />

        <div className={styles['row']}>
          <LabelValuePair label="Total to send" value={bcDetails["Total To Send"]} hasVerticalLine/>
          <LabelValuePair label="Total sent" value={bcDetails["Total Sent"]} hasVerticalLine/>
          <LabelValuePair label="Total errored" value={bcDetails["Total Errored"]}/>
        </div>

        <hr className={styles['line']} />

        {/* Shows 'NaNhrs : NaNmins : NaNsecs' in staging environment, I'll look into it next week */}
        {
          !!bcDetails["SendDuration"] && (
            <>
              <div className={styles['row']}>
                <LabelValuePair label="Send Duration" value={getSendDuration} hasVerticalLine/>
              </div>
              <hr className={styles['line']} />
            </>
          )
        }
      </div>
    </div>
  )
}

export default BroadcastMetrics