import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  pageErrorOccured: false,
  errorCode: 404,
  errorMessage: "",
};

const errorPageSlice = createSlice({
  name: "errorPage",
  initialState: INITIAL_STATE,
  reducers: {
    setPageErrorOccured: (state, action) => {
      state.pageErrorOccured = action.payload;
    },
    setErrorCode: (state, action) => {
      state.errorCode = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    resetErrorPageState: () => {
      return {
        ...INITIAL_STATE,
      };
    },
  },
});

export const {
  setPageErrorOccured,
  setErrorCode,
  setErrorMessage,
  resetErrorPageState,
} = errorPageSlice.actions;
export default errorPageSlice.reducer;
