import React, { useState, useEffect } from "react";
import RightSection from "../../components/right-section/RightSection";
import SideNav from "../../components/side-nav/SideNav";
import ApiNew from "../../common/api/apinew.service";
import SideNavMobile from "../../components/side-nav-mobile/SideNavMobile";
import { useIsAuthenticated } from "@azure/msal-react";
import { getMenuItems } from "../../components/side-nav/sideNav.service";
import styles from "./master.module.scss";

const Master = () => {
  const { api } = ApiNew();
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [showMobileSideNav, setShowMobieSideNav] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    (async () => {
      if (isAuthenticated && api) {
        const menuItemsData = await getMenuItems(api);
        setMenuItems(menuItemsData);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, api]);

  useEffect(() => {
    setShowMobieSideNav(screenSize < 800);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize]);

  let handleScreenSizeChange = () => {
    setScreenSize(window.innerWidth);
  };

  window.onresize = handleScreenSizeChange;

  return (
    <div className={styles.master}>
      {showMobileSideNav ? (
        <SideNavMobile
          menuItems={menuItems}
          showFullSideNav={() => setShowMobieSideNav(false)}
        />
      ) : (
        <SideNav
          menuItems={menuItems}
          setShowMobileSideNav={() => setShowMobieSideNav(true)}
        />
      )}

      <RightSection />
    </div>
  );
};

export default Master;
