export const getMenuItems = async (api) => {
  try {
    let response = await api.get(
      `SystemManagement/Navigation/GetNavigationItemsFigma/v2`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return []
  }
};
