import React from 'react'
import styles from './formFieldErrorText.module.scss'

const FormFieldErrorText = ({ text, showText }) => {
  if(showText){
    return <p className={styles['error-text']}>{text}</p>
  } else {
    return <></>
  }
}

export default FormFieldErrorText