import React from "react";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import styles from "./communicationTableRowStatus.module.scss";

const CommunicationTableRowStatus = ({
  row,
  rowClickAction,
  approveBtnClickAction,
  declineBtnClickAction,
}) => {
  const canApproveCampaignSends = useSelector(
    ({ user: { canApproveCampaignSends } }) => canApproveCampaignSends
  );

  return canApproveCampaignSends &&
    row.original.Status.toString().toLowerCase().includes("pending") ? (
    <td className={styles.td}>
      <div className={styles["btn-container"]}>
        <Button
          className={styles["approve-btn"]}
          onClick={() => approveBtnClickAction(row)}
          size="small"
        >
          Approve
        </Button>
        <Button
          className={styles["decline-btn"]}
          onClick={() => declineBtnClickAction(row)}
          size="small"
        >
          Decline
        </Button>
      </div>
    </td>
  ) : row.original.Status.toString().toLowerCase().includes("pending") ? (
    <td onClick={() => rowClickAction(row)} className={styles.td}>
      <p className={styles["pending-text"]}>Pending Approval</p>
    </td>
  ) : row.original.Status?.toLowerCase().includes("scheduled") ? (
    <td onClick={() => rowClickAction(row)} className={styles.td}>
      <p className={styles["sending-scheduled-text"]}>Scheduled</p>
    </td>
  ) : row.original.Status?.toLowerCase().includes("sending") ? (
    <td onClick={() => rowClickAction(row)} className={styles.td}>
      <p className={styles["sending-scheduled-text"]}>Sending</p>
    </td>
  ) : row.original.Status?.toLowerCase().includes("declined") ? (
    <td onClick={() => rowClickAction(row)} className={styles.td}>
      <p className={styles["declined-text"]}>Declined</p>
    </td>
  ) : row.original.Status?.toLowerCase().includes("sent") ? (
    <td onClick={() => rowClickAction(row)} className={styles.td}>
      <p className={styles["sent-text"]}>Sent</p>
    </td>
  ) : (
    <td onClick={() => rowClickAction(row)} className={styles.td}>
      <p className={styles["pending-text"]}>{row.original.Status}</p>
    </td>
  );
};

export default CommunicationTableRowStatus;
