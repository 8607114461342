

export const getRoutes = async (api) => {
  const response = await api
    .get(`SystemManagement/Routes/GetRoutesFigma/v2/`)
    .then((res) => res)
    .catch((err) => err);

  return response;
};
