import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
  canApproveCampaignSends: false
}

const user = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    setCanApproveCampaignSends: (state, action) => {
      state.canApproveCampaignSends = action.payload
    }
  }
})


export const { setCanApproveCampaignSends } = user.actions

export default user.reducer