import React from "react";
import { Outlet } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import TopNav from "./top-nav/TopNav";
import styles from "./rightSection.module.scss";

const RightSection = () => {
  return (
    <div className={styles["right-section"]}>
      <TopNav />

      <div className={styles["master-body"]}>
        <AuthenticatedTemplate>
          <Outlet />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <p data-testid='sign-in-text'>Welcome to Horizon Online! Please sign in on the top right.</p>
        </UnauthenticatedTemplate>
      </div>
    </div>
  );
};

export default RightSection;
