import React from 'react'
import BroadcastDetails from '../../../components/adhoc-communication/broadcast-details/BroadcastDetails'
import styles from './broadcastDetailsPage.module.scss'

const BroadcastDetailsPage = () => {
  return (
    <div className={styles['container']}>
      <BroadcastDetails />
    </div>
  )
}

export default BroadcastDetailsPage