import React, { useMemo } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import styles from "./audienceRecordTable.module.scss"

const AudienceRecordTable = ({
  currentPage,
  setCurrentPage,
  recordLimit,
  setRecordLimit,
  setRecordOffset,
  recordOffset,
  count,
  tableColumns,
  tableRows,
}) => {
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    setPageSize,
    state,
    prepareRow,
  } = useTable(
    { columns: tableColumns, data: tableRows, initialState: { pageSize: recordLimit } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageSize } = state;

  let handlePageSizeChange = (size) => {
    setPageSize(size)
    setRecordLimit(size)
  }
  
  const totalPage = useMemo(() => Math.ceil(count/recordLimit), [count, recordLimit])

  let handlePageChange = (pageString) => {
    switch(pageString){
      case 'next':
        setCurrentPage(currentPage + 1)
        setRecordOffset(recordOffset + recordLimit)
        break;

      case 'previous':
        setCurrentPage(currentPage - 1)
        setRecordOffset(recordOffset - recordLimit)
        break;

      case 'last':
        setCurrentPage(totalPage)
        setRecordOffset(count - recordLimit)
        break;

      case 'first':
        setCurrentPage(1)
        setRecordOffset(0)
        break;

      default:
        break;
    }
  }

  return (
    <div className={styles["table-n-pagination"]}>
      <div className={styles["table-container"]}>
        <div className={styles["selection-table-container"]}>
          <table className={styles.table} {...getTableProps()}>
            <thead className={styles.thead}>
              {headerGroups.map((headerGroup) => (
                <tr
                  className={styles.tr}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      className={styles.th}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className="material-icons-outlined">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "south"
                            : "north"
                          : ""}
                      </span>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className={styles.tbody} {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    className={styles.tr}
                    {...row.getRowProps()}
                    data-testid={`audiences-table-row-${row.original.id}`}
                  >
                    {row.cells.map((cell) => (
                      <td
                        className={styles.td}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {tableRows.length ? (
        <div className={styles["pagination-container"]}>
          <span className={styles["select-container"]}>
            Rows per page:
            <select
              className={styles.select}
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
            >
              {[10, 20, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </span>
          <span>
            <span>
              {currentPage} of {totalPage}
            </span>
            &nbsp;
          </span>
          <span className={styles["button-container"]}>
            <button onClick={() => handlePageChange('first')} disabled={currentPage === 1}>
              <span className="material-icons-outlined">first_page</span>
            </button>
            <button
              onClick={() => handlePageChange('previous')}
              disabled={currentPage === 1}
            >
              <span className="material-icons-outlined">chevron_left</span>
            </button>
            <button
              onClick={() => handlePageChange('next')}
              disabled={currentPage === totalPage}
            >
              <span className="material-icons-outlined">chevron_right</span>
            </button>
            <button
              onClick={() => handlePageChange('last')}
              disabled={currentPage === totalPage}
            >
              <span className="material-icons-outlined">last_page</span>
            </button>
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AudienceRecordTable;
