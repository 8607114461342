// // import Api from "../../common/api/api.service";
// import axios from "axios";

// // const { api } = Api();

// const createOptions = (authToken) => {
//   return {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${authToken}`,
//     },
//   };
// };

// export const getProperties = async (tableName, authToken) => {
//   try {
//     const response = await axios.get(
//       "https://localhost:44367/MasterDataManagement/TableProperties/" +
//         tableName,
//       createOptions(authToken)
//     );

//     return response.data;
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const getData = async (tableName, authToken) => {
//   try {
//     const response = await axios.get(
//       "https://localhost:44367/MasterDataManagement/TableData/" + tableName,
//       createOptions(authToken)
//     );
//     return response.data;
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const postData = async (data, authToken) => {
//   try {
//     const response = await axios.post(
//       "https://localhost:44367/MasterDataManagement/PostChanges",
//       data,
//       createOptions(authToken)
//     );
//     return response.data;
//   } catch (err) {
//     console.log(err);
//   }
// };

export const getProperties = async (tableName, api) => {
  try {
    const response = await api.get(
      `MasterDataManagement/TableProperties/${tableName}`
    );

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getData = async (tableName, api) => {
  try {
    const response = await api.get(
      `MasterDataManagement/TableData/${tableName}`
    );

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const postData = async (data, api) => {
  try {
    const response = await api.post("MasterDataManagement/PostChanges", data);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
