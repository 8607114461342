import React from 'react'
import styles from './calenderSelect.module.scss'

const CalenderSelect = ({ relativeDateList, ruleData, onValueChange }) => {

    return (
        <div className={styles["container"]}>
            <select value={ruleData.value} onChange={(e) => onValueChange(ruleData.id, e)} className={styles["select"]}>
                <option value="" hidden>
                    select relative date...
                </option>
                {relativeDateList.map(date => <option value={date.name} key={date.name}>{date.friendlyName}</option>)}
            </select>
        </div>
    )
}

export default CalenderSelect