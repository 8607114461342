import React from 'react'
import styles from './individualPreferences.module.scss'
import uuid from 'react-uuid'

const IndividualPreferences = ({ controlData }) => {
  return (
    <div className={styles['container']}>
      <table className={styles['table']}>
        <tbody className={styles['tbody']}>
          {controlData.map(control => 
            <tr className={styles['tr']} key={uuid()}>
              <td>{control.rows}</td>
              <td>{control.tooltip}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default IndividualPreferences