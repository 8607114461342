import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setTopNavSearchValue } from "../../../redux/top-nav-search/top-nav-search.reducer";
import { setIndividualSearchObj } from "../../../redux/individual-page/individual-page.reducer";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import AdvanceSearch from "./advance-search/AdvanceSearch";
import CustomModal from "../../../common/components/custom-modal/CustomModal";
import IndividualQuickSearch from "./individual-quick-search/IndividualQuickSearch";
import QuickSearchData from "./individual-quick-search/QuickSearchOptions.json";
import SignInButton from "./sign-in-button/SignInButton";
import ReactTooltip from 'react-tooltip'
import styles from "./topNav.module.scss";

const TopNav = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const name = accounts[0] && accounts[0].name;

  const [searchValue, setSearchValue] = useState("");
  const [selectedSearchCriteria, setSelectedSearchCriteria] = useState(
    QuickSearchData[0].name
  );
  const [openSearchCriteriaModal, setOpenSearchCriteriaModal] = useState(false);
  const [openAdvanceSearch, setOpenAdvanceSearch] = useState(false);

  const isIndividualPage = useMemo(() => {
    return location.pathname === "/customers";
  }, [location]);

  let searchInputOnChange = (event) => {
    setSearchValue(event.target.value);

    if (!isIndividualPage) {
      dispatch(setTopNavSearchValue(event.target.value));
    }
  };

  let searchInputOnKeyUp = (event) => {
    if (event.key === "Enter") {
      if (isIndividualPage) {
        const obj = {
          [selectedSearchCriteria]: searchValue,
        };

        dispatch(setIndividualSearchObj(obj));
      }
    }
  };

  let searchCriteriaSaveOnClick = (searchCriteriaSelected) => {
    setSelectedSearchCriteria(searchCriteriaSelected);
    setOpenSearchCriteriaModal(false);
  };

  let onAdvanceSearch = (searchItems) => {
    dispatch(setIndividualSearchObj(searchItems));
    setOpenAdvanceSearch(false);
  };

  let onSearchIconClick = () => {
    if (isIndividualPage) {
      const obj = {
        [selectedSearchCriteria]: searchValue,
      };

      dispatch(setIndividualSearchObj(obj));
    }
  };

  const selectedSearchCriteriaLabel = useMemo(() => {
    return QuickSearchData.find((item) => item.name === selectedSearchCriteria).label;
  }, [selectedSearchCriteria]);

  return (
    <div className={styles["top-nav"]}>
      {isAuthenticated ? (
        <React.Fragment>
          <div className={styles["input-n-user-icon"]}>
            <div className={styles["input-right"]}>
              <span className={styles["search-icon-container"]}>
                <span
                  className={`material-symbols-outlined ${styles["search-icon"]}`}
                  onClick={onSearchIconClick}
                >
                  search
                </span>
              </span>
              {isIndividualPage ? (
                <input
                  dir="auto"
                  className={styles["top-nav-search-input"]}
                  onChange={searchInputOnChange}
                  onKeyUp={searchInputOnKeyUp}
                  placeholder={`Search for ${selectedSearchCriteriaLabel}...`}
                />
              ) : (
                <input
                  dir="auto"
                  className={styles["top-nav-search-input"]}
                  onChange={searchInputOnChange}
                  onKeyUp={searchInputOnKeyUp}
                  placeholder="Search..."
                />
              )}
              {isIndividualPage ? (
                <>
                  <span className={styles["search-criteria-icon-container"]}>
                    <span
                      data-for="set-search-criteria"
                      data-tip="Set search criteria"
                      onClick={() => setOpenSearchCriteriaModal(true)}
                      className={`material-symbols-outlined ${styles["search-criteria-icon"]}`}
                    >
                      menu
                    </span>
                  </span>
                  <span
                    data-for="advance-search"
                    data-tip="Advance search"
                    onClick={() => setOpenAdvanceSearch(true)}
                    style={{ color: "#ffffff", cursor: "pointer" }}
                    className={`material-symbols-outlined ${styles["search-criteria-icon-container"]}`}
                  >
                    science
                  </span>
                  <ReactTooltip effect="solid" place="bottom" id='set-search-criteria' backgroundColor="#343D49" />
                  <ReactTooltip effect="solid" place="bottom" id='advance-search' backgroundColor="#343D49" />
                </>
              ) : (
                ""
              )}
            </div>
            <div className={styles["right"]}>
              {
                <img
                  className={styles["user-icon"]}
                  alt="user-icon"
                  src={`https://eu.ui-avatars.com/api?name=${name}&rounded=true&size=36`}
                ></img>
              }
            </div>
          </div>

          <CustomModal
            isOpen={openSearchCriteriaModal}
            closeModal={() => setOpenSearchCriteriaModal(false)}
          >
            <IndividualQuickSearch
              searchCriteriaSaveOnClick={searchCriteriaSaveOnClick}
              selectedSearchCriteria={selectedSearchCriteria}
            />
          </CustomModal>

          <CustomModal
            isOpen={openAdvanceSearch}
            closeModal={() => setOpenAdvanceSearch(false)}
          >
            <AdvanceSearch doSearch={onAdvanceSearch} />
          </CustomModal>
        </React.Fragment>
      ) : !isAuthenticated ? (
        <SignInButton />
      ) : (
        <></>
      )}
    </div>
  );
};

export default TopNav;
