import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import useTriggerEmailModal from './useTriggerEmailModal'
import AudienceSelect from '../common/audience-select/AudienceSelect'
import styles from './triggeredEmailModal.module.scss'
import FormFieldErrorText from './form-field-error-text/FormFieldErrorText'
import CustomInput from '../common/custom-input/CustomInput'
import ListItemContainer from './list-item-container/ListItemContainer'

const TriggeredEmailModal = () => {

  const {
    loading,
    formIsValid,
    prevModal,
    nextModal,
    audienceList,
    selectedAudienceList,
    setSelectedAudienceList,
    onCheckBoxChange,
    ignoredListItems,
    selectedListItems,
    addAllListItemsAsField,
    removeAllListItemsAsField,
    addCheckedListItemAsField,
    removeCheckedListItemAsField,
    handleChecked,
    triggeredEmail,
    handleTriggeredEmailChange,
    handleCampaignIdChange,
    messageTemplateList,
    formValidator
  } = useTriggerEmailModal()

  return (
    <div className={styles["modal-container"]}>
        <h1 className={styles.header}>Triggered Email</h1>
        <p className={styles["sub-header"]}>Send a triggered email using your Email Service Provider</p>
        <p className={styles["sub-header"]}>Note for large volumes of contacts there may be delay before delivery</p>
        <p style={{ display: loading? 'block' : 'none', textAlign: 'right'}}><i className="fa fa-spinner fa-pulse"></i></p>

        <div className={styles.main}>
          <div className={styles['audience-selection-container']}>
            <AudienceSelect 
              audienceList={audienceList} 
              selectedAudienceList={selectedAudienceList} 
              onCheckBoxChange={onCheckBoxChange}
              onSelectAll={(filteredAudienceList) => setSelectedAudienceList([...filteredAudienceList])}
              onDeselectAll={() => setSelectedAudienceList([])}
              hasError={formValidator.selectedAudience.hasError}
            />
            
            <CustomInput
              type='text' 
              className={styles['email-input']} 
              value={triggeredEmail.messageName} 
              name='messageName'
              maxLength={100} 
              onChange={handleTriggeredEmailChange} 
              placeholder='Message Name'
              hasError={formValidator.messageName.hasError}
              errorText='Enter message name'
            />
            
            <CustomInput
              type='number' 
              min={0}
              className={styles['email-input']} 
              value={triggeredEmail.campaignId} 
              name='campaignId' 
              onChange={handleCampaignIdChange} 
              placeholder='Campaign ID'
              hasError={formValidator.campaignId.hasError}
              errorText={formValidator.campaignId.errorMessage}
            />

            <div style={{ width: '100%' }}>
              <Select
                style={{ border: formValidator.messageTemplate.hasError ? '1px solid red' : '' }}
                className={styles.select}
                id="simple-select"
                name='messageTemplate'
                value={triggeredEmail.messageTemplate}
                displayEmpty
                renderValue={triggeredEmail.messageTemplate !== ''? undefined : () => "Message Template"}
                onChange={handleTriggeredEmailChange}
              >
                  {messageTemplateList.map(messageTemplate => <MenuItem key={messageTemplate.id} value={messageTemplate.template}>{messageTemplate.template}</MenuItem>)}
              </Select>
              <FormFieldErrorText text='No template selected' showText={formValidator.messageTemplate.hasError} />
            </div>
          </div>

          <div className={styles["fields-container"]}>
            <span className={styles.text}>
              Fields To Include
            </span>
            <div className={styles["fields"]}>
              <ListItemContainer listItems={ignoredListItems} handleChecked={(id) => handleChecked(id)} />

              <div className={styles["field-actions"]}>
                <Button disabled={!ignoredListItems.length} onClick={addAllListItemsAsField} title='add all' size='small'>
                    {">>"}
                </Button>
                <Button onClick={removeCheckedListItemAsField} disabled={!selectedListItems.some(item => item.checked)} title='remove' size='small'>
                    {"<"}
                </Button>
                <Button onClick={addCheckedListItemAsField} disabled={!ignoredListItems.some(item => item.checked)} title='add' size='small'>
                    {">"}
                </Button>
                <Button disabled={!selectedListItems.length} onClick={removeAllListItemsAsField} title='remove all' size='small'>
                    {"<<"}
                </Button>
              </div>

              <ListItemContainer listItems={selectedListItems} handleChecked={(id) => handleChecked(id)} hasError={formValidator.selectedFields.hasError} errorText='No selected fields'  />
            </div>
          </div>

          <div className={styles['test-n-shedule']}>
              <div className={styles['input-n-button']}>
                <CustomInput
                  min={new Date().toISOString().slice(0, -8)} 
                  name='schedule' value={triggeredEmail.schedule} 
                  onChange={handleTriggeredEmailChange} 
                  type='datetime-local' 
                  className={styles["date-time-input"]} 
                  placeholder='Optional Schedule'
                  hasError={formValidator.schedule.hasError}
                  errorText='No date selected'
                />
              </div>
              <div className={styles['input-n-button']}>
                  <input className={styles['test-to-input']} placeholder='Sent Test To' />
                  <Button className={styles.button} size='small'>Send</Button>
              </div>
          </div>

          <div className={styles.actions}>
              <Button onClick={prevModal} size='small' className={styles.cancel}>Back</Button>
              <Button onClick={nextModal} size='small' disabled={!formIsValid()} className={styles.next}>Next</Button>
          </div>
        </div>
    </div>
  )
}

export default TriggeredEmailModal