import React, { useState } from 'react'
import { Tabs, TabPanel, TabList, Tab } from "react-tabs"
import ItemDetailsTab from './item-details-tab/ItemDetailsTab'
import styles from './ordersCard.module.scss'

const OrdersCard = ({ orderHeader, orderLines }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={styles.container}>
            {
                isOpen ?
                    <>
                        <div onClick={() => setIsOpen(!isOpen)} className={`${styles["expand-less-icon"]} material-icons-outlined`}>
                            expand_less
                        </div>
                        <Tabs className={styles["tabs-container"]}>
                            <TabList className={styles["tab-list"]}>
                                <Tab className={styles["tab"]} selectedClassName={styles["selectedTab"]}>Order Details</Tab>
                                <Tab className={styles["tab"]} selectedClassName={styles["selectedTab"]}>Item Details</Tab>
                            </TabList>
                            <TabPanel>
                                <table className={styles["orderDetailsTable"]}>
                                    <tbody>
                                        <tr>
                                            <td>Order:</td>
                                            <td>{orderHeader["Order ID"]}</td>
                                        </tr>
                                        <tr>
                                            <td>Order Date:</td>
                                            <td>{orderHeader["Order Date"]}</td>
                                        </tr>
                                        <tr>
                                            <td>Order Total:</td>
                                            <td>{orderHeader["Total Amount"]}</td>
                                        </tr>
                                        <tr>
                                            <td>Status:</td>
                                            <td>{orderHeader["Status"]}</td>
                                        </tr>
                                        <tr>
                                            <td>Individual Order:</td>
                                            <td>{orderHeader["Status"]}</td>
                                        </tr>
                                        <tr>
                                            <td>Items:</td>
                                            <td>{orderHeader["Total Items Ordered"]}</td>
                                        </tr>
                                        <tr>
                                            <td>AOV:</td>
                                            <td>{orderHeader["AOV"]}</td>
                                        </tr>
                                        <tr>
                                            <td>LTV:</td>
                                            <td>{orderHeader["LTV"]}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel>
                                <ItemDetailsTab detailsData={orderLines}></ItemDetailsTab>
                            </TabPanel>
                        </Tabs>
                    </>
                    :
                    <>
                        <div onClick={() => setIsOpen(!isOpen)} className={`${styles["expand-more-icon"]} material-icons-outlined`}>
                            expand_more
                        </div>
                        <div className={styles["overviewContainer"]}>
                            <div className={styles.info}>
                                Order: {orderHeader["Order ID"]}
                            </div>
                            <div className={styles.info}>
                                Date: {orderHeader["Order Date"]}
                            </div>
                            <div className={styles.info}>
                                Order Total: {orderHeader["Total Amount GBP"]}
                            </div>
                            <div className={styles.info}>
                                Status: {orderHeader["Status"]}
                            </div>
                            <div className={styles.info}>
                                Individual Order: {orderHeader["Status"]}
                            </div>
                            <div className={styles.info}>
                                Items: {orderHeader["Total Items Ordered"]}
                            </div>
                            <div className={styles.info}>AOV: </div>
                            <div className={styles.info}>LTV: </div>
                        </div>
                    </>
            }
        </div>
    )
}

export default OrdersCard