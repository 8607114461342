import React from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import styles from "./message-field-selector.module.scss";

const MessageFieldSelector = ({
  placeholder,
  fieldList,
  selectedField,
  appendSelectedField,
  onFieldChange,
}) => {
  return (
    <div className={styles["select-item"]}>
      <Button
        title="append"
        onClick={appendSelectedField}
        disabled={!selectedField}
        size="small"
      >
        <span className="material-icons-outlined">arrow_back_ios</span>
      </Button>
      <Select
        className={styles.select}
        labelId="field-select-1"
        id="field-select-1"
        value={selectedField}
        displayEmpty
        renderValue={selectedField !== "" ? undefined : () => placeholder}
        onChange={onFieldChange}
      >
        {fieldList.map((field) => (
          <MenuItem key={field.id} value={field.label}>
            {field.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default MessageFieldSelector;
