import React from 'react'
import MasterDataManagement from '../../../components/MasterDataManagement/MasterDataManagement'
import styles from './masterData.module.scss'

const MasterData = () => {
  return (
    <div className={styles["master-data-page"]}>
      <MasterDataManagement />
    </div>
  )
}

export default MasterData