import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    communicationModalOpen: false,
    communicationModalId: 0,
    selectedAudience: null,
    adhocSms: null,
    adhocEmail: null,
    broadcastCompaignId: null
}

const adhocCommunication = createSlice({
    name: "adhocCommunication",
    initialState: INITIAL_STATE,
    reducers: {
        setCommunicationModal: (state, action) => {
            state.communicationModalOpen = action.payload
        },
        resetAdhocCommunicationState: () => {
            return {
                ...INITIAL_STATE
            }
        },
        setSelectedAudience: (state, action) => {
            state.selectedAudience = action.payload
        },
        switchModal: (state, action) => {
            state.communicationModalId = action.payload
        },
        setAdhocSms: (state, action) => {
            state.adhocSms = action.payload
        },
        setAdhocEmail: (state, action) => {
            state.adhocEmail = action.payload
        },
        setBroadcastCampaignId: (state, action) => {
            state.broadcastCompaignId = action.payload
        }
    }
})


export const { 
    setCommunicationModal,
    resetAdhocCommunicationState,
    setSelectedAudience,
    switchModal,
    setAdhocSms,
    setAdhocEmail,
    setBroadcastCampaignId
} = adhocCommunication.actions


export default adhocCommunication.reducer