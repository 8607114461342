import React, { useEffect, useRef } from 'react'
import CustomCheckbox from '../../../../../common/components/custom-checkbox/CustomCheckbox'
import styles from './searchableMultiSelectPopver.module.scss'

const SearchableMultiSelectPopover = ({
  selectedAudienceList,
  filteredAudienceList,
  searchValue,
  onSearchChange,
  onCheckBoxChange,
  onSelectAll,
  onDeselectAll,
  close
}) => {
  const ref = useRef()

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        close();
      }
    };

    setTimeout(() => {
      document.addEventListener("click", checkIfClickedOutside)
    }, 0)


    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSort = (a, b) => {
    if(a.name < b.name){
      return -1
    } else {
      return 1
    }
  }
  

  return (
    <div ref={ref} className={styles["popover-container"]}>
      <div className={styles["menu-body"]}>
        <input
          className={styles["search-input"]}
          value={searchValue}
          placeholder="Search..."
          onChange={onSearchChange}
        ></input>
        <div className={styles["select-text-container"]}>
          {
            !!filteredAudienceList.length && 
            <>
              {
                !!selectedAudienceList.length ? (
                  <p onClick={onDeselectAll}>Deselect all audiences</p>
                ) : (
                  <p onClick={() => onSelectAll(filteredAudienceList)}>Select all audiences</p>
                )
              }
            </>
          }
        </div>

        {!!filteredAudienceList.length ? (
          <ul className={styles.ul}>
            {[...filteredAudienceList.sort((a, b) => onSort(a, b))].map((item) => {
              return (
                <li key={item.id} className={styles.li}>
                  <CustomCheckbox
                    id={item.name}
                    checked={selectedAudienceList.some((audience) => audience.id === item.id)}
                    onChange={() => onCheckBoxChange(item.id)}
                  />
                  <label htmlFor={item.name}>{item.name}</label>
                </li>
              );
            })}
          </ul>
        ) : (
          <p className={styles["text"]}>No Audiences</p>
        )}
      </div>
    </div>
  )
}

export default SearchableMultiSelectPopover