//https://www.youtube.com/watch?v=hson9BXU9F8&list=PLC3y8-rFHvwgWTSrDiwmUsl4ZvipOw9Cz&index=3

import React, { useState, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import styles from "./masterDataManagementTable.module.scss";
import EditableRow from "./editable-row/EditableRow";
import ReadonlyRow from "./readonly-rows/ReadonlyRow";
import MDMTableHeader from "./mdm-table-header/MDMTableHeader";
import { postData } from "../masterDataManagement.service";

import CustomButton from "../../../common/components/mui-custom-button/CustomButton";
import CommonModal from "../../../common/components/custom-modal/CustomModal";
import { useSelector } from "react-redux";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import ApiNew from "../../../common/api/apinew.service";

const MasterDataManagement = ({ primaryField, masterData }) => {
  const { api } = ApiNew();

  const topNavSearchValue = useSelector(
    ({ topNavSearch: { topNavSearchValue } }) => topNavSearchValue
  );
  const [originalData, setOriginalData] = useState(masterData);
  const [data, setData] = useState(masterData);

  const [primaryKey] = useState(primaryField);

  const [editRecordID, setEditRecordID] = useState(null);
  const [editRecordData, setEditRecordData] = useState();

  const [editedRecords, setEditedRecords] = useState([]);
  const [deletedRecords, setDeletedRecords] = useState([]);
  const [newRecords, setNewRecords] = useState([]);

  const [isAddingNewRecord, setIsAddingNewRecord] = useState(false);

  const { accounts } = useMsal();

  // const [iPageIndex, setIPageIndex] = useState(0);

  const [, setSubmittedRecords] = useState([]);

  const isAuthenticated = useIsAuthenticated();

  const createFriendlyName = function (key) {
    return key
      .toString()
      .replace("_", " ") //remove _ with spaces
      .split(" ") //split the word so we can get sub-words
      .map((word) => word[0].toUpperCase() + word.substring(1, word.length)) //upper case first letter of each word.
      .join(" "); //rejoin words with spaces between
  };

  const CreateColumns = () => {
    const columnKeys = Object.keys(masterData[0]);

    let newColumns = columnKeys.map((key) => ({
      Header: createFriendlyName(key),
      accessor: key,
    }));

    newColumns.push({
      Header: "Edited?",
      accessor: "edited",
    });

    return newColumns;
  };

  const [columns] = useState(CreateColumns());

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
    setGlobalFilter,
    // allColumns,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(topNavSearchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topNavSearchValue]);

  const { pageIndex, pageSize } = state;

  const handleEditRecordChange = (event, columnID) => {
    event.preventDefault();

    const fieldValue = event.target.value;

    let newEditRecordData = { ...editRecordData };

    newEditRecordData[columnID] = fieldValue;

    setEditRecordData(newEditRecordData);
  };

  const handleEditClick = (event, rowOriginal) => {
    event.preventDefault();

    setEditRecordID(rowOriginal[primaryKey]);
    setEditRecordData(rowOriginal);
  };

  const handleSaveClick = (event) => {
    event.preventDefault();

    const newData = [...data];
    const editedDataItems = [...editedRecords];

    const id = editRecordData[primaryKey];

    if (isRowNew(id)) {
      const newRecordItems = [...newRecords];

      const newRecordItemIndex = newRecordItems.indexOf(
        (x) => x[primaryKey] === id
      );

      newRecordItems[newRecordItemIndex] = editRecordData;
    }

    editRecordData["edited"] = setEditedField_Edited(id) ? "⚠️ Unsaved" : "";

    const index = data.findIndex((item) => item[primaryKey] === editRecordID);

    newData[index] = editRecordData;

    const editedIndex = editedDataItems.findIndex(
      (item) => item[primaryKey] === editRecordID
    );

    //TODO
    let editRecordDataNoProp = { ...editRecordData };

    delete editRecordDataNoProp.edited;

    if (editedIndex > -1) {
      editedDataItems[editedIndex] = editRecordDataNoProp;
    } else {
      editedDataItems.push(editRecordDataNoProp);
    }

    setData(newData);
    setEditedRecords(editedDataItems);
    setEditRecordID(null);
    setEditRecordData(null);
  };

  const setEditedField_Edited = (id) => {
    const originalDataItems = [...originalData];

    const originalRecord = originalDataItems.find(
      (x) => x[primaryField] === id
    );

    const originalRecordKeys = Object.keys(originalRecord);

    for (let i in originalRecordKeys) {
      const keyValue = originalRecordKeys[i];
      const originalRecordValue = originalRecord[keyValue];
      const editRecordValue = editRecordData[keyValue];

      if (originalRecordValue !== editRecordValue) {
        return true;
      }
    }

    return false;
  };

  const handleCancelClick = () => {
    setEditRecordID(null);
  };

  const handleDeleteClick = (recordID) => {
    if (isRowNew(recordID)) {
      const dataItems = [...data];
      const newRecordItems = [...newRecords];

      const index = data.findIndex((item) => item[primaryKey] === recordID);
      dataItems.splice(index, 1);

      const newRecordIndex = newRecordItems.findIndex(
        (item) => item[primaryKey] === recordID
      );

      if (newRecordIndex > -1) {
        newRecordItems.splice(newRecordIndex, 1);
      }

      setData(dataItems);
      setNewRecords(newRecordItems);
    } else {
      const newData = [...data];

      const deletedItemsArr = [...deletedRecords];

      const index = data.findIndex((item) => item[primaryKey] === recordID);

      const deletedIndex = deletedItemsArr.findIndex(
        (item) => item[primaryKey] === recordID
      );

      if (deletedIndex <= 0) {
        let deleteRecordNoProp = { ...data[index] };

        delete deleteRecordNoProp.edited;

        deletedItemsArr.push(deleteRecordNoProp);
        setDeletedRecords(deletedItemsArr);
      }

      newData[index]["edited"] = "⚠️ Unsaved";

      setData(newData);
    }
  };

  const handleUndoDeleteClick = (recordID) => {
    const deletedItemsArr = [...deletedRecords];

    const deletedItemIndex = deletedItemsArr.findIndex(
      (item) => item[primaryField] === recordID
    );

    if (deletedItemIndex > -1) {
      deletedItemsArr.splice(deletedItemIndex, 1);

      setDeletedRecords(deletedItemsArr);

      const newData = [...data];

      const dataIndex = data.findIndex((item) => item[primaryKey] === recordID);

      newData[dataIndex]["edited"] = "";

      setData(newData);
    }
  };

  const handleUndoEditClick = (recordID) => {
    const newData = [...data];
    const originalDataItems = [...originalData];
    const editItems = [...editedRecords];

    const originalRecord = originalDataItems.find(
      (item) => item[primaryKey] === recordID
    );

    const editRecordIndex = editItems.findIndex(
      (x) => x[primaryField] === recordID
    );

    if (editRecordIndex > -1) {
      editItems.splice(editRecordIndex, 1);

      setEditedRecords(editItems);
    }

    const dataIndex = data.findIndex((item) => item[primaryKey] === recordID);
    newData[dataIndex] = originalRecord;

    setData(newData);
  };

  const showAddNewRecordModal = () => {
    setIsAddingNewRecord(true);
  };

  const undoChangesBtnClick = () => {
    const originalDataItems = [...originalData];

    setOriginalData(originalDataItems);
    setData(originalDataItems);

    setEditRecordID(null);
    setEditRecordData(null);

    setDeletedRecords([]);
    setNewRecords([]);

    setIsAddingNewRecord(false);

    window.location.reload();
  };

  const submitChangesBtnClick = async () => {
    if (!isAuthenticated || !api) {
      return;
    }

    let editedItems = [];

    for (let i in editedRecords) {
      const updateObject = {
        EditType: "U",
        Record: editedRecords[i],
      };

      editedItems.push(updateObject);
    }

    for (let j in newRecords) {
      const insertObject = {
        EditType: "I",
        Record: newRecords[j],
      };

      editedItems.push(insertObject);
    }

    for (let k in deletedRecords) {
      const deleteObject = {
        EditType: "D",
        Record: deletedRecords[k],
      };

      editedItems.push(deleteObject);
    }

    let editObject = {
      User: accounts[0].username,
      EditItems: editedItems,
    };

    setSubmittedRecords(editObject);

    await postData(editObject, api);

    setEditRecordID(null);
    setEditRecordData(null);

    setDeletedRecords([]);
    setNewRecords([]);

    setIsAddingNewRecord(false);

    window.location.reload();
  };

  const isRowEdited = (id) => {
    const row = originalData.filter((x) => x[primaryField] === id);
    const updatedRow = data.filter((x) => x[primaryField] === id);

    if (!row || row?.length === 0) {
      return false;
    }

    if (!updatedRow || updatedRow?.length === 0) {
      return false;
    }

    const keys = Object.keys(row[0]);

    for (let i = 0; i < keys.length; i++) {
      const newCell = updatedRow[0][keys[i]];
      const originalCell = row[0][keys[i]];

      if (newCell !== originalCell) {
        return true;
      }
    }

    return false;
  };

  const isRowDeleted = (id) => {
    const deletedItemsArr = [...deletedRecords];

    return deletedItemsArr.findIndex((x) => x[primaryField] === id) > -1;
  };

  const isRowNew = (id) => {
    const newItemsArr = [...newRecords];

    return newItemsArr.findIndex((x) => x[primaryKey] === id) > -1;
  };

  const [newRecord, setNewRecord] = useState({});
  const [productCodeError, setProductCodeError] = useState(false);
  const [productCodeErrorMessage, setProductCodeErrorMessage] = useState("");

  const addNewRecordOnChange = (e, columnName) => {
    setProductCodeError(false);
    const newValue = e.target.value;

    let newRecordValue = { ...newRecord };

    newRecordValue[columnName] = newValue;

    setNewRecord(newRecordValue);
  };

  const cancelAddingNewRecord = () => {
    setNewRecord({});
    setProductCodeError(false);
    setProductCodeErrorMessage("");
    setIsAddingNewRecord(false);
  };

  const saveAddingNewRecord = () => {
    const primaryFieldValue = newRecord[primaryField];

    if (!primaryFieldValue) {
      setProductCodeError(true);
      setProductCodeErrorMessage(`${primaryField} field is empty.`);
      return;
    }

    const findSamePrimaryKey = data.find(
      (record) => record[primaryField] === primaryFieldValue
    );

    if (findSamePrimaryKey) {
      setProductCodeError(true);
      setProductCodeErrorMessage(
        `Same primary field value '${primaryFieldValue}' exists in data. Please choose another value.`
      );
      return;
    }

    const newRecordsArr = [...newRecords];
    const dataItems = [...data];

    const newRecordItem = newRecord;
    newRecordItem["edited"] = "⚠️ Unsaved";

    const newRecordNoProp = { ...newRecordItem };
    delete newRecordNoProp.edited;

    newRecordsArr.push(newRecordNoProp);
    dataItems.push(newRecordItem);

    setNewRecords(newRecordsArr);
    setData(dataItems);

    setNewRecord({});

    gotoPage(pageCount - 1);

    setIsAddingNewRecord(false);
  };

  return (
    <>
      <div className={styles["btn-container"]}>
        <CustomButton
          btnSize="small"
          useMuiStyle={true}
          onClick={() => showAddNewRecordModal()}
        >
          Add new Record
        </CustomButton>

        <CustomButton
          btnSize="small"
          useMuiStyle={true}
          onClick={() => undoChangesBtnClick()}
        >
          Undo Changes
        </CustomButton>
        <CustomButton
          btnSize="small"
          useMuiStyle={true}
          onClick={() => submitChangesBtnClick()}
        >
          Submit Changes
        </CustomButton>
      </div>
      <>
        <div className={styles["table-container"]}>
          <div className={styles["selection-table-container"]}>
            <table className={styles.table} {...getTableProps()}>
              <thead className={styles.thead}>
                <MDMTableHeader headerGroups={headerGroups}></MDMTableHeader>
              </thead>
              <tbody className={styles.tbody} {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);

                  return editRecordID === row.original[primaryKey] ? (
                    <EditableRow
                      key={row.original[primaryKey]}
                      row={row}
                      primaryKey={primaryKey}
                      editRecordData={editRecordData}
                      handleSaveClick={handleSaveClick}
                      handleCancelClick={handleCancelClick}
                      handleEditRecordChange={handleEditRecordChange}
                    />
                  ) : (
                    <ReadonlyRow
                      key={row.original[primaryKey]}
                      row={row}
                      primaryKey={primaryKey}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                      handleUndoDeleteClick={handleUndoDeleteClick}
                      isRowDeleted={isRowDeleted}
                      isRowEdited={isRowEdited}
                      handleUndoEditClick={handleUndoEditClick}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles["pagination-container"]}>
          <span className={styles["select-container"]}>
            Rows per page:
            <select
              className={styles.select}
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 20, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </span>
          <span>
            <span>
              {pageIndex + 1} of {pageOptions.length}
            </span>
            &nbsp;
          </span>
          <span className={styles["button-container"]}>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <span className="material-icons-outlined">first_page</span>
            </button>
            <button disabled={!canPreviousPage} onClick={() => previousPage()}>
              <span className="material-icons-outlined">chevron_left</span>
            </button>
            <button disabled={!canNextPage} onClick={() => nextPage()}>
              <span className="material-icons-outlined">chevron_right</span>
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <span className="material-icons-outlined">last_page</span>
            </button>
          </span>
        </div>
      </>
      <CommonModal
        isOpen={isAddingNewRecord}
        closeModal={cancelAddingNewRecord}
      >
        <div style={{ textAlign: "center", margin: "1rem" }}>
          {columns.map((column) => {
            if (column.accessor !== "edited") {
              return (
                <div style={{ margin: "0.5rem 0" }} key={column.Header}>
                  <div>{column.Header}</div>
                  <input
                    className={styles.editableInput}
                    onChange={(e) => addNewRecordOnChange(e, column.Header)}
                  />
                  {column.Header === "ProductCode" && productCodeError ? (
                    <p className={styles["product-code-error"]}>
                      {productCodeErrorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              );
            } else {
              return <React.Fragment key={column.Header}></React.Fragment>;
            }
          })}
        </div>
        <div style={{ textAlign: "right" }}>
          <CustomButton
            btnSize="small"
            useMuiStyle={true}
            onClick={() => saveAddingNewRecord()}
          >
            Save
          </CustomButton>
          <CustomButton
            btnSize="small"
            useMuiStyle={true}
            onClick={() => cancelAddingNewRecord()}
          >
            Cancel
          </CustomButton>
        </div>
      </CommonModal>
    </>
  );
};

export default MasterDataManagement;
