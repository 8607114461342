import { useState, useEffect } from "react";
import { getSelectionById, getSelections } from "../audienceSelector.service";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedAudience,
  setCommunicationModal,
} from "../../../redux/adhoc-communication/adhoc-communication.reducer";
import { setAudienceToEdit } from "../../../redux/audience-selector/audience-selector.reducer";
import { setAudienceToView } from "../../../redux/audience-selector/audience-selector.reducer";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import ApiNew from "../../../common/api/apinew.service";

const useAudienceSelections = () => {
  const selectionTablePage = useSelector(
    ({ audienceSelector: { selectionTablePage } }) => selectionTablePage
  );
  const { api } = ApiNew();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selections, setSelections] = useState([]);
  const [deletionSelectionModal, setDeleteSelectionModal] = useState(false);
  const [newColumns, setNewColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const isAuthenticated = useIsAuthenticated();

  const friendlyColumnName = (key) => {
    return key
      .toString()
      .replace("_", " ") //remove _ with spaces
      .split(" ") //split the word so we can get sub-words
      .map((word) => word[0].toUpperCase() + word.substring(1, word.length)) //upper case first letter of each word.
      .join(" "); //rejoin words with spaces between
  };

  let fetchSelections = async () => {
    setLoading(true);

    if (isAuthenticated && api) {
      const selectionData = await getSelections(api);
      if (!!selectionData.length) {
        setSelections(parseValues(selectionData));

        const columnKeys = Object.keys(selectionData[0]);

        setNewColumns(
          columnKeys.map((key) => {
            if (key === "created_at" || key === "refreshed_at") {
              return {
                Header: friendlyColumnName(key),
                accessor: key,
                Cell: ({ cell: { value } }) =>
                  new Date(value).toLocaleString().replace(",", ""),
                sortType: "datetime",
              };
            }

            return {
              Header: friendlyColumnName(key),
              accessor: key,
            };
          })
        );
      } else {
        setSelections([]);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      fetchSelections();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, api]);

  let handleMessageSelection = async (row) => {
    const audience = await getSelectionById(row.original.id, api);

    const obj = {
      id: audience.id,
      name: audience.name,
      description: audience.description,
      limit: audience.limit,
      count: audience.individuals,
      autoRefresh: audience.auto_refresh,
      json: JSON.parse(audience.json),
    };

    dispatch(setSelectedAudience(obj));
    dispatch(setCommunicationModal(true));
    navigate("/communicate");
  };

  let handleEditSelection = async (row) => {
    const audienceToEdit = await getSelectionById(row.original.id, api);

    const obj = {
      id: audienceToEdit.id,
      name: audienceToEdit.name,
      description: audienceToEdit.description,
      limit: audienceToEdit.limit,
      count: audienceToEdit.individuals,
      autoRefresh: audienceToEdit.auto_refresh,
      refreshedAt: audienceToEdit.refreshed_at,
      json: JSON.parse(audienceToEdit.json),
    };

    dispatch(setAudienceToEdit(obj));
    navigate("/audience-builder");
  };

  let refreshTable = () => {
    setDeleteSelectionModal(false);
    setSelectedRow(null);
    fetchSelections();
  };

  let handleDeleteSelection = (row) => {
    const obj = {
      id: row.original.id,
      name: row.original.name,
    };

    setSelectedRow(obj);
    setDeleteSelectionModal(true);
  };

  let closeDeleteModal = () => {
    setDeleteSelectionModal(false);
    setSelectedRow(null);
  };

  let handleShowAudienceMembers = (audienceID) => {
    dispatch(setAudienceToView(audienceID));
    navigate("/audience-record");
  };

  let parseValues = (selectionData) => {
    return [...selectionData].map((record) => ({
      ...record,
      created_at: new Date(record.created_at),
      refreshed_at: record.refreshed_at
        ? new Date(record.refreshed_at)
        : new Date(record.created_at),
      created_by: record.created_by ? record.created_by.toLowerCase() : null,
    }));
  };

  return {
    loading,
    selections,
    newColumns,
    handleMessageSelection,
    selectedRow,
    handleEditSelection,
    handleShowAudienceMembers,
    closeDeleteModal,
    deletionSelectionModal,
    handleDeleteSelection,
    refreshTable,
    selectionTablePage,
  };
};

export default useAudienceSelections;
