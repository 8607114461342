import React from "react";
import styles from "../masterDataManagementTable.module.scss";

const MDMTableHeader = ({ headerGroups }) => {
  return headerGroups.map((headerGroup) => (
    <tr className={styles.tr} {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((column) => (
        <th
          className={styles.th}
          {...column.getHeaderProps(column.getSortByToggleProps())}
        >
          <span className="material-icons-outlined">
            {column.isSorted ? (column.isSortedDesc ? "south" : "north") : ""}
          </span>
          {column.render("Header")}
        </th>
      ))}
      <th></th>
    </tr>
  ));
};

export default MDMTableHeader;
